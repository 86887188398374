import { useForm } from "react-hook-form";
import React, { useContext, useState } from "react";

import {
  errorNotification,
  successNotification,
} from "../notifications/Notifications";
import styles from "../../styles/DefaultForm.modules.css";
import { InputFieldWrapper } from "../Input/InputFieldWrapper";
import { InputFieldTitle } from "../Input/InputFieldTitle";
import { InputFieldError } from "../Input/InputFieldError";
import LoaderCircleSmall from "../loader/LoaderCircleSmall";
import { CardTitle } from "../cards/CardTitle";
import { CardSubText } from "../cards/CardSubText";
import styled from "styled-components";
import { createNewActiveCreator } from "../../api/services/EnvironmentServices";
import { AuthContext } from "../../providers/AuthContextProvider";

export const FormRequestCreatorAccount = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);
  const {
    user: { email, userUUID },
  } = useContext(AuthContext);

  async function onSubmit({ creatorName, creatorBio }) {
    setLoading(true);
    try {
      await createNewActiveCreator(
        userUUID,
        creatorName,
        creatorBio,
        email,
      );
      setLoading(false);
      successNotification("User invited successfully");
    } catch (error) {
      errorNotification(error.data.message);
      setLoading(false);
    }
  }

  return (
    <>
      <CardHeaderWrapper>
        <div>
          <CardTitle>Request creator account</CardTitle>
        </div>
        <div>
          <CardSubText>
            Fill in your details to request a creator account
          </CardSubText>
        </div>
      </CardHeaderWrapper>
      <div className={styles.formContainer}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputFieldWrapper>
            <InputFieldTitle>Creator name</InputFieldTitle>
            <input
              {...register("creatorName", { required: true })}
              type="text"
            />
            {errors?.creatorName && (
              <InputFieldError>Creator name is required</InputFieldError>
            )}
          </InputFieldWrapper>
          <InputFieldWrapper>
            <InputFieldTitle>Creator bio</InputFieldTitle>
            <input
              {...register("creatorBio", { required: true })}
              type="text"
            />
            {errors?.creatorBio && (
              <InputFieldError>Creator bio is required</InputFieldError>
            )}
          </InputFieldWrapper>
          <button type="submit">
            {loading ? <LoaderCircleSmall /> : "Request"}
          </button>
        </form>
      </div>
    </>
  );
};

const CardHeaderWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
`;
