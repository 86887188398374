import styled from "styled-components";
import HeaderOpen from "../HeaderOpen";
import {CookieConsent} from 'react-cookie-consent';

const LayoutDefaultPageOpenWrapper = styled.div`
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const CookieWrapper = styled.div`
  display: flex;
  position: fixed;
  left: 0;
  bottom: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  //background: rgba(124, 47, 47, 0);
`;

export const LayoutDefaultPageOpen = (props) => (
  <>
    <HeaderOpen />
    <LayoutDefaultPageOpenWrapper className={props.children}>
      <span className="title">{props.children}</span>
    </LayoutDefaultPageOpenWrapper>
    <CookieWrapper>
      <CookieConsent
          location="bottom"
          cookieName={'cookieConsent'}
          style={{
            position: "relative",
            alignSelf: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "90%",
            background: "#ffffff",
            fontFamily: "DMSans",
            boxShadow: "8px 8px 40px rgba(112, 144, 176, 0.20)",
            borderRadius: "40px",
            padding: "20px 20px",
            color: "#3d3d3d",
            border: "1px solid #E9E3FF",
          }}
          buttonStyle={{
            width: "110px",
            height: "32px",
            fontSize: "14px",
            lineHeight: "24px",
            textAlign: "center",
            color: "#ffffff",
            background: "#4318FF",
            padding: "7px",
            borderRadius: "70px",
            alignItems: "center",
            display: "inline-flex",
            flexDirection: "row",
            justifyContent: "center",

          }}
          buttonText="Accept"
          expires={100}

      >Ravel uses cookies{" "}
        <br/>
        <span style={{
          fontSize: "8px",

        }}>
          By continuing to browse this site, you agree to the use of cookies to have a working site and provide you the best experience.
          We value your privacy and only use Functional Cookies. We do not use cookies for Statistical or Advertising purposes.
      </span>
      </CookieConsent>
    </CookieWrapper>

  </>
);
