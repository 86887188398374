import React, { useState } from "react";

import { ButtonChip } from "../button/ButtonChip";
import Modal from "./Modal";
import { ModalTitle } from "./ModalTitle";
import { ModalText } from "./ModalText";
import { CreatorsUploadAssetBundle } from "../creators/CreatorsUploadAssetBundle";

function ModalUpdateAssetBundle({ environmentUuid }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileAssetBundle, setFileStateAssetBundle] = useState(false);

  function handleOnAssetBundleUploaded() {}

  return (
    <div>
      <div onClick={() => setIsModalOpen(true)}>
        <ButtonChip>Update asset bundle</ButtonChip>
      </div>
      {isModalOpen && (
        <Modal
          id="modal"
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        >
          <ModalTitle>Update asset bundle</ModalTitle>
          <ModalText>
            Updating your asset bundle, only environments that are not published
            can be updated
          </ModalText>

          <CreatorsUploadAssetBundle
            environmentUuid={environmentUuid}
            setFileState={setFileStateAssetBundle}
            handleOnAsstBundleUploaded={handleOnAssetBundleUploaded}
          />

          <div className="box-footer">
            <div onClick={() => setIsModalOpen(false)} className="close">
              <ButtonChip width={110} height={32}>
                Cancel
              </ButtonChip>
            </div>
            <div onClick={() => setIsModalOpen(false)} className="close">
              <ButtonChip hasBackgroundColor>Done</ButtonChip>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default ModalUpdateAssetBundle;
