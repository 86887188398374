import React from "react";
import Modal from "./Modal";
import { ButtonChip } from "../button/ButtonChip";
import { ModalTitle } from "./ModalTitle";
import { ModalText } from "./ModalText";
import { errorNotification } from "../notifications/Notifications";
import { deleteOrganizationFiles } from "../../api/services/OrganizationFilesServices";

export function ModalDeleteFileOrg({ id, setIsModalOpen, fetchData }) {
  const deleteFile = async (id) => {
    try {
      await deleteOrganizationFiles(id).then((response) => {
        if (response.status === 204) {
          fetchData();
        }
      });
    } catch (error) {
      console.error(error);
      errorNotification("Error deleting file");
    }
  };

  return (
    <>
      <Modal id="modal" isOpen onClose={() => setIsModalOpen(false)}>
        <div className="box-header">
          <ModalTitle>Delete file</ModalTitle>
          <ModalText>Are you sure to delete the file?</ModalText>
        </div>

        <div className="box-footer">
          <div className="close">
            <ButtonChip
              hasBackgroundColor
              onClick={() => {
                deleteFile(id);
                setIsModalOpen(false);
              }}
            >
              Delete file
            </ButtonChip>
          </div>
        </div>
      </Modal>
    </>
  );
}
