import Card from "../../components/cards/Card";
import { LayoutDefaultPageOpen } from "../../components/layout/LayoutDefaultPageOpen";
import { Link } from "react-router-dom";
import { CardTitle } from "../../components/cards/CardTitle";
import { CardSubText } from "../../components/cards/CardSubText";
import { ButtonText } from "../../components/button/ButtonText";
import { FormResetPassword } from "../../components/form/FormResetPassword";
import { CardHeaderWrapper } from "../../components/cards/CardHeaderWrapper";
import { useState } from "react";

export const ResetPassword = () => {
  const [newPasswordSet, setNewPasswordSet] = useState(false);

  function emailSend() {
    setNewPasswordSet(true);
  }

  return (
    <LayoutDefaultPageOpen>
      <Card>
        {newPasswordSet ? (
          <CardHeaderWrapper>
            <div>
              <CardTitle>Forgot password</CardTitle>
            </div>
            <div>
              <CardSubText>Your new password has been set, </CardSubText>
            </div>
          </CardHeaderWrapper>
        ) : (
          <>
            <CardHeaderWrapper>
              <div>
                <CardTitle>Reset password</CardTitle>
              </div>
              <div>
                <CardSubText>
                  Enter your new password to reset your current password.
                </CardSubText>
                <CardSubText>

                  A minimum of 8 characters is required, with at
                  least one uppercase letter, one number and one special character.
                </CardSubText>
              </div>
            </CardHeaderWrapper>
            <FormResetPassword newPassWordSet={emailSend} />
          </>
        )}
        <Link to={"/login"}>
          <ButtonText>Log in</ButtonText>
        </Link>
      </Card>
    </LayoutDefaultPageOpen>
  );
};
