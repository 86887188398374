import styled from "styled-components";
import { Heading2 } from "../../components/Headings";
import { Paragraph } from "../../components/Paragraphs";
import { ItemHeader } from "../../components/item/ItemHeader";
import { CardContent } from "../../components/cards/CardContent";
import { ItemTitle } from "../../components/item/ItemTitle";
import { ItemContainer } from "../../components/item/ItemContainer";
import { ItemTextSubtile } from "../../components/item/ItemTextSubtile";
import { ItemText } from "../../components/item/ItemText";
import { ButtonChip } from "../button/ButtonChip";
import LoaderCirclePrimary from "../loader/LoaderCircleBig";

const convertDate = (date) => {
  const d = new Date(date);
  return d.toUTCString();
};

// TODO: refactor this -> breakdown
export const SpacesDetail = ({ environment, renderActions, handleOnEnter }) => {
  return (
    <>
      {!environment ? (
        <LoaderCirclePrimary />
      ) : (
        <GridItemContainer>
          <CardWrapper>
            <EnvImageWrapper>
              <GridItemContent>
                <GridItemLink href={`/spaces/${environment.photonRoomId}`}>
                  <GridItemImageMask
                    src={environment.environmentPro?.imageUrl}
                    alt={environment.environmentPro?.name}
                  />
                </GridItemLink>
              </GridItemContent>
            </EnvImageWrapper>
            <CardContent>
              <ItemContainer>
                <ItemHeader>
                  <GridHeader>
                    <ItemTitle>
                      <Heading2>Space details</Heading2>
                    </ItemTitle>
                    <div onClick={() => handleOnEnter()}>
                      <ButtonChip hasBackgroundColor width={128} height={40}>
                        Enter
                      </ButtonChip>
                    </div>
                  </GridHeader>
                </ItemHeader>
                <GridBody>
                  <ItemTextSubtile>
                    <Paragraph>Name : </Paragraph>
                  </ItemTextSubtile>
                  <ItemText>
                    <Paragraph>{environment?.spaceName}</Paragraph>
                  </ItemText>
                </GridBody>
                <GridBody>
                  <ItemTextSubtile>
                    <Paragraph>Description: </Paragraph>
                  </ItemTextSubtile>
                  <ItemText>
                    <Paragraph>{environment?.description}</Paragraph>
                  </ItemText>
                </GridBody>
                <GridBody>
                  <ItemTextSubtile>
                    <Paragraph>Space type : </Paragraph>
                  </ItemTextSubtile>
                  <ItemText>
                    <Paragraph>{environment.spaceType}</Paragraph>
                  </ItemText>
                </GridBody>
                <GridBody>
                  <ItemTextSubtile>
                    <Paragraph>Created at : </Paragraph>
                  </ItemTextSubtile>
                  <ItemText>
                    <Paragraph>{convertDate(environment.created_at)}</Paragraph>
                  </ItemText>
                </GridBody>
              </ItemContainer>
              <ItemContainer>
                <ItemHeader>
                  <GridHeader>
                    <ItemTitle>
                      <Heading2>Environment</Heading2>
                    </ItemTitle>
                  </GridHeader>
                </ItemHeader>

                <GridBody>
                  <ItemTextSubtile>
                    <Paragraph>Environment: </Paragraph>
                  </ItemTextSubtile>
                  <ItemText>
                    <Paragraph>{environment.name}</Paragraph>
                  </ItemText>
                </GridBody>
                <GridBody>
                  <ItemTextSubtile>
                    <Paragraph>Short summary: </Paragraph>
                  </ItemTextSubtile>
                  <ItemText>
                    <Paragraph>{environment.description}</Paragraph>
                  </ItemText>
                </GridBody>
              </ItemContainer>
              <ItemContainer>
                <ItemHeader>
                  <GridHeader>
                    <ItemTitle>
                      <Heading2>Actions</Heading2>
                    </ItemTitle>
                  </GridHeader>
                </ItemHeader>
                {environment?.spaceType === "ORGANIZATION" && (
                  <ActionsWrapper>{renderActions()}</ActionsWrapper>
                )}
              </ItemContainer>
            </CardContent>
          </CardWrapper>
        </GridItemContainer>
      )}
    </>
  );
};

const GridHeader = styled.div`
  display: grid;
  grid-template-columns: 60% 20% 20%;
`;

const GridBody = styled.div`
  display: grid;
  grid-template-columns: 30% 70%;
`;
const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

const GridItemContainer = styled.div`
  position: relative;
  width: 600px;
`;

const GridItemContent = styled.div`
  height: 300px;
  width: 100%;
  position: relative;
`;

const GridItemLink = styled.div`
  width: 100%;
  height: 100%;
`;

const GridItemImageMask = styled.img`
  height: 100%;
  width: 100%;
  bottom: 0;
  background: rgb(255, 255, 255);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
`;

const EnvImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  //width: 242px;
  align-items: flex-start;
`;

const CardWrapper = styled.div`
  min-height: 400px;
  background: #ffffff;
  box-shadow: 0px 18px 40px rgba(112, 144, 176, 0.12);

  border-radius: 30px;
`;
