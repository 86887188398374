import HeaderClosed from "../HeaderClosed";
import styled from "styled-components";
import { LayoutFooterClosed } from ".//LayoutFooterClosed";

const PageSpecific = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const LayoutMinimalPageClosed = ({ children }) => (
  <>
      <HeaderClosed />
      <PageSpecific>{children}</PageSpecific>
      <LayoutFooterClosed />
  </>
);
