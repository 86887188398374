import { LayoutDefaultPageClosed } from "../../components/layout/LayoutDefaultPageClosed";
import { PageDetailsProvider } from "../../providers/PageDetailsProvider";
import { CreatorsSection } from "../../components/creators/CreatorsSection";

export const Creators = () => {
  return (
    <LayoutDefaultPageClosed>
      <PageDetailsProvider title="Creator" />
      <CreatorsSection />
    </LayoutDefaultPageClosed>
  );
};
