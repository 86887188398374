import React, { useEffect, useState } from "react";

import AgoraRTC from "agora-rtc-sdk-ng";
import useAgora from "./hooks/useAgora";

import "./Call.css";
import styled from "styled-components";

import { ReactComponent as MicEnabled } from "../../assets/icons/microphone-2.svg";
import { ReactComponent as MicDisables } from "../../assets/icons/microphone-slash.svg";
import { ReactComponent as CameraEnabled } from "../../assets/icons/video.svg";
import { ReactComponent as CameraDisables } from "../../assets/icons/video-slash.svg";
import MediaPlayerVideo from "./components/MediaPlayerVideo";
import MediaPlayerAudio from "./components/MediaPlayerAudio";
import { ButtonRound } from "../../components/button/ButtonRound";
import { infoNotification } from "../../components/notifications/Notifications";
import AvSettings from "../../components/modal/ModalAvSettings";
const client = AgoraRTC.createClient({ codec: "h264", mode: "rtc" });

//style={{display: showUI ? "" : "none"}}

function Call(props: {
  room: any;
  agoraToken: any;
  photonRoomId: any;
  sessionUserId: any;
  moderationUserState: any;
  moderationRoomState: any;
  socket: any;
  updateUserState: any;
  showUI: any;
  showSettingsModelAsync: any;
}) {
  const {
    room,
    agoraToken,
    photonRoomId,
    sessionUserId,
    moderationUserState,
    moderationRoomState,
    socket,
    updateUserState,
    showUI,
    showSettingsModelAsync
  } = props;
  const [appid, setAppid] = useState("ff90a02189ee44ac841dc640e712870f");
  const {
    localAudioTrack,
    localVideoTrack,
    leave,
    join,
    handleJoinState,
    joinState,
    audioEnabled,
    videoEnabled,
    handleAudioMute,
    forceAudioMute,
    forceCamMute,
    handleVideoMute,
    getMics,
    getCameras,
    switchMicrophone,
    switchCamera,
    remoteUsers,
  } = useAgora(client, socket, updateUserState);

  const [showSettings, setShowSettings] = useState(false);

  useEffect(() => {
    const abc = async () => {
      const c = await getCameras();
      setCams(c);
      const m = await getMics();
      setMics(m);
      console.info("Pre-Selected Cam:  " + JSON.stringify(c[0]));
      console.info("Pre-Selected Mic:  " + JSON.stringify(m[0]));
      await switchCamera(c[0]?.deviceId);
      await switchMicrophone(m[0]?.deviceId);
      return { c, m };
    };
    abc().then(async (r) => {
      setTimeout(() => {
        setselectedCam(r.c[0]?.label);
        setSelectedMic(r.m[0]?.label);
        setShowSettings(showSettingsModelAsync);
      }, 100);
    });
  }, [showSettingsModelAsync]);

  useEffect(() => {
    join(appid, photonRoomId, agoraToken, sessionUserId);
    return function () {
      leave();
    };
  }, []);

  useEffect(() => {
    if (moderationRoomState.muteEveryone) {
      if (moderationUserState.overrideMuteEveryone) {
        return;
      } else {
        forceAudioMute();
      }
    }
  }, [moderationRoomState]);

  useEffect(() => {
    if (!moderationUserState.overrideMuteEveryone) {
      if (moderationRoomState.muteEveryone) {
        forceAudioMute();
      } else {
        return;
      }
    }
  }, [moderationUserState.overrideMuteEveryone]);

  useEffect(() => {
    if (moderationUserState.muted) {
      if (audioEnabled) {
        forceAudioMute();
      }
    }
  }, [moderationUserState.muted]);

  useEffect(() => {
    if (!moderationUserState.overRideVideoMuteEveryone) {
      if (moderationRoomState.muteVideoEveryone) {
        forceCamMute();
      } else {
        return;
      }
    }
  }, [moderationUserState.overRideVideoMuteEveryone]);

  useEffect(() => {
    if (moderationUserState.videoMuted) {
      if (videoEnabled) {
        forceCamMute();
      }
    }
  }, [moderationUserState.videoMuted]);

  /**
   * Mutes the webcam of the local client when webcam is enabled and host activates mute-cam-everyone option from host-tools
   * */
  useEffect(() => {
    if (!moderationUserState.overRideVideoMuteEveryone) {
      if (videoEnabled) {
        forceCamMute();
      } else {
        return;
      }
    }
  }, [moderationRoomState.muteVideoEveryone]);

  window.onunload = function () {
    leave();
  };

  const [cams, setCams] = useState<any[]>([]);
  const [mics, setMics] = useState<any[]>([]);

  const [selectedMic, setSelectedMic] = useState("");
  const [selectedCam, setselectedCam] = useState("");

  function changeAudioInput(deviceId: string) {
    switchMicrophone(deviceId);
  }

  function changeCameraInput(deviceId: string) {
    switchCamera(deviceId);
  }

  function handleMic() {
    if (moderationRoomState.muteEveryone) {
      if (moderationUserState.overrideMuteEveryone) {
        handleAudioMute();
      } else {
        infoNotification("Everyone is muted by the host");
        return;
      }
    }
    handleAudioMute();
  }

  function handleCamera() {
    if (moderationRoomState.muteVideoEveryone) {
      if (moderationUserState.overRideVideoMuteEveryone) {
        handleVideoMute();
      } else {
        infoNotification("All webcams are disabled by the host");
        return;
      }
    }
    handleVideoMute();
  }

  const renderMicButton = () => {
    if (moderationRoomState.muteEveryone) {
      if (moderationUserState.overrideMuteEveryone) {
        return (
          <ButtonRound
            className="btn btn-primary btn-sm"
            disabled={!joinState}
            onClick={() => {
              handleMic();
            }}
          >
            {audioEnabled === true && <MicEnabled></MicEnabled>}
            {audioEnabled === false && <MicDisables></MicDisables>}
          </ButtonRound>
        );
      } else {
        return <></>;
      }
    } else {
      return (
        <ButtonRound
          className="btn btn-primary btn-sm"
          disabled={!joinState}
          onClick={() => {
            handleMic();
          }}
        >
          {audioEnabled === true && <MicEnabled></MicEnabled>}
          {audioEnabled === false && <MicDisables></MicDisables>}
        </ButtonRound>
      );
    }
  };
  const renderCamButton = () => {
    if (moderationRoomState.muteVideoEveryone) {
      if (moderationUserState.overRideVideoMuteEveryone) {
        return (
          <ButtonRound
            className="btn btn-primary btn-sm"
            disabled={!joinState}
            onClick={() => {
              handleCamera();
            }}
          >
            {videoEnabled === false && <CameraDisables></CameraDisables>}
            {videoEnabled === true && <CameraEnabled></CameraEnabled>}
          </ButtonRound>
        );
      } else {
        return <></>;
      }
    } else {
      return (
        <ButtonRound
          className="btn btn-primary btn-sm"
          disabled={!joinState}
          onClick={() => {
            handleCamera();
          }}
        >
          {videoEnabled === false && <CameraDisables></CameraDisables>}
          {videoEnabled === true && <CameraEnabled></CameraEnabled>}
        </ButtonRound>
      );
    }
  };

  // @ts-ignore
  return (
    <>
      {/*Mic enabled<Switch checked={audioEnabled} />*/}
      {/*RoomState MuteEveryone Mic<Switch checked={moderationRoomState.muteEveryone} />*/}
      {/*Mic Overrride<Switch checked={moderationUserState.overrideMuteEveryone} />*/}
      {/*Video enabled<Switch checked={videoEnabled} />*/}
      {/*RoomState MuteEveryone Video<Switch checked={moderationRoomState.muteVideoEveryone} />*/}
      {/*Video Override<Switch checked={moderationUserState.overRideVideoMuteEveryone} />*/}
      <div className="call">
        <div style={{ display: showUI ? "" : "none" }}>
          <BottomBarContainer>
            <ButtonContainer>
              <AvSettings
                cams={cams}
                mics={mics}
                changeAudioInput={changeAudioInput}
                changeCameraInput={changeCameraInput}
                selectedCam={selectedCam}
                selectedMic={selectedMic}
                showSettingsModel={showSettings} />
            </ButtonContainer>
            <ButtonContainer>{renderMicButton()}</ButtonContainer>
            <ButtonContainer>{renderCamButton()}</ButtonContainer>
          </BottomBarContainer>
        </div>

        <VideoPlayer>
          <VideoWrapperScreen>

            <div className="player-container">
              <div className="local-player-wrapper">
                {videoEnabled === true && (
                  <>
                    {/*<p className='local-player-text'>{localVideoTrack && `localTrack`}{joinState && localVideoTrack ? `(${client.uid}) ${client.channelName} ${client.connectionState}` : ''}</p>*/}
                    <SingleVideoPlayerContainer>
                      <MediaPlayerVideo
                        videoTrack={localVideoTrack}
                      ></MediaPlayerVideo>
                    </SingleVideoPlayerContainer>
                  </>
                )}
              </div>
              {remoteUsers.map((user) => (
                <div className="remote-player-wrapper" key={user.uid}>
                  {/*<p className='remote-player-text'>{`remote client: ${user.uid} +audio: ${user.hasAudio} video: ${user.hasVideo}`} </p>*/}
                  {user.hasVideo === true && (
                    <>
                      <SingleVideoPlayerContainer>
                        <MediaPlayerVideo
                          videoTrack={user.videoTrack}
                        ></MediaPlayerVideo>
                      </SingleVideoPlayerContainer>
                    </>
                  )}
                  <MediaPlayerAudio
                    audioTrack={user.audioTrack}
                  ></MediaPlayerAudio>
                </div>
              ))}

            </div>
          </VideoWrapperScreen>
        </VideoPlayer>
      </div>
    </>
  );
}

export default Call;

const VideoWrapperScreen = styled.div`
  display: flex;
  pointer-events: all !important;
`;

const BottomBarContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  height: 100px;
  border-radius: 103.5px;
`;

const VideoPlayer = styled.div`
  height: 0px;
  display: flex;
  max-height: 60%;
  overflow: scroll;
  position: fixed;
  align-content: center;
`;

const ButtonContainer = styled.div``;

const SingleVideoPlayerContainer = styled.div`
  background-color: black;
  margin-bottom: 10px;
  margin-left: -20px;
  position: relative;
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.25);
`;
