const ORGANIZATION_ADMIN = "ORGANIZATION_ADMIN";
const ORGANIZATION_OWNER = "ORGANIZATION_OWNER";
const ORGANIZATION_USER = "ORGANIZATION_USER";
const ORGANIZATION_GUEST = "ORGANIZATION_GUEST";

export const ORGANIZATION_ROLE = {
  admin: ORGANIZATION_ADMIN,
  owner: ORGANIZATION_OWNER,
  user: ORGANIZATION_USER,
  guest: ORGANIZATION_GUEST,
};
