import { useQuery } from "react-query";
import { REACT_QUERY_CACHE_KEYS } from "./reactQueryCacheKeys";
import { getUserOrganizationRole } from "../../api/services/OrganizationUserServices";

export function useOrganizationRole({ userUUID, organization }) {
  const { data, isLoading } = useQuery(
    [REACT_QUERY_CACHE_KEYS.ORGANIZATION_ROLE, userUUID, organization],
    () => getUserOrganizationRole({ organization, userUUID })
  );

  return { data, isLoading };
}
