import React, { useCallback, useEffect, useState } from "react";
import Modal from ".//Modal";
import { ButtonChip } from "../button/ButtonChip";
import { ModalTitle } from "./ModalTitle";
import { ModalText } from "./ModalText";
import { FileUploadOrg } from "../file/FileUploadOrg";

export function ModalUploadFileOrganization({
  setFileState,
  organizationUuid,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState("");

  function fetchData() {
    setFileState(true);
  }

  useEffect(() => {
    setSelectedOrg(organizationUuid);
  }, [organizationUuid]);

  return (
    <div>
      <div onClick={() => setIsModalOpen(true)}>
        <ButtonChip isLightOutline width={140} height={40}>
          Upload file
        </ButtonChip>
      </div>
      {isModalOpen && (
        <Modal
          id="modal"
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        >
          <div className="box-header">
            <ModalTitle>Upload a file</ModalTitle>
            <ModalText>
              Select drop or select a file to upload, file size cannot exceed
              100 MB
            </ModalText>
          </div>
          <div className="box-body">
            <FileUploadOrg selectedOrg={selectedOrg} fetchData={fetchData} />
          </div>
          <div className="box-footer">
            <div onClick={() => setIsModalOpen(false)} className="close">
              <ButtonChip hasBackgroundColor>Done</ButtonChip>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
