import React from "react";
import Modal from "./Modal";
import { ButtonChip } from "../button/ButtonChip";
import { ModalTitle } from "./ModalTitle";
import { ModalText } from "./ModalText";

export const ModalDeleteSpace = ({
  isModalOpen,
  setIsModalOpen,
  onHandleClick,
}) => {
  return (
    <Modal
      id="modal"
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
    >
      <div className="box-header">
        <ModalTitle>Delete space</ModalTitle>
        <ModalText>Are you sure to delete space?</ModalText>
      </div>
      <div className="box-footer">
        <div className="close">
          <ButtonChip
            width={110}
            height={32}
            onClick={() => setIsModalOpen(false)}
          >
            Cancel
          </ButtonChip>
        </div>
        <ButtonChip onClick={onHandleClick} hasBackgroundColor>
          Yes
        </ButtonChip>
      </div>
    </Modal>
  );
};
