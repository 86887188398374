import { ButtonChip } from "../button/ButtonChip";
import { Link } from "react-router-dom";
import React from "react";
import styled, { css } from "styled-components";

const CustomSingleSpaceCardEvent = ({ fetching }) => {
  if (!fetching) {
    return (
      <>
        <GridItemContainer>
          <CardWrapper>
            <EnvImageWrapper>
              <GridItemContent>
                <GridItemLink
                  href={`https://app.ravel.world/events/a0c344b7-275a-4e24-9638-5fc10715bf6d`}
                >
                  <GridItemImageMask
                    src="/ade-bg-event.png"
                    alt="ade-blendrealities"
                  ></GridItemImageMask>
                  <GridItemTextContainer></GridItemTextContainer>
                </GridItemLink>
              </GridItemContent>
            </EnvImageWrapper>

            <CardContent>
              <Title>Blendrealities</Title>
              <Description>
                See the overview of the Blendrealities event and enter virtual
                worlds.
              </Description>
            </CardContent>
            <CardFooter>
              <Link to="/events/a0c344b7-275a-4e24-9638-5fc10715bf6d">
                <ButtonChip hasBackgroundColor>Visit overview</ButtonChip>
              </Link>
            </CardFooter>
          </CardWrapper>
        </GridItemContainer>
      </>
    );
  } else {
    return <></>;
  }
};

export default CustomSingleSpaceCardEvent;

const GridItemContainer = styled.div`
  height: 300px;
  position: relative;
  width: 300px;
`;

const GridItemContent = styled.div`
  height: 150px;
  width: 100%;
  position: relative;
`;

const GridItemLink = styled.a`
  //display: block;
  width: 100%;
  height: 100%;
`;

const GridItemImageMask = styled.img`
  height: 100%;
  width: 100%;
  //position: absolute;
  bottom: 0;
  background: rgb(255, 255, 255);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  //border-bottom-left-radius: 0px;
  //border-bottom-right-radius: 0px;

  //background-size: cover;
  //background-position: center;
  //background-repeat: no-repeat;
`;

const GridItemTextContainer = styled.div`
  color: #fff;
  font-size: 0.875rem;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  padding: 0 24px 20px 28px;
  text-shadow: 0 0.25rem 0.375rem rgb(0 0 0 / 30%);
`;

const EnvImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  //width: 242px;
  align-items: flex-start;
`;

const CardWrapper = styled.div`
  height: 300px;
  transition: 0.5s all;
  transform: scale(1);
  background: #c1ff3f;
  box-shadow: 0px 18px 40px rgba(112, 144, 176, 0.12);

  border-radius: 30px;

  &:hover {
    transform: scale(1.1);
    transition: 0.5s all; //var(--emo-out);
    box-shadow: 0 16px 48px 0 rgb(0 0 0 / 24%);
  }

  @media only screen and (max-width: 500px) {
    //max-height: 300px;
    //width: 300px;
  }
`;

const CardFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  //width: 280px;
  height: 10px;
  padding: 5px;
  justify-content: space-around;
  //margin-top: auto;
`;
const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 280px;
  height: 75px;
  padding: 10px;
  align-items: flex-start;
`;

const Title = styled.title`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-size: var(--font-size-20);
    font-weight: ${theme.font.weight.bold700};
    font-style: normal;
    line-height: 16px;
    /* identical to box height */
    letter-spacing: -0.531474px;
    color: var(--color-secondary-primary-black);
    //margin-left: var(--space-40);
    display: inline-flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    padding-left: 7px;
    padding-bottom: 3px;
  `}
`;
const Description = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-size: var(--font-size-10);
    font-weight: ${theme.font.weight.regular400};
    font-style: normal;
    line-height: 16px;
    /* identical to box height */
    letter-spacing: -0.531474px;
    color: var(--color-secondary-primary-black);
    //margin-left: var(--space-40);
    display: inline-flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    //width: 200px;
    max-height: 50px;
    //white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 7px;
    padding-right: 7px;
  `}
`;
