import React, { useState } from "react";
import { ButtonChip } from "../button/ButtonChip";
import { DragAndDrop } from "../DragAndDrop";
import {ProfileImageUploader} from "../ProfileImageUploader";

export const ModalUploadProfilePicture = ({ setUserUpdate }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div onClick={() => setIsModalOpen(true)}>
        <ButtonChip>Change profile</ButtonChip>
      </div>
      {isModalOpen && (
          // <ProfileImageUploader/>

        <DragAndDrop
          title="Change profile"
          exceed="2 MB"
          acceptedFileTypes=".jpeg, .png, .jpg"
          fetch="/api/v1/users/profileImages/"
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          setUserUpdate={setUserUpdate}
        />
      )}
    </>
  );
};
