import { useQuery } from "react-query";
import { REACT_QUERY_CACHE_KEYS } from "./reactQueryCacheKeys";
import { getSpacesForOrganization } from "../../api/services/SpaceProServices";

export function useSpacesPro({ organizationId, spaceType }) {
  const { data, isLoading } = useQuery(
    [REACT_QUERY_CACHE_KEYS.SPACES_PRO, organizationId, spaceType],
    () => getSpacesForOrganization({ organizationId, spaceType })
  );
  return { data, isLoading };
}
