import { useCallback, useState } from "react";
import styled, { css } from "styled-components";
import { useDropzone } from "react-dropzone";
import { getToken } from "../../helpers/CookieHelper";
import { ButtonChip } from "../button/ButtonChip";
import LoaderCirclePrimary from "../loader/LoaderCircleBig";
import {
  errorNotification,
  successNotification,
  warningNotification,
} from "../notifications/Notifications";

const apiUrl = process.env.REACT_APP_SYSTEMS_BASE_API_URL;

export const CreatorsUploadPreviewImage = ({
  environmentUuid,
  setFileState,
  handleOnPreviewImageUploaded,
}) => {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [largeFiles, setLargeFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [correctSize, setCorrectSize] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  // const { setFileState } = prop;
  const onClick = () => {
    setError(false);
    setLargeFiles([]);
  };

  const checkImageDimension = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener("load", (event) => {
      const _loadedImageUrl = event.target?.result;
      const image = document.createElement("img");
      image.src = _loadedImageUrl.toString();

      image.addEventListener("load", () => {
        const { width, height } = image;

        if (height > 1080 || height < 1080) {
          errorNotification("image must be at least 1920x1080");
          setError("Image has wrong size, please try again");
          setLargeFiles((largeFiles) => [...largeFiles, file.name]);
        }
        if (width > 1920 || width < 1920) {
          errorNotification("Image has wrong size, please try again");
          setError("Image has wrong size, please try again");
          setLargeFiles((largeFiles) => [...largeFiles, file.name]);
        }
        //if width equals and height equals
        if (width === 1920 && height === 1080) {
          setCorrectSize(true);

          setError(false);
          setLoading(true);
          const reader = new FileReader();
          reader.onabort = () =>
            setError("InputFieldError uploading file, please trying again.");
          reader.onerror = () =>
            setError("InputFieldError uploading file, please trying again.");
          reader.onload = async () => {
            let formData = new FormData();
            formData.append("image", file, file.name);
            var myHeaders = new Headers();
            myHeaders.append("accept", "application/json");
            myHeaders.append("Authorization", "Bearer " + getToken());
            await fetch(
              apiUrl +
                `/api/v1/environments/uploads/preview-images?environmentUuid=${environmentUuid}`,
              {
                method: "POST",
                headers: myHeaders,
                body: formData,
                redirect: "follow",
              }
            )
              .then((data) => {
                setLoading(false);
                if (data.status === 201) {
                  setSuccess("Upload successful: " + file.name);
                  successNotification("File(s) uploaded successfully");
                  setFileState(true);
                  setFileUploaded(true);
                }
                if (data.status === 400) {
                  errorNotification("Cannot update asset bundle");
                  setFileState(false);
                  setError("400");
                }
              })
              .catch((_) => {
                setLoading(false);
                setFileState(false);
                setError("InputFieldError uploading file, please try again.");
              });
          };
          reader.readAsArrayBuffer(file);
        } else {
          setCorrectSize(false);
          setLargeFiles((largeFiles) => [...largeFiles, file.name]);
        }
      });
    });
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      setLargeFiles([]);
      const file = acceptedFiles[0];
      if (acceptedFiles.length > 1) {
        errorNotification("You can only upload one image");
      }
      if (acceptedFiles.length === 1) {
        acceptedFiles.forEach((file) => {
          checkImageDimension(file);
          if (file.size > 8000000) {
            setLargeFiles((largeFiles) => [...largeFiles, file.name]);
            warningNotification(`${file.name} is too large.`);
            setError("InputFieldError: image must not be larger than 8MB");
          } else {
            //remove all files from acceptedFiles
            // warningNotification(`Try again`);
            // setError("Try again");
          }
        });
      }
    },
    [setFileState]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    disabled: fileUploaded,
  });

  return (
    <div style={{ marginTop: "32px" }} onClick={onClick}>
      <DropSection className="container">
        <>
          <DropZone {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} accept=" .jpeg, .png, .jpg" />
            {error && <p style={{ color: "red", marginTop: "8px" }}>{error}</p>}

            {largeFiles && (
              <p style={{ color: "red" }}>{largeFiles.toString()}</p>
            )}
            {success && <p style={{ color: "#01B574" }}>{success}</p>}

            {loading && <LoaderCirclePrimary />}
            {!loading && (
              <div>
                <p>Preview image</p>
                <br />
                <ModalText>
                  Upload a preview image here, the preview image must have an
                  size of 1920 x 1080 pixels
                </ModalText>
                <br />
                <ModalText>Drag and drop or </ModalText>
                <br />
                <ButtonChip> BROWSE </ButtonChip>
              </div>
            )}
          </DropZone>
        </>
      </DropSection>
    </div>
  );
};

const DropSection = styled.div`
  display: table-cell;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  margin: auto;
  width: 820px;
  height: 200px;
  left: 528px;
  top: 225px;
  background: #fafcfe;
  border: 1px solid #8f9bba;
  border-radius: 20px;

  .dragDrop {
    color: #4a4a68;
    //font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 8px;
    letter-spacing: 0.0617116px;
    margin-top: 24px;
  }

  .subText {
    //font-family: "DM Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #676767ff;
  }

  .or {
    //font-family: "DM Sans";
    font-style: normal;
    font-weight: 300;
    font-size: 25px;
    line-height: 8px;
    text-align: center;
    letter-spacing: 0.0617116px;
  }
`;
const DropZone = styled.div`
  display: inline-block;
  width: 100%;
`;

const ModalText = styled.p`
  ${({ theme }) => css`
    color: var(--color-secondary-primary-darkGrey-500);
    font-family: ${theme.font.family};
    font-size: var(--font-size-10);
    font-weight: ${theme.font.weight.regular400};
  `}
`;
