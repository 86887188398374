import { useEffect, useState } from "react";
import styled from "styled-components";

export const NewMessage = ({ socket, user, room }) => {
  const [value, setValue] = useState("");
  const submitForm = (e) => {
    e.preventDefault();
    console.log("submitForm", value);
    const data = {
      message: value,
      user: user,
      room: room,
    };
    console.log("room", room);
    socket.emit("message", data);
    setValue("");
  };
  //
  // useEffect(() => {
  //   const randomString =
  //     Math.random().toString(36).substring(2, 15) +
  //     Math.random().toString(36).substring(2, 15);
  //   const value = `Test message: ${randomString}`;
  //   const data = {
  //     message: value,
  //     user: user,
  //     room: room.photonRoomId,
  //   };
  //
  //   setInterval(() => {
  //     socket.emit("message", data);
  //   }, 5000);
  //
  // }, []);

  return (
    <form onSubmit={submitForm}>
      <InputFieldWrapperChat>
        <input
          tabIndex={2}
          autoFocus
          value={value}
          placeholder="Type your message"
          onChange={(e) => {
            setValue(e.currentTarget.value);
          }}
        />
      </InputFieldWrapperChat>
    </form>
  );
};

const InputFieldWrapperChat = styled.div`
  pointer-events: all;

  display: flex;
  flex-direction: column;
  width: 250px;
`;
