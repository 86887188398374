import { LayoutDefaultPageClosed } from "../../components/layout/LayoutDefaultPageClosed";
import { PageDetailsProvider } from "../../providers/PageDetailsProvider";
import { CardTitle } from "../../components/cards/CardTitle";
import { CardSubText } from "../../components/cards/CardSubText";
import { ReactComponent as GoogleLogo } from "../../assets/icons/google.svg";
import { ReactComponent as MicrosoftLogo } from "../../assets/icons/microsoft.svg";
import Card from "../../components/cards/Card";
import styled from "styled-components";
import MetaMaskAuth from "../../components/metamask/MetaMask";

export const Integrations = () => {
  function onAddressChanged() {}

  const renderMetaMask = () => {
    return <MetaMaskAuth onAddressChanged={onAddressChanged} />;
  };

  return (
    <LayoutDefaultPageClosed>
      <PageDetailsProvider
        title="Integrations"
        description="Integrations overview"
      />
      <IntegrationsContainer>
        <Card>
          <CardHeaderWrapper>
            <div>
              <CardTitle>Integrations</CardTitle>
            </div>
          </CardHeaderWrapper>


          <div>
            <CardSubText>No integrations enabled</CardSubText>
            {/*<div className="googleItem">*/}
            {/*  <CustomButtonDisabled>*/}
            {/*    <ButtonContainer>*/}
            {/*      <GoogleLogo />*/}
            {/*      <ButtonTextOS>Google</ButtonTextOS>{" "}*/}
            {/*    </ButtonContainer>*/}
            {/*  </CustomButtonDisabled>*/}
            {/*</div>*/}
            {/*<div className="microsoftItem">*/}
            {/*  <CustomButtonDisabled>*/}
            {/*    <ButtonContainer>*/}
            {/*      <MicrosoftLogo />*/}
            {/*      <ButtonTextOS>Microsoft</ButtonTextOS>{" "}*/}
            {/*    </ButtonContainer>*/}
            {/*  </CustomButtonDisabled>*/}
            {/*</div>*/}
          </div>
        </Card>
      </IntegrationsContainer>
    </LayoutDefaultPageClosed>
  );
};

const IntegrationsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const CardHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 30px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  hight: 100%;
  width: 100%;
  gap: 7px;
`;

const CustomButtonDisabled = styled.button`
  color: #4318ff;
  font-family: "DMSans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
  padding: 7px;
  width: 270px;
  height: 50px;
  background: #ffffff;
  border: 1px solid #4318ff;
  border-radius: 120px;
  margin: 10px 0px;

  &:hover {
    border: 1px solid #2200b7;
    color: #2200b7;
  }
`;

const ButtonTextOS = styled.div`
  font-family: "DMSans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
`;
