import React, { useEffect, useState } from "react";
import { SubMenuBar } from "../subMenu/SubMenuBar";
import { ContactsUserList } from "./ContactsUserList";
import { ModalAddUser } from "../modal/ModalAddUser";
import { SubMenuContainer } from "../subMenu/SubMenuContainer";
import { SubMenuItem } from "../subMenu/SubMenuItem";
import LoaderCircleBig from "../loader/LoaderCircleBig";
import { useOrganizationContacts } from "../../hooks/api/useOrganizationContacts";
import { ButtonChip } from "../button/ButtonChip";

export const ContactsList = ({
  organizationsForUser,
  selectedOrganization,
  isAdmin,
  onChange,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [organizationName, setOrganizationName] =
    useState(selectedOrganization);

  const { data: selectedOrganizationContacts, isLoading: isLoadingUsers } =
    useOrganizationContacts({
      organizationName: organizationName,
    });

  useEffect(() => {
    setOrganizationName(selectedOrganization);
  }, [organizationName, selectedOrganization]);

  return (
    <>
      <SubMenuContainer>
        <SubMenuBar>
          {organizationsForUser && (
            <SubMenuItem>
              <select
                value={selectedOrganization}
                onChange={(e) => onChange(e.target.value)}
              >
                {organizationsForUser.map((link) => (
                  <option
                    key={link.organization.organizationName}
                    value={link.organization.organizationName}
                  >
                    {link.organization.organizationName}
                  </option>
                ))}
              </select>
            </SubMenuItem>
          )}
          {isAdmin && (
            <SubMenuItem>
              <div onClick={() => setIsModalOpen(true)}>
                <ButtonChip width={140} height={40}>
                  Invite user
                </ButtonChip>
              </div>
              {isModalOpen && (
                <ModalAddUser
                  selectedOption={selectedOrganization}
                  isModalOpen={isModalOpen}
                  setIsModalOpen={setIsModalOpen}
                />
              )}
            </SubMenuItem>
          )}
        </SubMenuBar>
      </SubMenuContainer>
      <SubMenuContainer>
        {isLoadingUsers ? (
          <LoaderCircleBig />
        ) : (
          selectedOrganizationContacts && (
            <ContactsUserList
              selectedOption={selectedOrganization}
              data={selectedOrganizationContacts}
              isAdmin={isAdmin}
              refreshData={setOrganizationName}
            />
          )
        )}
      </SubMenuContainer>
    </>
  );
};
