import React from "react";
import Modal from "../../components/modal/Modal";
import { ButtonChip } from "../../components/button/ButtonChip";
import { ModalTitle } from "../../components/modal/ModalTitle";
import { ModalText } from "../../components/modal/ModalText";
import { ButtonText } from "../../components/button/ButtonText";
import styled from "styled-components";
import { CardContent } from "../../components/cards/CardContent";
import { Paragraph } from "../../components/Paragraphs";
import { ItemText } from "../../components/item/ItemText";
import { ItemTextSubtile } from "../../components/item/ItemTextSubtile";
import { ItemContainer } from "../../components/item/ItemContainer";

export const NoAccessToSpace = ({
  isModalOpen,
  returnToSpaces,
  gateDetails,
}) => {
  return (
    <>
      {!isModalOpen && (
        <Modal
          id="modal"
          isOpen={true}
          modalSize={"lg"}
          onClose={() => returnToSpaces()}
        >
          <ModalTitle>No access</ModalTitle>
          <ModalText>This Space is exclusive for token holders</ModalText>
          <ModalText>
            To enter this Space, your wallet must meet the the following
            requirements:
          </ModalText>

          <RequirementsContainer>
            <GridItemContainer>
              <CardContent>
                <ItemContainer>
                  <GridBody>
                    <ItemTextSubtile>
                      <Paragraph>Name of NFT : </Paragraph>
                    </ItemTextSubtile>
                    <ItemText>
                      <Paragraph>{gateDetails.tokenName}</Paragraph>
                    </ItemText>
                  </GridBody>
                  <GridBody>
                    <ItemTextSubtile>
                      <Paragraph>Minimum amount : </Paragraph>
                    </ItemTextSubtile>
                    <ItemText>
                      <Paragraph>{gateDetails.minimumQuantity}</Paragraph>
                    </ItemText>
                  </GridBody>
                </ItemContainer>
              </CardContent>
            </GridItemContainer>
          </RequirementsContainer>
          <a
            href={`${gateDetails.purchasingLink}`}
            target={"_blank"}
            rel="noreferrer"
          >
            <ButtonChip hasBackgroundColor width={140} height={36}>
              Get tokens
            </ButtonChip>
          </a>
          <div className="box-footer">
            <div className="close">
              <ButtonChip
                width={140}
                height={36}
                onClick={() => returnToSpaces()}
              >
                Go back
              </ButtonChip>
            </div>
          </div>
          <a
            href="https://docs.ravel.world/getting-started/token-access-on-spaces"
            target={"_blank"}
            rel="noreferrer"
          >
            <ButtonText>More info</ButtonText>
          </a>
        </Modal>
      )}
    </>
  );
};

const RequirementsContainer = styled.div`
  margin: 20px;
  display: flex;
  justify-content: center;
`;

const GridBody = styled.div`
  display: grid;
  grid-template-columns: 30% 70%;
`;

const GridItemContainer = styled.div`
  position: relative;
  width: 600px;
`;
