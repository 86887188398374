import React, { useState } from "react";
import { ReactComponent as Settings } from "../../assets/icons/setting-4.svg";
import Modal from "../../components/modal/Modal";
import { ModalTitle } from "../../components/modal/ModalTitle";
import { ModalText } from "../../components/modal/ModalText";
import { InputFieldWrapper } from "../../components/Input/InputFieldWrapper";
import { InputFieldTitle } from "../../components/Input/InputFieldTitle";
import { ButtonChip } from "../../components/button/ButtonChip";
import { Button } from "antd";

export const SelectMicInput = ({ mics, changeAudioInput, live }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [currentSelectedMic, setCurrentSelectedMic] = useState("Select mic");

  function handleOnChangeMic(selectedMic) {
    const filteredMics = mics.filter((mic) => mic.deviceId === selectedMic);
    setCurrentSelectedMic(filteredMics[0].label);
    changeAudioInput(selectedMic);
  }

  const renderMics = () => {
    if (!mics) {
      return <></>;
    }
    return (
      <div>
        <div onClick={() => setIsModalOpen(true)}>
          <Button disabled={!live}>Change mic / input</Button>
        </div>
        {isModalOpen && (
          <Modal
            id="modal"
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            modalClass={"modal-av-settings"}
            modalSize={"md"}
          >
            <div className="box-header">
              <ModalTitle>Settings</ModalTitle>
              <ModalText>Change mic / input here</ModalText>
            </div>
            <div className="box-body">
              <InputFieldWrapper>
                <InputFieldTitle>Microphone</InputFieldTitle>
                <select
                  value={mics}
                  onChange={(e) => handleOnChangeMic(e.target.value)}
                >
                  <option label={currentSelectedMic}>
                    {currentSelectedMic}
                  </option>
                  {mics.map((link, index) => (
                    <option key={link.deviceId} value={link.deviceId}>
                      {link.label}
                    </option>
                  ))}{" "}
                </select>
              </InputFieldWrapper>
            </div>
            <div className="box-footer">
              <div onClick={() => setIsModalOpen(false)} className="close">
                <ButtonChip hasBackgroundColor>Done</ButtonChip>
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  };

  return <>{renderMics()}</>;
};
