import React, { useState } from "react";

import { ButtonChip } from "../button/ButtonChip";
import { deleteNonPublishedEnvironment } from "../../api/services/EnvironmentServices";
import {
  errorNotification,
  successNotification,
} from "../notifications/Notifications";
import { useNavigate } from "react-router-dom";
import Modal from "../modal/Modal";
import { ModalTitle } from "../modal/ModalTitle";
import { ModalText } from "../modal/ModalText";

export const CreatorsConfirmDeleteEnvironment = ({ environmentUuid }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  async function handleOnDeleteEnvironment() {
    const response = await deleteNonPublishedEnvironment(environmentUuid);
    if (response.status === 204) {
      successNotification("Deleted environment");
      navigate("/creators");
    }

    if (response.status === 404) {
      errorNotification(response.data.message);
    } else {
      // errorNotification(response.data.message);
    }
  }

  return (
    <div>
      <div onClick={() => setIsModalOpen(true)}>
        <ButtonChip>Delete</ButtonChip>
      </div>
      {isModalOpen && (
        <Modal
          id="modal"
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        >
          <ModalTitle>Delete environment</ModalTitle>
          <ModalText>
            Confirm to delete your environment, remember that only non-published
            environments can be deleted.
          </ModalText>

          <div className="box-footer">
            <div onClick={() => setIsModalOpen(false)} className="close">
              <ButtonChip width={110} height={32}>
                Cancel
              </ButtonChip>
            </div>
            <div onClick={() => handleOnDeleteEnvironment()} className="close">
              <ButtonChip hasBackgroundColor>Delete</ButtonChip>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};
