import React, { useState } from "react";

import { ButtonChip } from "../button/ButtonChip";
import Modal from "./Modal";
import { ModalTitle } from "./ModalTitle";
import { ModalText } from "./ModalText";
import { CreatorsUploadPreviewImage } from "../creators/CreatorsUploadPreviewImage";

export const ModalUpdatePreviewImage = ({ environmentUuid }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [filePreviewImage, setFileStatePreviewImage] = useState(false);

  function handleOnPreviewImageUploaded() {}

  return (
    <>
      <div onClick={() => setIsModalOpen(true)}>
        <ButtonChip>Update image</ButtonChip>
      </div>
      {isModalOpen && (
        <Modal
          id="modal"
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        >
          <ModalTitle>Update preview image</ModalTitle>
          <ModalText>
            Updating your preview image, only environments that are not
            published can be updated
          </ModalText>

          <CreatorsUploadPreviewImage
            environmentUuid={environmentUuid}
            setFileState={setFileStatePreviewImage}
            handleOnPreviewImageUploaded={handleOnPreviewImageUploaded}
          />

          <div className="box-footer">
            <div onClick={() => setIsModalOpen(false)} className="close">
              <ButtonChip width={110} height={32}>
                Cancel
              </ButtonChip>
            </div>
            <div onClick={() => setIsModalOpen(false)} className="close">
              <ButtonChip hasBackgroundColor>Done</ButtonChip>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
