import styled from "styled-components";

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  //width: 280px;
  //height: 75px;
  padding: 30px;
  align-items: flex-start;
  gap: 40px;
`;
