import {SubMenuContainer} from '../subMenu/SubMenuContainer';
import {SubMenuBar} from '../subMenu/SubMenuBar';
import {SubMenuItem} from '../subMenu/SubMenuItem';
import TabLeft from '../tabs/TabLeft';
import TabLeftSecondary from '../tabs/TabLeftSecondary';
import TabMiddle from '../tabs/TabMiddle';
import TabMiddleSecondary from '../tabs/TabMiddleSecondary';
import TabRight from '../tabs/TabRight';
import TabRightSecondary from '../tabs/TabRightSecondary';
import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useOrganizationRole} from '../../hooks/api/useOrganizationRole';
import {AuthContext} from '../../providers/AuthContextProvider';
import {ORGANIZATION_ROLE} from '../../constants/organizations';
import {ButtonChip} from '../button/ButtonChip';
import {SpacesAdd} from './SpacesAdd';
import {infoNotification} from '../notifications/Notifications';

export const SpacesNavigationMenu = ({
  showPublic,
  showOrganization,
  userOrganizationMember,
  organizationsForUser,
  selectedObject,
  spaceType,
}) => {
  const {
    user: {userUUID},
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedOptionObject, setSelectedOptionObject] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [createNewSpace, setCreateNewSpace] = useState(false);

  const {data: userRole} = useOrganizationRole({
    organization:
        selectedOption || organizationsForUser
            ? organizationsForUser[0]?.organization?.organizationName
            : null,
    userUUID,
  });

  const handleOnClickPublic = () => {
    navigate('/spaces/public');
  };

  const handleOnClickPrivate = () => {
    // navigate('/spaces/private');
    infoNotification("Private spaces are not yet available");
  };

  const handleOnClickOrganization = () => {
    if (!organizationsForUser) return;
    navigate(
        `/spaces/organization/${organizationsForUser[0].organization?.organizationId}`);
  };

  function handleOnChangeSelected(value) {
    const currentSelectionObject = organizationsForUser.find(
        (organization) => organization.organization.organizationName === value,
    );
    setSelectedOption(currentSelectionObject.organization.organizationName);
    setSelectedOptionObject(currentSelectionObject);
    if (
        currentSelectionObject.organizationRole === ORGANIZATION_ROLE.admin ||
        currentSelectionObject.organizationRole === ORGANIZATION_ROLE.owner
    ) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
    navigate(
        `/spaces/organization/${currentSelectionObject.organization.organizationId}`);
  }

  useEffect(() => {
    if (selectedObject?.organization) {
      setSelectedOptionObject(selectedObject);
      setSelectedOption(selectedObject.organization.organizationName);
      if (selectedObject.organizationRole === ORGANIZATION_ROLE.admin ||
          selectedObject.organizationRole === ORGANIZATION_ROLE.owner) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }

    }
  }, [selectedObject]);

  return (
      <>
        <>
          <SubMenuContainer>
            <SubMenuBar>
              <SubMenuItem>
                {showOrganization &&
                    userOrganizationMember &&
                    organizationsForUser?.length > 0 && (
                        <SubMenuItem>
                          <select
                              value={selectedOption}
                              onChange={(e) => handleOnChangeSelected(
                                  e.target.value)}
                          >
                            {organizationsForUser.map((link) => (
                                <option
                                    key={link.organization.organizationName}
                                    value={link.organization.organizationName}
                                >
                                  {link.organization.organizationName}
                                </option>
                            ))}
                          </select>
                        </SubMenuItem>
                    )}
              </SubMenuItem>
              <SubMenuItem>
                <div onClick={handleOnClickPublic}>
                  {showPublic ? (
                      <TabLeft>Public</TabLeft>
                  ) : (
                      <TabLeftSecondary>Public</TabLeftSecondary>
                  )}
                </div>
                {
                    userOrganizationMember &&
                    organizationsForUser?.length > 0 && (
                        <>
                          <div onClick={handleOnClickOrganization}>
                            {showOrganization && userOrganizationMember ? (
                                <TabRight>Organization</TabRight>
                            ) : (
                                userOrganizationMember && (
                                    <TabRightSecondary>Organization</TabRightSecondary>
                                )
                            )}
                          </div>
                        </>
                    )
                }
                {/*<div onClick={handleOnClickPrivate}>*/}
                {/*  {!showPublic && !showOrganization ? (*/}
                {/*      <TabRight>Private</TabRight>*/}
                {/*  ) : (*/}
                {/*      <TabRightSecondary>Private</TabRightSecondary>*/}
                {/*  )}*/}
                {/*</div>*/}
              </SubMenuItem>
              <SubMenuItem>
                {showOrganization && isAdmin && (
                    <>
                      <SpacesAdd
                          setCreateNewSpace={setCreateNewSpace}
                          selectedType={spaceType}
                          userOrganizationMember={userOrganizationMember}
                          selectedOrganization={selectedOptionObject}
                      />
                    </>
                )}
              </SubMenuItem>
            </SubMenuBar>
          </SubMenuContainer>
        </>
      </>
  );
};
