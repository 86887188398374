import { useForm } from "react-hook-form";
import { useState } from "react";
import styles from "../../styles/DefaultForm.modules.css";
import { InputFieldWrapper } from "../Input/InputFieldWrapper";
import { InputFieldTitle } from "../Input/InputFieldTitle";
import { InputFieldError } from "../Input/InputFieldError";
import LoaderCircleSmall from "../loader/LoaderCircleSmall";
import { verifyCode } from "../../api/services/AuthenticationServices";
import {
  errorNotification,
  successNotification,
} from "../notifications/Notifications";

export const FormPairDevice = ({ closeModal }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const onCLose = () => closeModal(false);

  const onSubmit = async ({ code }) => {
    setLoading(true);
    try {
      await verifyCode({ code });
      setLoading(false);
      successNotification("Device paired successfully");
      setLoading(false);
      onCLose();
    } catch (error) {
      errorNotification(error.message);
      setLoading(false);
    }
  };

  return (
    <div className={styles.formContainer}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputFieldWrapper>
          <InputFieldTitle>Code</InputFieldTitle>
          <input {...register("code", { required: true })} type="text" />
          {errors?.firstName && (
            <InputFieldError>Code is required</InputFieldError>
          )}
        </InputFieldWrapper>

        <button type="submit">
          {loading ? <LoaderCircleSmall /> : "Pair"}
        </button>
      </form>
    </div>
  );
};
