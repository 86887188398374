import React from "react";
import Modal from "./Modal";
import { ModalTitle } from "./ModalTitle";
import { ModalText } from "./ModalText";
import { ButtonChip } from "../button/ButtonChip";
import { FormAddUserToOrg } from "../form/FormAddUserToOrg";

export const ModalAddUser = ({
  selectedOption,
  isModalOpen,
  setIsModalOpen,
}) => {
  return (
    <Modal
      id="modal"
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
    >
      <ModalTitle>Invite user</ModalTitle>
      <ModalText>
        Provide the email address and select a role for the person you want to
        invite to your organization
      </ModalText>
      <div className="box-body">
        <FormAddUserToOrg selectedOption={selectedOption} />
      </div>

      <div className="box-footer">
        <div onClick={() => setIsModalOpen(false)} className="close">
          <ButtonChip width={110} height={32}>
            Cancel
          </ButtonChip>
        </div>
      </div>
    </Modal>
  );
};
