import React, { useEffect, useState } from "react";
import { ReactComponent as MetaMaskLogo } from "../../assets/icons/metamask.svg";
import styled from "styled-components";
import {
  errorNotification,
  successNotification,
} from "../notifications/Notifications";
import {
  connectWallet,
  disconnectWallet,
  getIntegrations,
} from "../../api/services/IntegrationsServices";
import { Button } from "antd";

function isMobileDevice() {
  return "ontouchstart" in window || "onmsgesturechange" in window;
}

/**
 * will try to connect to metamask and if successfully will store the address via the api in the backend
 */
async function connect(onConnected) {
  if (!window.ethereum) {
    errorNotification("Please install MetaMask");
    return;
  }
  await window.ethereum
    .request({
      method: "eth_requestAccounts",
    })
    .then(async (accounts) => {
      const chainId = await window.ethereum.request({
        method: "eth_chainId",
      });
      if (chainId === "0x1") {
        connectWallet(accounts[0], `ETHEREUM`).then(async (response) => {
          if (response.status === 200) {
            onConnected(accounts[0]);
            successNotification("Successfully connected to MetaMask");
          } else {
            errorNotification("Something went wrong, try again");
          }
        });
      } else {
        errorNotification("Something went wrong, try again");
      }
    });
}

export default function MetaMaskAuth({ onAddressChanged }) {
  const [userAddress, setUserAddress] = useState("");

  useEffect(async () => {
    await getIntegrations().then(async (response) => {
      if (response.status === 200) {
        if (response.data.metaMaskWallet === null) {
          return;
        }
        setUserAddress(response.data.metaMaskWallet.address);
      }
    });
  }, []);

  useEffect(() => {
    onAddressChanged(userAddress);
  }, [userAddress]);

  function Connect({ setUserAddress }) {
    if (isMobileDevice()) {
      const dappUrl = "dev.ravel.world";
      const metamaskAppDeepLink = "https://metamask.app.link/dapp/" + dappUrl;
      return (
        <a href={metamaskAppDeepLink}>
          <CustomButton>
            <ButtonContainer>
              <MetaMaskLogo />
              <ButtonTextOS>Connect MetaMask</ButtonTextOS>
            </ButtonContainer>
          </CustomButton>
        </a>
      );
    }

    return (
      <div onClick={() => connect(setUserAddress)}>
        <CustomButton>
          <ButtonContainer>
            <MetaMaskLogo />
            <ButtonTextOS>Connect MetaMask</ButtonTextOS>
          </ButtonContainer>
        </CustomButton>
      </div>
    );
  }

  async function deleteAddressFromAccount(userAddress) {
    await disconnectWallet(userAddress).then(async (response) => {
      if (response.status === 204) {
        successNotification("Successfully removed wallet");
        setUserAddress("");
      } else {
        errorNotification("Something went wrong, try again");
      }
    });
  }

  function Address({ userAddress }) {
    return (
      <>
        <span
          style={{
            fontSize: "12px",
            fontWeight: "regular400",
            backgroundColor: "#F4F7FE",
            borderRadius: "4px",
            padding: "8px",
          }}
        >
          {userAddress.substring(0, 5)}…
          {userAddress.substring(userAddress.length - 4)}
        </span>
        <Button
          onClick={() => deleteAddressFromAccount(userAddress)}
          type="text"
          danger
        >
          Remove
        </Button>
      </>
    );
  }

  return userAddress ? (
    <div>
      <Wrapper>
        <CustomButton>
          <ButtonContainer>
            <MetaMaskLogo />
            <ButtonTextOS>Connected</ButtonTextOS>
          </ButtonContainer>
        </CustomButton>
        Current connected address: :
        <Address userAddress={userAddress} />
      </Wrapper>
    </div>
  ) : (
    <Connect setUserAddress={setUserAddress} />
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  hight: 100%;
  width: 100%;

  gap: 7px;
`;

const ButtonTextOS = styled.div`
  font-family: "DMSans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
`;

const CustomButton = styled.button`
  font-family: "DMSans";

  color: #ffffff;
  font-family: "DMSans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
  padding: 7px;
  width: 270px;
  height: 50px;
  background: #4318ff;
  border: 0px solid #000000;
  border-radius: 120px;
  margin: 24px 0px;

  :hover {
    background: #2200b7;
    color: #ffffff;
    border: 0px solid #000000;
    transition: 0.3s all;
  }

  :active {
    transition: 0.3s all;
    transform: translateY(3px);
    opacity: 0.8;
  }
`;
