import styled from "styled-components";
import { BadgeDefault } from "../../styles/BadgeDefault";

const ButtonWrapper = styled.div`
  ${BadgeDefault}
  width: 50px;
  height: 8px;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000;
  background: rgba(225, 72, 72, 0.7);
`;

const BadgeSmallRed = (props) => (
  <ButtonWrapper className={props.className}>
    <span className="title">{props.children}</span>
  </ButtonWrapper>
);

export default BadgeSmallRed;
