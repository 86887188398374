import React, { useState } from "react";
import Modal from "./Modal";
import { ButtonChip } from "../button/ButtonChip";
import { ModalTitle } from "./ModalTitle";
import { ModalText } from "./ModalText";

import styled, { css } from "styled-components";

export const ModalLeaveSpace = ({ leaveSpace }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOnConfirm = () => {
    leaveSpace();
    setIsModalOpen(false);
  };

  return (
    <>
      <div onClick={() => setIsModalOpen(true)}>
        <ButtonChipCustom>Leave</ButtonChipCustom>
      </div>
      {isModalOpen && (
        <Modal
          id="modal"
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        >
          <ModalTitle>Leave space</ModalTitle>
          <ModalText>Are you sure to leave the space?</ModalText>
          <div className="box-footer">
            <div onClick={() => setIsModalOpen(false)} className="close">
              <ButtonChip width={110} height={32}>
                No
              </ButtonChip>
            </div>
            <div onClick={() => handleOnConfirm()} className="close">
              <ButtonChip hasBackgroundColor>Yes</ButtonChip>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

const ButtonChipCustom = styled.button`
  ${({ theme }) => css`
    width: 140px;
    height: 46px;
    font-size: 14px;
    line-height: 24px;
    text-align: center;

    /* identical to box height, or 171% */

    text-align: center;

    pointer-events: all;

    font-family: ${theme.font.family};
    color: ${theme.colour.secondary.dark};
    background: rgba(224, 229, 242, 0.88);
    border: 0px solid;
    border-color: ${theme.colour.secondary.greyDark};
    box-shadow: 0 16px 32px 0 rgb(0 0 0 / 24%);

    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 10px;
    border-radius: 70px;
    left: calc(50% - 84px / 2);
    top: calc(50% - 34px / 2 - 27px);
    transition: 0.5s all; // var(--emo-out);
    transform: scale(1);
    z-index: 1000;

    &:hover {
      background: rgba(224, 229, 242, 0.88);
      transform: scale(1.2);
      transition: 0.5s all; //var(--emo-out);
      box-shadow: 0 16px 48px 0 rgb(0 0 0 / 24%);
    }

    &:focus {
    }

    &:disabled {
      background: rgba(224, 229, 242, 0.5);
    }
  `}
`;
