import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import {
  errorNotification,
  successNotification,
} from "../notifications/Notifications";
import styles from "../../styles/DefaultForm.modules.css";
import { InputFieldWrapper } from "../Input/InputFieldWrapper";
import { InputFieldTitle } from "../Input/InputFieldTitle";
import { InputFieldError } from "../Input/InputFieldError";
import LoaderCircleSmall from "../loader/LoaderCircleSmall";

import { submitNewEnvironment } from "../../api/services/EnvironmentServices";
import { AuthContext } from "../../providers/AuthContextProvider";

export const FormSubmitNewEnvironment = ({ handleOnEnvironmentCreated }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);
  const {
    user: { userUUID },
  } = useContext(AuthContext);

  async function onSubmit({ name, shortSummary, longSummary, status }) {
    setLoading(true);
    try {
      const response = await submitNewEnvironment({
        userUUID,
        name,
        shortSummary,
        longSummary,
        isPublic: status,
      });
      successNotification("Environment created successfully");
      handleOnEnvironmentCreated(response.data);
      setLoading(false);
    } catch (error) {
      errorNotification(error.message);
      setLoading(false);
    }
  }

  return (
    <>
      <div className={styles.formContainer}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputFieldWrapper>
            <InputFieldTitle>Name</InputFieldTitle>
            <input {...register("name", { required: true })} type="text" />
            {errors.creatorName && (
              <InputFieldError>Name is required</InputFieldError>
            )}
          </InputFieldWrapper>
          <InputFieldWrapper>
            <InputFieldTitle>Short summary</InputFieldTitle>
            <input
              {...register("shortSummary", { required: true })}
              type="text"
            />
            {errors?.creatorBio && (
              <InputFieldError>Short summary is required</InputFieldError>
            )}
          </InputFieldWrapper>
          <InputFieldWrapper>
            <InputFieldTitle>Long summary</InputFieldTitle>
            <input
              {...register("longSummary", { required: true })}
              type="text"
            />
            {errors?.creatorBio && (
              <InputFieldError>Long summary is required</InputFieldError>
            )}
          </InputFieldWrapper>

          <InputFieldWrapper>
            <InputFieldTitle>
              Public or private environment, default is private
            </InputFieldTitle>
            <select {...register("status", { required: true })}>
              <option value="false">Private</option>
              <option value="true">Public</option>
            </select>
          </InputFieldWrapper>

          <button type="submit">
            {loading ? <LoaderCircleSmall /> : "Create"}
          </button>
        </form>
      </div>
    </>
  );
};
