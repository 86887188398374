import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { SubMenuContainer } from "../subMenu/SubMenuContainer";
import { SubMenuBar } from "../subMenu/SubMenuBar";
import { SubMenuItem } from "../subMenu/SubMenuItem";
import { ModalUploadFile } from "../modal/ModalUploadFile";
import { ContentFilesList } from "./ContentFilesList";
import { useUserFile } from "../../hooks/api/useUserFiles";

export const ContentFiles = () => {
  const [fileState, setFileState] = useState(true);
  const [userFiles, setUserFiles] = useState(null);

  const { data, isLoading } = useUserFile({ userFiles, enabled: fileState });

  useEffect(() => {
    if (fileState && data) {
      setFileState(false);
      setUserFiles(data);
    }
  }, [data, userFiles, fileState]);

  return (
    <>
      <SubMenuContainer>
        <SubMenuBar>
          <SubMenuItem>
            <ModalUploadFile setFileState={setFileState} />
          </SubMenuItem>
        </SubMenuBar>
      </SubMenuContainer>
      <ContentContainer>
        <ContentFilesList
          setFileState={setFileState}
          data={userFiles}
          isLoading={isLoading}
        />
      </ContentContainer>
    </>
  );
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
