import client from "../Client";

const apiUrl = process.env.REACT_APP_SYSTEMS_BASE_API_URL;

export const getOrganizationContacts = async (organizationName) =>
  client
    .get(`${apiUrl}/api/v1/organizations/organizations/${organizationName}`)
    .then(({ data }) => data)
    .catch((error) => {
      console.error(error);
      throw error;
    });

export const getOrganizationsForUser = async ({ userUUID }) =>
  client
    .get(`${apiUrl}/api/v2/organizations/organizationsdetailed/${userUUID}`)
    .then(({ data }) => data)
    .catch((error) => {
      console.error(error);
      throw error;
    });

export const inviteUserToOrg = async ({
  organizationName,
  userEmail,
  organizationRole,
}) =>
  client
    .post(`${apiUrl}/api/v1/organizations/users`, {
      organizationName: organizationName,
      userEmail: userEmail,
      organizationRole: organizationRole,
    })
    .then(({ data }) => data)
    .catch((error) => {
      console.error(error);
      throw error;
    });

export const deleteUserFromOrg = async ({ organizationName, userUUID }) =>
  client
    .delete(
      `${apiUrl}/api/v2/organizations/${organizationName}/userUuid/${userUUID}`
    )
    .then((data) => data)
    .catch((error) => {
      console.error(error);
      throw error;
    });

export const updateOrganizationRole = async ({
  organizationName,
  userUUID,
  organizationRole,
}) =>
  client
    .put(
      `${apiUrl}/api/v2/organizations/users/roles/${organizationName}/usersUuid/${userUUID}?organizationRole=${organizationRole}`
    )
    .then((data) => data)
    .catch((error) => {
      console.error(error);
      throw error;
    });

export const getUserOrganizationRole = async ({ organization, userUUID }) =>
  client
    .get(
      `${apiUrl}/api/v1/organizations/users/roles/${organization}/usersuuid/${userUUID}`
    )
    .then(({ data }) => data.organizationRole)
    .catch((error) => {
      console.error(error);
      throw error;
    });
