import Card from "../../components/cards/Card";
import { LayoutDefaultPageOpen } from "../../components/layout/LayoutDefaultPageOpen";
import { Link } from "react-router-dom";
import { FormForgotPassword } from "../../components/form/FormForgotPassword";
import { CardTitle } from "../../components/cards/CardTitle";
import { CardSubText } from "../../components/cards/CardSubText";
import { ButtonText } from "../../components/button/ButtonText";
import { CardHeaderWrapper } from "../../components/cards/CardHeaderWrapper";
import { useState } from "react";

export const ForgotPassword = () => {
  const [emailHasBeenSend, setEmailHasBeenSend] = useState(false);

  function emailSend() {
    setEmailHasBeenSend(true);
  }

  return (
    <LayoutDefaultPageOpen>
      <Card>
        {emailHasBeenSend ? (
          <CardHeaderWrapper>
            <div>
              <CardTitle>Forgot password</CardTitle>
            </div>
            <div>
              <CardSubText>
                Please check your email for the reset password link
              </CardSubText>
            </div>
          </CardHeaderWrapper>
        ) : (
          <>
            <CardHeaderWrapper>
              <div>
                <CardTitle>Forgot password</CardTitle>
              </div>
              <div>
                <CardSubText>
                  Enter your email address to receive an email to reset your
                  password
                </CardSubText>
              </div>
            </CardHeaderWrapper>
            <FormForgotPassword emailSend={emailSend} />
          </>
        )}
        <Link to="/login">
          <ButtonText>Log in</ButtonText>
        </Link>
      </Card>
    </LayoutDefaultPageOpen>
  );
};
