import Messages from "./Messages";
import { NewMessage } from "./NewMessage";
import React from "react";
import styled from "styled-components";
import Draggable from "react-draggable";

export const ChatComponent = ({ socket, user, showChatCard, room }) => {
  return (
    <>
      <Draggable
      // bounds="body"
      >
        <ChatCard
          style={{ display: showChatCard ? "" : "none", pointerEvents: "all" }}
        >
          <CardContentCustom>
            <Messages socket={socket} user={user} room={room} />
            <NewMessage socket={socket} user={user} room={room} />
          </CardContentCustom>
        </ChatCard>
      </Draggable>
    </>
  );
};

const ChatCard = styled.div`
  background: #ffffff;
  display: flex;
  justify-content: center;
  //padding: 10px 10px;

  box-shadow: 0px 18px 40px rgba(112, 144, 176, 0.12);
  border-radius: 40px;
  //margin: 10px;
`;

const CardContentCustom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 30px;
  align-items: flex-start;
  gap: 10px;
`;
