import { useQuery } from "react-query";
import { REACT_QUERY_CACHE_KEYS } from "./reactQueryCacheKeys";
import { getEnvironmentsForCreatingSpace } from "../../api/services/EnvironmentServices";

export function useSpaceEnivroments({ environmentType, organizationId }) {
  const { data, isLoading } = useQuery(
    [REACT_QUERY_CACHE_KEYS.SPACES_ADD, environmentType, organizationId],
    () =>
      getEnvironmentsForCreatingSpace({
        environmentType,
        organizationId,
      })
  );

  return { data, isLoading };
}
