import Card from "../../components/cards/Card";
import styled from "styled-components";
import { CardTitle } from "../../components/cards/CardTitle";
import React, { useContext, useEffect, useState } from "react";
import {
  infoNotification,
  warningNotification,
} from '../../components/notifications/Notifications';
import { useParams } from "react-router-dom";
import { Avatar, Button, Checkbox, Switch, Table, Typography } from "antd";
import { CardContent } from "../../components/cards/CardContent";
import { InputFieldTitle } from "../../components/Input/InputFieldTitle";
import { InputFieldWrapper } from "../../components/Input/InputFieldWrapper";
import { ReactComponent as MicEnabled } from "../../assets/icons/microphone-2.svg";
import { ReactComponent as MicDisables } from "../../assets/icons/microphone-slash.svg";
import { ReactComponent as CameraEnabled } from "../../assets/icons/video.svg";
import { ReactComponent as CameraDisables } from "../../assets/icons/video-slash.svg";
import { AuthContext } from "../../providers/AuthContextProvider";
import BadgeSmallGreyNoWidth from "../../components/badge/BadgeSmallGreyNoWidth";
import { ItemTextSubtile } from "../../components/item/ItemTextSubtile";
import { ItemText } from "../../components/item/ItemText";

export const OnlineUserList = ({ socket, spacePro, allowedPermissions }) => {
  const [stateLoaded, setStateLoaded] = useState(false);
  const { room } = useParams();
  const [userList, setUserList] = useState([]);
  const columns = [
    {
      title: "",
      dataIndex: "avatar",
      width: 82,
      key: "avatar",
      render: (text, user) => (
        <Avatar
          shape="square"
          size={50}
          src={user.state.userDetails.profileImageUrl}
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "state",
      render: (item) => (
        <>
          <div>{item === null ? "" : item.userDetails.firstName}</div>
        </>
      ),
    },
    {
      title: "Role",
      dataIndex: "sessionDetails",
      render: (item) => (
        <>
          <div>
            {item === null ? (
              "null"
            ) : (
              <>
                {item.userSpaceRoles.map((role, index) => (
                  <BadgeSmallGreyNoWidth key={role.appRoleName}>
                    {role.length - 1 === index
                      ? role.appRoleName
                      : role.appRoleName}
                  </BadgeSmallGreyNoWidth>
                ))}
              </>
            )}
          </div>
        </>
      ),
    },
    {
      title: "Raised hand",
      dataIndex: "state",
      render: (item) => <>{item.raisingHand ? <>✋</> : <></>}</>,
    },
    {
      title: "Allow microphone, will show mic button",
      // dataIndex: 'id',
      key: "actions",
      render: (item) => (
        <>
          <Switch
            checked={item.state.overrideMuteEveryone}
            onChange={(value) => {
              handleAllowMicSingleUser(item.id, value);
            }}
          />
        </>
      ),
    },
    {
      title: "Mic status",
      // dataIndex: 'id',
      key: "actions",
      render: (item) => (
        <>
          {item.state.muted === false ? (
            <div
              onClick={(value) => {
                handleMuteMicSingleUser(item.id, value);
              }}
            >
              <MicEnabled></MicEnabled>
            </div>
          ) : (
            <MicDisables></MicDisables>
          )}
        </>
      ),
    },
    {
      title: "Allow cam, will show cam button",
      // dataIndex: 'id',
      key: "actions",
      render: (item) => (
        <>
          <Switch
            checked={item.state.overRideVideoMuteEveryone}
            onChange={(value) => {
              handleAllowWebCamSingleUser(item.id, value);
            }}
          />
        </>
      ),
    },
    {
      title: "Cam status",
      // dataIndex: 'id',
      key: "actions",
      render: (item) => (
        <>
          {/*{item.state.videoMuted === false ?  <CameraEnabled></CameraEnabled> : <CameraDisables></CameraDisables>}*/}
          <>
            {item.state.videoMuted === false ? (
              <div
                onClick={(value) => {
                  handleMuteCamSingleUser(item.id, value);
                }}
              >
                <CameraEnabled></CameraEnabled>
              </div>
            ) : (
              <CameraDisables></CameraDisables>
            )}
          </>
        </>
      ),
    },

    {
      title: "Current state",
      // dataIndex: 'id',
      key: "actions",
      render: (item) => <>{item.state.currentState}</>,
    },
    {
      title: "Kick user",
      // dataIndex: 'id',
      key: "actions",
      render: (item) => (
        <>
          <Button
            onClick={(value) => {
              handleKickUser(item.id, value);
            }}
          >
            Kick user
          </Button>
        </>
      ),
    },
  ];

  const [roomState, setRoomState] = useState({
    allowSeating: false,
    forcedSeats: false,
    lightsOn: false,
    movementDisabled: false,
    muteEveryone: false,
    muteVideoEveryone: false,
    raiseHandEnabled: false,
    showNameTags: true,
    userOnStage: "",
  });

  const adminConnectionDetails = {
    room: room,
    password: "levaR",
  };

  useEffect(() => {
    socket.emit("admin", adminConnectionDetails);
    socket.on("admin-result", handleAdminResult);
    socket.on("state", (state) => {
      updateState(state);
    });
    socket.on("update", (state) => {
      updateState(state);
    });
    socket.on("users", (users) => {
      setupUsers(users);
    });
    return () => {
      socket.off("admin-result");
      socket.off("state");
      socket.off("update");
      socket.off("users");
    };
  }, [socket]);

  function handleKickUser(id) {
    infoNotification("Kicking user");
    const data = {
      userState: {
        kicked: true,
      },
      id: id,
      room: room,
    };
    socket.emit("updateUserStateByAdmin", data);
  }

  function handleAllowMicSingleUser(id, value) {
    // if(value === true){
    const data = {
      userState: {
        overrideMuteEveryone: value,
      },
      id: id,
      room: room,
    };
    socket.emit("updateUserStateByAdmin", data);
    // } else{
    //   warningNotification("It's not allowed to enable the mic for a single user")
    // }
  }

  function handleAllowWebCamSingleUser(id, value) {
    // if(value === true){
    const data = {
      userState: {
        overRideVideoMuteEveryone: value,
      },
      id: id,
      room: room,
    };
    socket.emit("updateUserStateByAdmin", data);
    // } else{
    //   warningNotification("It's not allowed to enable the mic for a single user")
    // }
  }

  function handleMuteMicSingleUser(id, value) {
    const data = {
      userState: {
        muted: true,
      },
      id: id,
      room: room,
    };
    socket.emit("updateUserStateByAdmin", data);
  }

  function handleMuteCamSingleUser(id, value) {
    const data = {
      userState: {
        videoMuted: true,
      },
      id: id,
      room: room,
    };
    socket.emit("updateUserStateByAdmin", data);
  }

  function handleMuteAllUsers(e) {
    warningNotification("Mute all users");
    const data = {
      action: "muteEveryone",
      actionValue: e,
      room: room,
    };
    socket.emit("action", data);
    roomState.muteEveryone = e;
    //TODO maybe update state of mute a singel user when force mute all
    // userList.filter(user => user.state.muted === false).forEach(user => {
    //   socket.emit("toggleMute", { id: user.id, muted: e })
    // })
  }

  function handleMuteVideoAllUsers(e) {
    warningNotification("Mute all users");
    const data = {
      action: "muteVideoEveryone",
      actionValue: e,
      room: room,
    };
    socket.emit("action", data);
    roomState.muteVideoEveryone = e;
    //TODO maybe update state of mute a singel user when force mute all
    // userList.filter(user => user.state.muted === false).forEach(user => {
    //   socket.emit("toggleMute", { id: user.id, muted: e })
    // })
  }

  function handleAdminResult(response) {
    const data = {
      room: room,
    };
    socket.emit("getUsers", data);
    socket.emit("state", data);
  }

  function updateState(state) {
    setRoomState(state);
  }

  function setupUsers(user) {
    console.log("users", user);
    setUserList(user);
  }

  const renderModOptions = () => {
    if (!stateLoaded) {
      return <></>;
    }
    return <></>;
  };

  const { Paragraph, Text, Link } = Typography;

  return (
    <>
      <Card>
        <CardHeaderWrapper>
          <CardTitle>{spacePro?.spaceName}</CardTitle>
          <br />

          <InfoContainer>
            <GridBody>
              <ItemTextSubtile>
                <Paragraph>Host in room: </Paragraph>
              </ItemTextSubtile>
              <ItemText>
                <Paragraph>{spacePro?.spaceName}</Paragraph>
              </ItemText>
              <ItemTextSubtile>
                <Paragraph>Max ccu: </Paragraph>
              </ItemTextSubtile>
              <ItemText>
                <Paragraph>{spacePro?.maxOnlineUsers}</Paragraph>
              </ItemText>
            </GridBody>
          </InfoContainer>
          <br />
          <InputFieldWrapper>
            <InputFieldTitle>Mute everyone's mic, will also disable the mic button meaning its not visible. </InputFieldTitle>
            <ActionContainer>
              <Switch
                checked={roomState.muteEveryone}
                onChange={() => {
                  handleMuteAllUsers(!roomState.muteEveryone);
                }}
              />
            </ActionContainer>
          </InputFieldWrapper>
          <InputFieldWrapper>
            <InputFieldTitle>Mute everyone's webcame, will also disable the webcam button meaning its not visible. </InputFieldTitle>
            <ActionContainer>
              <Switch
                checked={roomState.muteVideoEveryone}
                onChange={() => {
                  handleMuteVideoAllUsers(!roomState.muteVideoEveryone);
                }}
              />
            </ActionContainer>
          </InputFieldWrapper>
        </CardHeaderWrapper>
        <CardContent>
          <TableContainer>
            <Table
              dataSource={userList}
              columns={columns}
              rowKey={(data) => data.socketId}
            />
          </TableContainer>
        </CardContent>
      </Card>
    </>
  );
};

const TableContainer = styled.div`
  display: flex;
  width: 100%;
`;

const ActionContainer = styled.div`
  //display: flex;
  margin-left: var(--space-40);
  display: inline-flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
`;

const CardHeaderWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  hight: 100%;
  width: 100%;

  gap: 7px;
`;

const CustomButton = styled.button`
  font-family: "DMSans";

  color: #ffffff;
  font-family: "DMSans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
  padding: 7px;
  width: 270px;
  height: 50px;
  background: #4318ff;
  border: 0px solid #000000;
  border-radius: 120px;
  margin: 24px 0px;

  :hover {
    background: #2200b7;
    color: #ffffff;
    border: 0px solid #000000;
    transition: 0.3s all;
  }

  :active {
    transition: 0.3s all;
    transform: translateY(3px);
    opacity: 0.8;
  }
`;

const CustomButtonDisabled = styled.button`
  color: #4318ff;
  font-family: "DMSans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
  padding: 7px;
  width: 270px;
  height: 50px;
  background: #ffffff;
  border: 1px solid #4318ff;
  border-radius: 120px;
  margin: 10px 0px;

  &:hover {
    border: 1px solid #2200b7;
    color: #2200b7;
  }
`;

const ButtonTextOS = styled.div`
  font-family: "DMSans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
`;

const InfoContainer = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
`;

const GridBody = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 35% 65%;
`;
