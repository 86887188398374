import React from "react";
import { Typography } from "antd";
import Modal from "../../components/modal/Modal";
import { ModalTitle } from "../../components/modal/ModalTitle";
import { ModalText } from "../../components/modal/ModalText";
import { ButtonChip } from "../../components/button/ButtonChip";

export function MaxCCUReached({ isModalOpen, returnToSpaces }) {
  const { Link } = Typography;

  return (
    <div>
      {isModalOpen && (
        <Modal
          id="modal"
          isOpen={true}
          modalSize={"lg"}
          onClose={() => returnToSpaces()}
        >
          <ModalTitle>Too many online users</ModalTitle>
          <ModalText>
            Sorry, this space has reached max capacity! Visit another one or
            check out the livestream on the Ravel Linkedin page.
          </ModalText>
          <br />
          <Link
            href="https://nl.linkedin.com/company/ravel-world"
            target="_blank"
          >
            Ravel LinkedIn page
          </Link>
          <div className="box-footer">
            <div onClick={() => returnToSpaces()} className="close">
              <ButtonChip width={140} height={36}>
                Go back
              </ButtonChip>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
