import { LayoutDefaultPageOpen } from "../../components/layout/LayoutDefaultPageOpen";
import Card from "../../components/cards/Card";
import { Link } from "react-router-dom";
import { CardTitle } from "../../components/cards/CardTitle";
import { CardSubText } from "../../components/cards/CardSubText";
import { ButtonText } from "../../components/button/ButtonText";
import { CardHeaderWrapper } from "../../components/cards/CardHeaderWrapper";
import { useState } from "react";
import FormSignUp from "../../components/form/FormSignUp";

export const SignUp = () => {
  const [emailHasBeenSend, setEmailHasBeenSend] = useState(false);

  function emailSend() {
    setEmailHasBeenSend(true);
  }

  return (
    <LayoutDefaultPageOpen>
      <Card>
        {emailHasBeenSend ? (
          <CardHeaderWrapper>
            <div>
              <CardTitle>Sign up</CardTitle>
            </div>
            <div>
              <CardSubText>
                Please check your email to create and activate your account
              </CardSubText>
            </div>
          </CardHeaderWrapper>
        ) : (
          <>
            <CardHeaderWrapper>
              <div>
                <CardTitle>Sign up</CardTitle>
              </div>
              <div>
                <CardSubText>Enter your email to sign up to Ravel</CardSubText>
              </div>
            </CardHeaderWrapper>
            <FormSignUp emailSend={emailSend} />
          </>
        )}
        <Link to="/login">
          <ButtonText>Log in</ButtonText>
        </Link>
      </Card>
    </LayoutDefaultPageOpen>
  );
};
