import AgoraRTC, { IAgoraRTCClient } from "agora-rtc-sdk-ng";
import React, { useContext, useEffect, useState } from "react";
import { Button, Switch, Typography } from "antd";
import MediaPlayerVideo from "../../feature/web-rtc/components/MediaPlayerVideo";
import styled from "styled-components";
import "./Call.css";
import { AuthContext } from "../../providers/AuthContextProvider";
import { getTokenForStreaming } from "../../api/services/SpaceProServices";
import {
  successNotification,
  warningNotification,
} from "../../components/notifications/Notifications";
import { SelectMicInput } from "./SelectMicInput";
import Card from "../../components/cards/Card";
// import {
//   LayoutMinimalPageClosed,
// } from '../../components/layout/LayoutMinimalPageClosed';
// import {InputFieldWrapper} from '../../components/form/InputFieldWrapper';
// import {InputFieldTitle} from '../../components/form/InputFieldTitle';
import { ItemTextSubtile } from "../../components/item/ItemTextSubtile";
import { Paragraph } from "../../components/Paragraphs";
import { ItemText } from "../../components/item/ItemText";
import Hashids from "hashids";
import { LayoutMinimalPageClosed } from "../../components/layout/LayoutMinimalPageClosed";
import { InputFieldWrapper } from "../../components/Input/InputFieldWrapper";
import { InputFieldTitle } from "../../components/Input/InputFieldTitle";

/**
 * This page is a prototype for the streaming module.
 */
function StreamingDashboard() {
  const { Paragraph, Text, Link } = Typography;
  const [appid, setAppid] = useState("e56c1f2539d34ad0b7055d025d50f900");
  const [remoteFeeds, setRemoteFeeds] = useState([]);
  const [videoProfile, setVideoProfile] = useState("1080p_2");
  const [randomNumber] = useState(Math.floor(Math.random() * 100));
  const [userId, setUserId] = useState("");
  const [expiresAt, setExpiresAt] = useState("");
  const [sessionToken, setSessionToken] = useState("");
  const { user } = useContext(AuthContext);
  const [spaces, setSpaces] = useState(null);
  const [preFix, setPreFix] = useState("");
  const [agoraRoom, setAgoraRoom] = useState(null);
  const [microphoneAudioTrack, setMicrophoneAudioTrack] = useState(undefined);
  const [mics, setMics] = useState([]);

  const [streamIsLive, setStreamIsLive] = useState(false);
  const [sessionDetailsCreated, setSessionDetailsCreated] = useState(false);
  const [existingStream, setExistingStream] = useState(false);

  const [clientState, setClientState] = useState("disconnected");

  useEffect(() => {
    if (!existingStream) {
      let randomString = (Math.random() + 1).toString(36).substring(7);
      var hashids = new Hashids(randomString),
          id = hashids.encode(1, 2);
      setPreFix(id + "-");
    } else {
      setPreFix("");
    }
  }, [existingStream]);

  function handleOnCreateSessionDetails() {
    getTokenForStreaming(
        `${preFix}${agoraRoom}`,
        user.userUUID + "-" + randomNumber,
        "ROLE_PUBLISHER"
    )
    .then((res) => {
      setAppid(res.data.createdForAppId);
      setSessionToken(res.data.sessionToken);
      setExpiresAt(res.data.expiresAt);
      setUserId(res.data.createdForUserId);
      setSessionDetailsCreated(true);
    })
    .catch((err) => {
      console.log(err);
    });
  }

  // create Agora client
  var client = AgoraRTC.createClient({
    mode: "live",
    codec: "vp8",
    role: "host",
  });
  AgoraRTC.enableLogUpload();

  var localTracks = {
    screenVideoTrack: null,
    audioTrack: null,
    screenAudioTrack: null,
  };
  var remoteUsers = {};
  // Agora client options
  var options = {
    appid: appid,
    channel: "test",
    uid: randomNumber,
    token:
        "006b5ef7fb8a6004bbab48d15fe4e6b13fdIABnS3WaO15bfrI9zK2sFh6NeyX5Z6XVw8lDOg5T7beKtwx+f9gAAAAAEACOhaHHUnjiYgEAAQBSeOJi",
  };

  useEffect(() => {
    client.on("connection-state-change", (curState, reason) => {
      setClientState(curState);
    });
  }, [client]);

  async function handleOnJoin() {
    await join();
  }

  async function join() {
    client.on("user-published", handleUserPublished);
    client.on("user-unpublished", handleUserUnpublished);
    client.setClientRole("host");
    let screenTrack;
    [options.uid, localTracks.audioTrack, screenTrack] = await Promise.all([
      client.join(appid, `${preFix}${agoraRoom}`, sessionToken, userId),
      createLocalMicTrack({
        encoderConfig: "high_quality_stereo",
      }),

      AgoraRTC.createScreenVideoTrack(
          {
            encoderConfig: { videoProfile },
            optimizationMode: "detail",
          },
          "auto"
      ),
    ]);

    if (screenTrack instanceof Array) {
      localTracks.screenVideoTrack = screenTrack[0];
      localTracks.screenAudioTrack = screenTrack[1];
    } else {
      localTracks.screenVideoTrack = screenTrack;
    }
    localTracks.screenVideoTrack.play("local-player");

    localTracks.screenVideoTrack.on("track-ended", () => {
      warningNotification(
          `Screen-share track ended, stop sharing screen ` +
          localTracks.screenVideoTrack.getTrackId()
      );
      localTracks.screenVideoTrack && localTracks.screenVideoTrack.close();
      localTracks.screenAudioTrack && localTracks.screenAudioTrack.close();
      localTracks.audioTrack && localTracks.audioTrack.close();
    });
    if (localTracks.screenAudioTrack == null) {
      await client.publish([
        localTracks.screenVideoTrack,
        localTracks.audioTrack,
      ]);
    } else {
      await client.publish([
        localTracks.screenVideoTrack,
        localTracks.audioTrack,
        localTracks.screenAudioTrack,
      ]);
    }
    setStreamIsLive(true);
    successNotification(`You're broadcasting a stream`);
    console.log("publish success");
  }

  async function subscribe(user, mediaType) {
    const uid = user.uid;
    await client.subscribe(user, mediaType);
    if (mediaType === "video") {
      const player = `
      <div id="player-wrapper-${uid}">
        <p class="player-name">remoteUser(${uid})</p>
        <div id="player-${uid}" class="player"></div>
      </div>
    `;
      setRemoteFeeds([...remoteFeeds, player]);
      user.videoTrack.play(`player-${uid}`);
    }
    if (mediaType === "audio") {
      user.audioTrack.play();
    }
  }

  function handleUserPublished(user, mediaType) {
    const id = user.uid;
    remoteUsers[id] = user;
    subscribe(user, mediaType);
  }

  function handleUserUnpublished(user, mediaType) {
    if (mediaType === "video") {
      const id = user.uid;
      delete remoteUsers[id];
      `#player-wrapper-${id}`.remove();
    }
  }

  const handleOnChangeChannelToStream = (e) => {
    setAgoraRoom(e.target.value);
  };

  async function createLocalMicTrack(audioConfig) {
    const microphoneTrack = await AgoraRTC.createMicrophoneAudioTrack(
        audioConfig
    );
    setMicrophoneAudioTrack(microphoneTrack);
    return microphoneTrack;
  }

  async function handleGetMics() {
    // setCams(await  AgoraRTC.getCameras());
    setMics(await AgoraRTC.getMicrophones());
  }

  function changeAudioInput(deviceId) {
    if (!microphoneAudioTrack) return;
    microphoneAudioTrack
    .setDevice(deviceId)
    .then(() => {})
    .catch((e) => {
      console.log("set device error", e);
    });
  }

  const renderDetails = () => {
    return (
        <>
          <ItemTextSubtile>
            <Paragraph>Agora status: </Paragraph>
          </ItemTextSubtile>
          <ItemText>
            <Paragraph>{clientState}</Paragraph>
          </ItemText>
        </>
    );
  };

  return (
      <LayoutMinimalPageClosed>
        <div>
          <DetailsContainer>
            <Card>
              <InfoContainer>
                <InputFieldWrapper>
                  <InputFieldTitle>
                    Channel, existing stream : .
                    <Switch
                        disabled={sessionDetailsCreated}
                        size={"small"}
                        checked={existingStream}
                        onChange={(e) => setExistingStream(e)}
                    />
                  </InputFieldTitle>
                  <input
                      disabled={sessionDetailsCreated}
                      onChange={handleOnChangeChannelToStream}
                  />
                </InputFieldWrapper>
                <Button
                    disabled={streamIsLive}
                    onClick={handleOnCreateSessionDetails}
                >
                  Create session details
                </Button>
                <Button
                    disabled={!sessionDetailsCreated || streamIsLive}
                    onClick={handleOnJoin}
                >
                  Publish stream
                </Button>
                <div onClick={() => handleGetMics()}>
                  <SelectMicInput
                      mics={mics}
                      changeAudioInput={changeAudioInput}
                      live={streamIsLive}
                  />
                </div>
              </InfoContainer>
            </Card>
            <Card>
              <InfoContainer>
                <GridBody>
                  <ItemTextSubtile>
                    <Paragraph>Name: </Paragraph>
                  </ItemTextSubtile>
                  <ItemText>
                    <Paragraph>
                      {preFix}
                      {agoraRoom}
                    </Paragraph>
                  </ItemText>
                  <ItemTextSubtile>
                    <Paragraph>Existing stream: </Paragraph>
                  </ItemTextSubtile>
                  <ItemText>
                    <Paragraph>
                      {existingStream ? (
                          <Text type="warning">Yes, I know what i'm doing</Text>
                      ) : (
                          "No, this is a new stream"
                      )}
                    </Paragraph>
                  </ItemText>
                  {renderDetails()}
                </GridBody>
              </InfoContainer>
            </Card>
          </DetailsContainer>
          <ContentContainer>
            <Card>
              <div className="row video-group">
                <div className="col">
                  {streamIsLive && (
                      <>
                        <Paragraph style={{ color: "red" }}>
                          Status: LIVE - YOU'RE BROADCASTING A STREAM{" "}
                        </Paragraph>
                      </>
                  )}
                  <div id="local-player" className="player"></div>
                </div>
                <div className="w-100"></div>
                <div className="col">
                  <div id="remote-playerlist">
                    <MediaPlayerVideo videoTrack={remoteFeeds.videoTrack} />
                  </div>
                </div>
              </div>
            </Card>
          </ContentContainer>
        </div>
      </LayoutMinimalPageClosed>
  );
}

export default StreamingDashboard;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const InfoContainer = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
`;

const GridBody = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 35% 65%;
`;
