import { useCallback, useState } from "react";
import styled, { css } from "styled-components";
import { useDropzone } from "react-dropzone";
import { getToken } from "../../helpers/CookieHelper";
import { ButtonChip } from "../button/ButtonChip";
import LoaderCirclePrimary from "../loader/LoaderCircleBig";
import {
  errorNotification,
  successNotification,
  warningNotification,
} from "../notifications/Notifications";

const apiUrl = process.env.REACT_APP_SYSTEMS_BASE_API_URL;

export const CreatorsUploadAssetBundle = ({
  environmentUuid,
  setFileState,
  handleOnAsstBundleUploaded,
}) => {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [largeFiles, setLargeFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);

  const onClick = () => {
    setError(false);
    setLargeFiles([]);
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      setLargeFiles([]);
      if (acceptedFiles.length > 1) {
        errorNotification("You can only upload one file.");
      }
      if (acceptedFiles.length === 1) {
        acceptedFiles.forEach((file) => {
          if (file.size > 180000000) {
            setLargeFiles((largeFiles) => [...largeFiles, file.name]);
            warningNotification(`${file.name} is too large.`);
            setError("InputFieldError: file(s) must not be larger than 180MB");
          } else {
            setError(false);
            setLoading(true);
            const reader = new FileReader();
            reader.onabort = () =>
              setError("InputFieldError uploading file, please trying again.");
            reader.onerror = () =>
              setError("InputFieldError uploading file, please trying again.");
            reader.onload = async () => {
              let formData = new FormData();
              formData.append("file", file, file.name);
              var myHeaders = new Headers();
              myHeaders.append("accept", "application/json");
              myHeaders.append("Authorization", "Bearer " + getToken());
              await fetch(
                apiUrl +
                  `/api/v1/environments/uploads/asset-bundles?environmentUuid=${environmentUuid}`,
                {
                  method: "POST",
                  headers: myHeaders,
                  body: formData,
                  redirect: "follow",
                }
              )
                .then((data) => {
                  setLoading(false);
                  if (data.status === 201) {
                    setSuccess("Upload successful: " + file.name);
                    successNotification("File(s) uploaded successfully");
                    setFileState(true);
                    setFileUploaded(true);
                    handleOnAsstBundleUploaded();
                  }
                  if (data.status === 400) {
                    errorNotification("Cannot update asset bundle");
                    setFileState(false);
                    setError("400");
                  }
                  if (acceptedFiles.length > 1) {
                    setError("You can only upload on file at a time.");
                  }
                })
                .catch((_) => {
                  setLoading(false);
                  setFileState(false);
                  setError("Catch");
                });
            };
            reader.readAsArrayBuffer(file);
          }
        });
      }
    },
    [setFileState, environmentUuid, handleOnAsstBundleUploaded]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    disabled: fileUploaded,
  });
  return (
    <div style={{ marginTop: "32px" }} onClick={onClick}>
      <DropSection className="container">
        <DropZone {...getRootProps({ className: "dropzone" })}>
          <input
            {...getInputProps()}
            accept=".glb, .jpeg, .png, .pdf, .mov, .mp4, .glTF"
          />

          {error && <p style={{ color: "red", marginTop: "8px" }}>{error}</p>}

          {largeFiles && (
            <p style={{ color: "red" }}>{largeFiles.toString()}</p>
          )}
          {success && <p style={{ color: "#01B574" }}>{success}</p>}
          {loading && <LoaderCirclePrimary />}
          {!loading && (
            <div>
              <p>Asset-bundle</p>
              <br />
              <ModalText>
                The asset-bundle cannot be larger than 180MB
              </ModalText>
              <br />
              <ModalText>Drag and drop or </ModalText>
              <br />
              <ButtonChip> BROWSE </ButtonChip>
            </div>
          )}
        </DropZone>
      </DropSection>
    </div>
  );
};
export default CreatorsUploadAssetBundle;

const DropSection = styled.div`
  display: table-cell;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  margin: auto;
  width: 820px;
  height: 200px;
  left: 528px;
  top: 225px;
  background: #fafcfe;
  border: 1px solid #8f9bba;
  border-radius: 20px;

  .dragDrop {
    color: #4a4a68;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 8px;
    letter-spacing: 0.0617116px;
    margin-top: 24px;
  }

  .subText {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #676767ff;
  }

  .or {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 300;
    font-size: 25px;
    line-height: 8px;
    text-align: center;
    letter-spacing: 0.0617116px;
  }
`;
const DropZone = styled.div`
  display: inline-block;
  width: 100%;
`;

const ModalText = styled.p`
  ${({ theme }) => css`
    color: var(--color-secondary-primary-darkGrey-500);
    font-family: ${theme.font.family};
    font-size: var(--font-size-10);
    font-weight: ${theme.font.weight.regular400};
  `}
`;
