import React, { useCallback, useContext, useEffect, useState } from "react";
import { LayoutDefaultPageClosed } from "../../components/layout/LayoutDefaultPageClosed";
import styled from "styled-components";
import { SubMenuContainer } from "../../components/subMenu/SubMenuContainer";
import { SubMenuBar } from "../../components/subMenu/SubMenuBar";
import { SubMenuItem } from "../../components/subMenu/SubMenuItem";
import { PageDetailsProvider } from "../../providers/PageDetailsProvider";
import {
  errorNotification,
  infoNotification,
} from "../../components/notifications/Notifications";
import { AuthContext } from "../../providers/AuthContextProvider";
import {
  getOrganizationFiles,
  requestDownloadUrlOrg,
} from "../../api/services/OrganizationFilesServices";
import { ButtonIcon } from "../../components/button/ButtonIcon";
import LoaderCirclePrimary from "../../components/loader/LoaderCircleBig";
import Card from "../../components/cards/Card";

import { Switch } from "antd";
import { ModalUploadFileOrganization } from "../../components/modal/ModalUploadFileOrganization";
import { ModalDeleteFileOrg } from "../../components/modal/ModalDeleteFileOrg";
import { useOrganizationsForUsers } from "../../hooks/api/useOrganizationsForUsers";
import { useOrganizationRole } from "../../hooks/api/useOrganizationRole";
import { ORGANIZATION_ROLE } from "../../constants/organizations";
import { Table } from "../../components/Table";

export const ContentOverview = () => {
  const { user } = useContext(AuthContext);
  const {
    user: { userUUID },
  } = useContext(AuthContext);

  const [file, setFileState] = useState(false);
  // const [organizationsForUser, setOrganizationsForUser] = useState([]);

  const [fetchingOrganization, setFetchingOrganization] = useState(true);
  const [fetchingFiles, setFetchingFiles] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState([]);
  const [orgFileList, setOrgFileList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [userOrganizationMember, setUserOrganizationMember] = useState(false);

  const { data: organizationsForUser } = useOrganizationsForUsers({
    userUUID,
  });

  const { data: userRole } = useOrganizationRole({
    organization:
      selectedOrganization?.organization?.organizationName ||
      organizationsForUser
        ? organizationsForUser[0].organization?.organizationName
        : null,
    userUUID,
  });

  // const fetchOrganizations = useCallback(async () => {
  //   console.warn(user.userUUID)
  //   try {
  //
  //
  //     const response = await getOrganizationsForUser(user.userUUID);
  //
  //
  //     if (response.status === 200) {
  //       if (response.data.length > 0) {
  //         setOrganizationsForUser(response.data);
  //         setSelectedOrganization(response.data[0]);
  //         setFetchingOrganization(false);
  //         if (response.data[0].organizationRole === "ORGANIZATION_ADMIN") {
  //           setIsAdmin(true);
  //         } else if (
  //             response.data[0].organizationRole === "ORGANIZATION_OWNER"
  //         ) {
  //           setIsAdmin(true);
  //         } else {
  //           setIsAdmin(false);
  //         }
  //       } else {
  //         setOrganizationsForUser([]);
  //         setFetchingOrganization(false);
  //       }
  //     } else {
  //       setOrganizationsForUser([]);
  //       setFetchingOrganization(false);
  //     }
  //   } catch (e) {
  //     errorNotification(e.message);
  //   }
  // }, []);

  const fetchOrgFiles = useCallback(async (id) => {
    try {
      const response = await getOrganizationFiles(id);
      if (response.status === 200) {
        setOrgFileList(response.data);
      } else {
        setOrgFileList([]);
      }
      setFileState(false);
      setFetchingFiles(false);
    } catch (error) {
      errorNotification("Files could not be loaded");
    }
  }, []);

  useEffect(() => {
    if (
      userRole === ORGANIZATION_ROLE.admin ||
      userRole === ORGANIZATION_ROLE.owner
    ) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [userRole]);

  useEffect(() => {
    if (organizationsForUser) {
      const [firstOrganizationData] = organizationsForUser || [];
      setSelectedOrganization(firstOrganizationData);
      // setSelectedOptionObject(firstOrganizationData);
    } else {
      setSelectedOrganization([]);
    }
  }, [organizationsForUser]);

  function handleOnChangeSelected(value) {
    setFetchingFiles(true);
    const currentSelectionObject = organizationsForUser.find(
      (organization) => organization.organization.organizationName === value
    );
    fetchOrgFiles(currentSelectionObject.organization.organizationId);
    setSelectedOrganization(currentSelectionObject);
    if (currentSelectionObject.organizationRole === "ORGANIZATION_ADMIN") {
      setIsAdmin(true);
    } else if (
      currentSelectionObject.organizationRole === "ORGANIZATION_OWNER"
    ) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }

  const onClickGetFile = async (id) => {
    try {
      const { data } = await requestDownloadUrlOrg(id);
      const file_path = await data.preSignedUrl;
      const a = document.createElement("A");

      a.href = file_path;
      a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error(error);
      errorNotification("InputFieldError retrieving file");
    }
  };

  const onChange = (checked) => {
    infoNotification("not implemented yet");
    console.log(`switch to ${checked}`);
  };

  // useEffect(() => {
  //   fetchOrganizations();
  // }, [fetchOrganizations]);

  useEffect(() => {
    if (selectedOrganization.organization) {
      fetchOrgFiles(selectedOrganization.organization.organizationId);
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (file) {
      fetchOrgFiles(selectedOrganization.organization.organizationId);
    }
  }, [file]);

  const columns = React.useMemo(
    () => [
      {
        Header: "file name",
        accessor: "fileName",
      },
      {
        Header: "file size",
        accessor: "fileSize",
        Cell: ({ cell: { value } }) => {
          const mb = Math.floor(value / 1000000);
          return (
            <>{mb > 0.0 ? `${mb} mb${mb > 1.0 ? " " : " "} ` : "1 mb <"}</>
          );
        },
      },
      {
        Header: "public",
        accessor: "private",
        Cell: ({ cell: { value } }) => {
          return (
            <>
              <Switch checked={value} defaultChecked onChange={onChange} />
            </>
          );
        },
      },
      {
        Header: "",
        accessor: "id",
        Cell: ({ cell: { value } }) => {
          return (
            <>
              <ButtonIcon
                hasBackground
                icon="trash"
                width={32}
                height={32}
                onClick={() => {
                  setItemToDelete(value);
                  setIsModalOpen(true);
                }}
              />

              <ButtonIcon
                hasBackground
                width={32}
                height={32}
                icon="download"
                onClick={() => onClickGetFile(value)}
              />
            </>
          );
        },
      },
    ],
    []
  );
  function renderList() {
    if (selectedOrganization === null) {
      return <></>;
    } else {
      return (
        <>
          {fetchingFiles ? (
            <LoaderCirclePrimary />
          ) : !orgFileList?.length ? (
            <p>
              You can upload files by clicking on the upload button in the top
              right corner.
            </p>
          ) : (
            <>
              <Card>
                <ListWrapper>
                  <Table columns={columns} data={orgFileList} />
                </ListWrapper>
              </Card>
            </>
          )}
        </>
      );
    }
  }

  function renderUpload() {
    return (
      <>
        <div>
          {isAdmin ? (
            <SubMenuItem>
              <div>
                <ModalUploadFileOrganization
                  setFileState={setFileState}
                  organizationUuid={
                    selectedOrganization.organization.organizationId
                  }
                />
              </div>
            </SubMenuItem>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  }

  function fetchOrgListAgainAfterDelete() {
    setFileState(true);
  }

  return (
    <>
      <LayoutDefaultPageClosed>
        <PageDetailsProvider
          title={"Content organization"}
          description={"Content organization overview"}
          meta={""}
        />
        {isModalOpen && (
          <ModalDeleteFileOrg
            setIsModalOpen={setIsModalOpen}
            id={itemToDelete}
            fetchData={fetchOrgListAgainAfterDelete}
          />
        )}

        <SubMenuContainer>
          <SubMenuBar>
            {organizationsForUser?.length > 1 && (
              <SubMenuItem>
                <select
                  value={selectedOrganization?.organization?.organizationName}
                  onChange={(e) => handleOnChangeSelected(e.target.value)}
                >
                  {organizationsForUser.map((link, index) => (
                    <option
                      key={link.organization.organizationName}
                      value={link.organization.organizationName}
                    >
                      {link.organization.organizationName}
                    </option>
                  ))}{" "}
                </select>
              </SubMenuItem>
            )}

            <SubMenuItem>
              <div>
                {isAdmin ? <SubMenuItem>{renderUpload()}</SubMenuItem> : <></>}
              </div>
            </SubMenuItem>
          </SubMenuBar>
        </SubMenuContainer>
        <ContentContainer>
          <ContentWrapper>{renderList()}</ContentWrapper>
        </ContentContainer>
      </LayoutDefaultPageClosed>
    </>
  );
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ContentWrapper = styled.div``;

const ListWrapper = styled.div`
  //max-height: 100%;
  display: flex;
  justify-content: center;
  min-width: 300px;
  max-height: 500px;
  overflow-y: scroll;
  @media only screen and (max-width: 500px) {
    max-height: 300px;
  }

  .menu-icon {
    margin-left: 8px;
    height: 24px;
    display: inline-block;
  }
`;
