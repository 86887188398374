import AgoraRTC from "agora-rtc-sdk-ng";
import React, { useContext, useEffect, useState } from "react";
import { Typography } from "antd";
import styled from "styled-components";
import "./Call.css";
import { AuthContext } from "../../providers/AuthContextProvider";
import {
  getAllSpacesAsSysAdmin,
  getTokenForStreaming,
} from "../../api/services/SpaceProServices";
import { LayoutDefaultPageClosed } from "../../components/layout/LayoutDefaultPageClosed";
import { SubMenuContainer } from "../../components/subMenu/SubMenuContainer";
import { SubMenuBar } from "../../components/subMenu/SubMenuBar";
import { SubMenuItem } from "../../components/subMenu/SubMenuItem";
import { ButtonChip } from "../../components/button/ButtonChip";

/* This page is a prototype to research on the Streaming functionality within the Ravel Platform.  */
function StreamingDashboard() {
  const { Paragraph } = Typography;
  const [appid, setAppid] = useState("e56c1f2539d34ad0b7055d025d50f900");
  const [remoteFeeds, setRemoteFeeds] = useState([]);
  const [randomNumber] = useState(Math.floor(Math.random() * 100));
  const [channel, setChannel] = useState("streamTestLocal");
  const [userId, setUserId] = useState("");
  const [expiresAt, setExpiresAt] = useState("");
  const [sessionToken, setSessionToken] = useState("");
  const { user } = useContext(AuthContext);
  const [spaces, setSpaces] = useState(null);
  const [selectedSpace, setSelectedSpace] = useState(null);
  const [fetchingSpaces, setFetchingSpaces] = useState(true);

  function getSpaces() {
    getAllSpacesAsSysAdmin()
      .then((response) => {
        setSpaces(response.data);
        setSelectedSpace(response.data[0].sessionSpaceId);
      })
      .finally(() => {
        setFetchingSpaces(false);
      });
  }

  useEffect(() => {
    getSpaces();
  }, []);

  function handleOnCreateSessionDetails() {
    getTokenForStreaming(
      `livestream-${selectedSpace}`,
      user.userUUID + "-" + randomNumber,
      "ROLE_SUBSCRIBER"
    )
      .then((res) => {
        setAppid(res.data.createdForAppId);
        setSessionToken(res.data.sessionToken);
        setExpiresAt(res.data.expiresAt);
        setUserId(res.data.createdForUserId);
        setChannel(res.data.createdForChannel);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  // create Agora client
  var client = AgoraRTC.createClient({
    mode: "live",
    codec: "vp8",
    role: "audience",
  });
  AgoraRTC.enableLogUpload();

  var localTracks = {
    screenVideoTrack: null,
    audioTrack: null,
    screenAudioTrack: null,
  };
  var remoteUsers = {};
  // Agora client options
  var options = {
    appid: appid,
    channel: "test",
    uid: randomNumber,
    token:
      "006b5ef7fb8a6004bbab48d15fe4e6b13fdIABnS3WaO15bfrI9zK2sFh6NeyX5Z6XVw8lDOg5T7beKtwx+f9gAAAAAEACOhaHHUnjiYgEAAQBSeOJi",
  };

  async function handleOnJoin() {
    await join();
  }

  async function join() {
    // add event listener to play remote tracks when remote user publishs.
    client.on("user-published", handleUserPublished);
    client.on("user-unpublished", handleUserUnpublished);

    let screenTrack;
    client.setClientRole("audience")[
      (options.uid, localTracks.audioTrack, screenTrack)
    ] = await Promise.all([
      client.join(appid, `livestream-${selectedSpace}`, sessionToken, userId),
    ]);
  }

  async function subscribe(user, mediaType) {
    const uid = user.uid;
    // subscribe to a remote user
    await client.subscribe(user, mediaType);
    console.log("subscribe success");
    if (mediaType === "video") {
      const player = `
      <div id="player-wrapper-${uid}">
        <p class="player-name">remoteUser(${uid})</p>
        <div id="player-${uid}" class="player"></div>
      </div>
    `;
      setRemoteFeeds([...remoteFeeds, player]);
      // ("#remote-playerlist").append(player);
      user.videoTrack.play(`player-${uid}`);
    }
    if (mediaType === "audio") {
      user.audioTrack.play();
    }
  }

  function handleUserPublished(user, mediaType) {
    const id = user.uid;
    remoteUsers[id] = user;
    subscribe(user, mediaType);
  }

  function handleUserUnpublished(user, mediaType) {
    if (mediaType === "video") {
      const id = user.uid;
      delete remoteUsers[id];
      `#player-wrapper-${id}`.remove();
    }
  }

  const handleOnSpaceChange = (e) => {
    setSelectedSpace(e.target.value);
  };

  const renderSpaces = () => {
    if (fetchingSpaces) {
      return <div>Loading...</div>;
    }
    return (
      <>
        <label htmlFor="spaceToStream">Select space to stream to</label>
        <select
          name="spaceToStream"
          id="spaceToStream"
          onChange={handleOnSpaceChange}
        >
          {spaces.map((space) => (
            <option key={space.spaceUuid} value={space.sessionSpaceId}>
              {space.spaceName} - {space.sessionSpaceId}
            </option>
          ))}
        </select>
      </>
    );
  };

  return (
    <LayoutDefaultPageClosed>
      <SubMenuContainer>
        <SubMenuBar>
          <SubMenuItem></SubMenuItem>
        </SubMenuBar>
      </SubMenuContainer>
      <div>
        <DetailsContainer>
          <SessionDetailsContainer>
            <InfoContainer>
              {renderSpaces()}
              <ButtonChip
                onClick={handleOnCreateSessionDetails}
                width={140}
                height={40}
              >
                Create session details
              </ButtonChip>
              <ButtonChip onClick={handleOnJoin} width={140} height={40}>
                Join stream
              </ButtonChip>
            </InfoContainer>
          </SessionDetailsContainer>
          <SessionDetailsContainer>
            <InfoContainer>
              <Paragraph>
                {" "}
                for userId:{" "}
                <Paragraph code copyable={true}>
                  {userId}
                </Paragraph>
              </Paragraph>
              <Paragraph>
                using channel:{" "}
                <Paragraph code copyable={true}>
                  {channel}
                </Paragraph>
              </Paragraph>
              <Paragraph>
                using appid:{" "}
                <Paragraph code copyable={true}>
                  {appid}
                </Paragraph>
              </Paragraph>
              <Paragraph>
                using token:{" "}
                <Paragraph
                  code
                  style={{ maxWidth: "100%" }}
                  ellipsis
                  copyable={true}
                >
                  {sessionToken}
                </Paragraph>
              </Paragraph>
              <Paragraph>
                expires at:{" "}
                <Paragraph style={{ maxWidth: "100%" }} code copyable={true}>
                  {expiresAt}
                </Paragraph>
              </Paragraph>
            </InfoContainer>
          </SessionDetailsContainer>
        </DetailsContainer>
      </div>
    </LayoutDefaultPageClosed>
  );
}

export default StreamingDashboard;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

const SessionDetailsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 40%;
  height: 250px;
  flex-wrap: wrap;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: scroll;
  flex-wrap: wrap;
`;
