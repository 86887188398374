import styled, { css } from "styled-components";
import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ReactComponent as Contacts } from "../../assets/icons/contacts.svg";
import { ReactComponent as Content } from "../../assets/icons/content.svg";
import { ReactComponent as Profile } from "../../assets/icons/profile.svg";
import { ReactComponent as Settings } from "../../assets/icons/settings.svg";
import { ReactComponent as Spaces } from "../../assets/icons/spaces.svg";
import { NavigationSettingsMenu } from "./NavigationSettingsMenu";

export const NavigationBar = () => {
  const [open, setOpen] = useState(false);

  function isNavLinkActiveForSpace(pathname) {
    const regex = /^\/spaces\/(public|organization|private)/;
    return regex.test(pathname);
  }

  const currentLocation = useLocation();

  return (
      <NavigationMenuWrapper>
        <Bar>
          <NavUnlisted>
            <NavLink
                className={isNavLinkActiveForSpace(currentLocation.pathname) ? "active" : ""}
                to="/spaces/public"
            >
              <ItemWrapper>
                <Spaces />
                Spaces
                <Underline />
              </ItemWrapper>
            </NavLink>
            <NavLink
                className={(navData) => (navData.isActive ? "active" : "")}
                to="/contacts"
            >
              <ItemWrapper>
                <Contacts />
                Contacts
                <Underline />
              </ItemWrapper>
            </NavLink>
            <NavLink
                className={(navData) => (navData.isActive ? "active" : "")}
                to="/content"
            >
              <ItemWrapper>
                <Content />
                Content
                <Underline />
              </ItemWrapper>
            </NavLink>
            <NavLink
                className={(navData) => (navData.isActive ? "active" : "")}
                to="/profile"
            >
              <ItemWrapper>
                <Profile />
                Profile
                <Underline />
              </ItemWrapper>
            </NavLink>
            <ItemWrapper onClick={() => setOpen(!open)}>
              <Settings />
              Settings
              <Underline />
            </ItemWrapper>
            <NavigationSettingsMenu setOpen={setOpen} open={open} />
          </NavUnlisted>
        </Bar>
      </NavigationMenuWrapper>
  );
};

const NavigationMenuWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  bottom: 20px;
  width: 100%;
  position: fixed;
`;

const NavUnlisted = styled.ul`
  margin-bottom: 0px;
  margin-block-start: 0px;
  display: flex;
  padding-inline-start: 0px;
  justify-content: center;
  align-items: center;

  margin-left: 20px;
  margin-right: 20px;
  flex-direction: row;

  a {
    text-decoration: none;
  }

  .active {
    li {
      color: var(--color-secondary-primary-black);
    }
    div:last-of-type {
      width: 100%;
      background-color: var(--color-secondary-primary-black);
    }
  }

  @media only screen and (max-width: 800px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const ItemWrapper = styled.li`
  ${({ theme }) => css`
    margin: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    list-style: none;
    color: var(--color-secondary-primary-darkGrey-500);
    font-family: ${theme.font.family};
    font-size: var(--font-size-20);
    font-weight: ${theme.font.weight.medium500};
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.38px;
    cursor: pointer;
    transition: color 0.5s;
    @media only screen and (max-width: 800px) {
      font-size: var(--font-size-10);
    }
  `}

  &:hover {
    color: var(--color-secondary-primary-black);

    div:last-of-type {
      background-color: var(--color-primary-purpleBlue-500);
      width: 100%;
    }
  }

  @media only screen and (max-width: 500px) {
    margin: 10px;
  }
`;

const Underline = styled.div`
  height: 3px;
  background-color: transparent;
  width: 0%;
  transition: width 0.2s, background-color 0.5s;
  margin: 0 auto;
`;

const Bar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  background: rgba(255, 255, 255, 0.95);
  /* Shadows/Sharp Shadow/Sharp Shadow - Style 9 */
  height: 100px;
  box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.25);
  border-radius: 103.5px;
`;
