import styled from "styled-components";

const ButtonWrapper = styled.button`
  width: 56px;
  height: 56px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  pointer-events: all;
  font: ${({ theme }) => theme.font.family};
  color: ${({ theme }) => theme.colour.secondary.light};
  background: rgba(224, 229, 242, 0.72);
  border: 0px solid;
  box-shadow: 0 16px 32px 0 rgb(0 0 0 / 24%);
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 10px;
  border-radius: 70px;
  left: calc(50% - 84px / 2);
  top: calc(50% - 34px / 2 - 27px);
  transition: 0.5s all; // var(--emo-out);
  transform: scale(1);
  z-index: 1000;

  &:hover {
    background: rgba(244, 247, 254, 0.72);
    transform: scale(1.2);
    transition: 0.5s all; //var(--emo-out);
    box-shadow: 0 16px 48px 0 rgb(0 0 0 / 24%);
  }

  &:focus {
  }

  &:disabled {
    background: rgba(120, 124, 134, 0.49);
  }
`;

export const ButtonRound = ({ children, className, onClick, disabled }) => {
  return (
    <ButtonWrapper className={className} onClick={onClick} disabled={disabled}>
      {children}
    </ButtonWrapper>
  );
};
