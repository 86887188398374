import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../providers/AuthContextProvider";
import { LayoutDefaultPageClosed } from "../../components/layout/LayoutDefaultPageClosed";
import { PageDetailsProvider } from "../../providers/PageDetailsProvider";
import { useOrganizationsForUsers } from "../../hooks/api/useOrganizationsForUsers";
import { useOrganizationRole } from "../../hooks/api/useOrganizationRole";
import { ContactsList } from "../../components/contacts/ContactsList";
import { ORGANIZATION_ROLE } from "../../constants/organizations";

export const Contacts = () => {
  const {
    user: { userUUID },
  } = useContext(AuthContext);
  const [selectedOrganization, setSelectedOrganization] = useState(null);

  const { data: organizationsForUser } = useOrganizationsForUsers({
    userUUID,
    organization: selectedOrganization,
  });

  const [firstOrganizationData] = organizationsForUser || [];
  const organizationName =
    firstOrganizationData?.organization?.organizationName;

  const { data: userRole } = useOrganizationRole({
    userUUID,
    organization: selectedOrganization || organizationName,
  });

  useEffect(() => {
    if (!selectedOrganization) {
      setSelectedOrganization(organizationName);
    }
  }, [organizationsForUser, organizationName, selectedOrganization]);

  return (
    <LayoutDefaultPageClosed>
      <PageDetailsProvider title="Contacts" description="Contacts overview" />
      <ContactsList
        organizationsForUser={organizationsForUser}
        selectedOrganization={selectedOrganization}
        isAdmin={
          userRole === ORGANIZATION_ROLE.admin ||
          userRole === ORGANIZATION_ROLE.owner
            ? true
            : false
        }
        onChange={setSelectedOrganization}
      />
    </LayoutDefaultPageClosed>
  );
};
