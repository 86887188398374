import React, { useContext, useState } from "react";
import { AuthContext } from "../../providers/AuthContextProvider";
import Modal from "./Modal";
import { ModalBody } from "./ModalBody";
import { ModalTitle } from "./ModalTitle";
import { ModalText } from "./ModalText";
import { ButtonChip } from "../button/ButtonChip";
import { InputFieldWrapper } from "../Input/InputFieldWrapper";
import { InputFieldTitle } from "../Input/InputFieldTitle";
import { ButtonTextDefault } from "../button/ButtonTextDefault";
import {
  errorNotification,
  successNotification,
} from "../notifications/Notifications";
import { assignEnvironmentToOrganization } from "../../api/services/EnvironmentServices";
import styled from "styled-components";
import LoaderCircleSmall from "../loader/LoaderCircleSmall";
import { useOrganizationsForUsers } from "../../hooks/api/useOrganizationsForUsers";

function ModalManageAssigendOrganizations({
  environmentUuid,
  fetchEnvironmentDetails,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  function closeModal() {
    setIsModalOpen(false);
    fetchEnvironmentDetails();
  }
  const { user: userUUID } = useContext(AuthContext);

  const [selectedOption, setSelectedOption] = useState("");
  const [loading, setLoading] = useState(false);

  const { data: organizationsForUser } = useOrganizationsForUsers({
    userUUID,
  });

  async function assignEnvironmentToOrg(organization) {
    setLoading(true);
    try {
      await assignEnvironmentToOrganization({ environmentUuid, organization });
      successNotification("Environment has been assigned to organization");
      fetchEnvironmentDetails();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorNotification(error.message);
    }
  }

  function handleOnAssign(value) {
    assignEnvironmentToOrg(value);
  }

  return (
    <div>
      <div onClick={() => setIsModalOpen(true)}>
        <ButtonTextDefault>Manage</ButtonTextDefault>
      </div>
      {isModalOpen && (
        <Modal
          id="modal"
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        >
          <ModalTitle>Manage organizations</ModalTitle>
          <ModalText>
            Select an organization to assign to environment to or remove the
            environment from an organization. After adding the organization an
            use the environment to create new Spaces
          </ModalText>
          <ModalBody>
            <Container>
              <InputFieldWrapper>
                <InputFieldTitle>Select organization</InputFieldTitle>
                <select
                  value={selectedOption.organizationName}
                  onChange={(e) => setSelectedOption(e.target.value)}
                >
                  {organizationsForUser.map((link, index) => (
                    <option
                      key={link.organization.organizationName}
                      value={link.organization.organizationId}
                    >
                      {link.organization.organizationName}
                    </option>
                  ))}{" "}
                </select>
              </InputFieldWrapper>
              <button
                onClick={() => handleOnAssign(selectedOption)}
                type="submit"
              >
                {loading ? <LoaderCircleSmall /> : "Assign"}{" "}
              </button>
            </Container>
          </ModalBody>
          <div className="box-footer">
            <div onClick={() => closeModal()} className="close">
              <ButtonChip hasBackgroundColor>Done</ButtonChip>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default ModalManageAssigendOrganizations;

const Container = styled.div`
  width: auto;
  height: auto;
  display: flex;
  align-content: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
