import client from "../Client";

const apiUrl = process.env.REACT_APP_SYSTEMS_BASE_API_URL;

export const getUserFiles = async () =>
  client
    .get(`${apiUrl}/api/v1/userfiles`)
    .then(({ data }) => data)
    .catch((error) => {
      console.error(error);
      throw error;
    });

export const deleteUserFiles = async ({ id }) =>
  client
    .delete(`${apiUrl}/api/v2/userfiles/${id}`)
    .then((data) => data)
    .catch((error) => {
      console.error(error);
      throw error;
    });

export const requestDownloadUrl = async (id) =>
  client
    .get(`${apiUrl}/api/v2/userfiles/request/${id}`)
    .then((data) => data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
