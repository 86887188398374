import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import Cookies from "js-cookie";
import {
  errorNotification,
  successNotification,
} from "../notifications/Notifications";

const apiUrl = process.env.REACT_APP_SYSTEMS_BASE_API_URL;
const token = Cookies.get("token");

export function FileUploadOrg({ fetchData, selectedOrg }) {
  const props = {
    name: "file",
    action: `${apiUrl}/api/v1/organizationfiles?organizationUuid=${selectedOrg}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        successNotification(`${info.file.name} file uploaded successfully`);
        fetchData();
      } else if (info.file.status === "error") {
        errorNotification(`${info.file.name} file upload failed.`);
      }
    },
    progress: {
      strokeColor: {
        "0%": "#e5e5e5",
        "100%": "#009ee7",
      },
      strokeWidth: 2,
      format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
    },
  };

  return (
    <>
      <Upload {...props}>
        <Button icon={<UploadOutlined />}>Click to Upload</Button>
      </Upload>
    </>
  );
}
