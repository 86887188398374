import { useQuery } from "react-query";
import { REACT_QUERY_CACHE_KEYS } from "./reactQueryCacheKeys";
import { getSpaceProWithUuid } from "../../api/services/SpaceProServices";

export function useSpaceProWithUuid({ spaceUuid }) {
  const { data, isLoading } = useQuery(
    [REACT_QUERY_CACHE_KEYS.SPACES_PRO, spaceUuid],
    () => getSpaceProWithUuid({ spaceUuid })
  );

  return { data, isLoading };
}
