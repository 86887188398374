import Card from "../../components/cards/Card";
import { LayoutDefaultPageOpen } from "../../components/layout/LayoutDefaultPageOpen";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ButtonText } from "../../components/button/ButtonText";
import FormLogIn from "../../components/form/FormLogIn";
import { CardTitle } from "../../components/cards/CardTitle";
import { CardSubText } from "../../components/cards/CardSubText";
import { ReactComponent as AppleLogo } from "../../assets/icons/apple.svg";
import { ReactComponent as GoogleLogo } from "../../assets/icons/google.svg";
import { ReactComponent as MicrosoftLogo } from "../../assets/icons/microsoft.svg";
import { ReactComponent as MetaMaskLogo } from "../../assets/icons/metamask.svg";
import { useEffect, useState } from "react";

export const Login = () => {
  const [passwordLogin, setPasswordLogin] = useState(true);
  const [loginOptions, setLoginOptions] = useState(false);

  function handlePasswordLogin() {
    setLoginOptions(!loginOptions);
    setPasswordLogin(!passwordLogin);
  }
  
  return (
    <LayoutDefaultPageOpen>
      {loginOptions && (
        <Card>
          <CardHeaderWrapper>
            <div>
              <CardTitle>Login</CardTitle>
            </div>
          </CardHeaderWrapper>

          <div>
            <div onClick={() => handlePasswordLogin()}>
              <CustomButton>Email</CustomButton>
            </div>
            <Link to="/signup">
              <ButtonText>Sign up</ButtonText>
            </Link>
            {/* <CardSubText>Coming soon</CardSubText>

            <div className="metaMaskItem">
              <CustomButtonDisabled>
                <ButtonContainer>
                  <MetaMaskLogo />
                  <ButtonTextOS>MetaMask</ButtonTextOS>
                </ButtonContainer>
              </CustomButtonDisabled>
            </div>

            <div className="googleItem">
              <CustomButtonDisabled>
                <ButtonContainer>
                  <GoogleLogo />
                  <ButtonTextOS>Google</ButtonTextOS>{" "}
                </ButtonContainer>
              </CustomButtonDisabled>
            </div>
            <div className="appleItem">
              <CustomButtonDisabled>
                <ButtonContainer>
                  <AppleLogo /> <ButtonTextOS>Apple</ButtonTextOS>
                </ButtonContainer>
              </CustomButtonDisabled>
            </div>
            <div className="microsoftItem">
              <CustomButtonDisabled>
                <ButtonContainer>
                  <MicrosoftLogo />
                  <ButtonTextOS>Microsoft</ButtonTextOS>{" "}
                </ButtonContainer>
              </CustomButtonDisabled>
            </div> */}
          </div>
        </Card>
      )}

      {passwordLogin && (
        <Card>
          <CardHeaderWrapper>
            <CardTitle>Login</CardTitle>
            <CardSubText>
              Enter your credentials to log in to your account
            </CardSubText>
          </CardHeaderWrapper>
          <FormLogIn />
          <Link to={"/forgot-password"}>
            <ButtonText>Forgot password?</ButtonText>
          </Link>
          <Link to={"/signup"}>
            <ButtonText>Sign up</ButtonText>
          </Link>
        </Card>
      )}
    </LayoutDefaultPageOpen>
  );
};

const CardHeaderWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  hight: 100%;
  width: 100%;

  gap: 7px;
`;

const CustomButton = styled.button`
  font-family: "DMSans";

  color: #ffffff;
  font-family: "DMSans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
  padding: 7px;
  width: 270px;
  height: 50px;
  background: #4318ff;
  border: 0px solid #000000;
  border-radius: 120px;
  margin: 24px 0px;

  :hover {
    background: #2200b7;
    color: #ffffff;
    border: 0px solid #000000;
    transition: 0.3s all;
  }

  :active {
    transition: 0.3s all;
    transform: translateY(3px);
    opacity: 0.8;
  }
`;

const CustomButtonDisabled = styled.button`
  color: #4318ff;
  font-family: "DMSans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
  padding: 7px;
  width: 270px;
  height: 50px;
  background: #ffffff;
  border: 1px solid #4318ff;
  border-radius: 120px;
  margin: 10px 0px;

  &:hover {
    border: 1px solid #2200b7;
    color: #2200b7;
  }
`;

const ButtonTextOS = styled.div`
  font-family: "DMSans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
`;
