import { useQuery } from "react-query";
import { REACT_QUERY_CACHE_KEYS } from "./reactQueryCacheKeys";
import { getOrganizationContacts } from "../../api/services/OrganizationUserServices";

export function useOrganizationContacts({ organizationName }) {
  const { data, isLoading, isSuccess } = useQuery(
    [REACT_QUERY_CACHE_KEYS.ORGANIZATION_USERS, organizationName],
    () => getOrganizationContacts(organizationName)
  );

  return { data, isLoading, isSuccess };
}
