import { LayoutDefaultPageClosed } from "../../components/layout/LayoutDefaultPageClosed";
import { SpacesSection } from "../../components/spaces/SpacesSection";
import { PageDetailsProvider } from "../../providers/PageDetailsProvider";
import {
  SpacesNavigationMenu
} from '../../components/spaces/SpacesNavigationMenu';

export const SpacesGroup = () => {
  return (
    <LayoutDefaultPageClosed>
      <PageDetailsProvider title="Space Group" description="Spaces overview" />
      {/*<SpacesNavigationMenu showPrivate={false} showOrganization={false} showPublic={true} userOrganizationMember={true} isOrganizationAdmin={false} />*/}
      {/*<SpacesSection />*/}
    </LayoutDefaultPageClosed>
  );
};
