import React from "react";
import Modal from "./Modal";
import { ButtonChip } from "../button/ButtonChip";
import { ModalTitle } from "./ModalTitle";
import { ModalText } from "./ModalText";
import { deleteUserFiles } from "../../api/services/UserFilesServices";
import { errorNotification } from "../notifications/Notifications";

export const ModalDeleteFile = ({ id, setIsModalOpen, setFileState }) => {
  const deleteFile = async (id) => {
    try {
      await deleteUserFiles({ id });
      setFileState(true);
    } catch (error) {
      console.error(error);
      errorNotification("Error deleting file");
    }
  };

  return (
    <Modal id="modal" isOpen onClose={() => setIsModalOpen(false)}>
      <ModalTitle>Delete file</ModalTitle>
      <ModalText>Are you sure to delete the file?</ModalText>

      <ButtonChip
        hasBackgroundColor
        margin="16px 0px"
        onClick={() => {
          deleteFile(id);
          setIsModalOpen(false);
        }}
      >
        Delete file
      </ButtonChip>
    </Modal>
  );
};
