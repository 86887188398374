import React, {useContext} from 'react';
import {QueryClient, QueryClientProvider} from 'react-query';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import {ResetPassword} from './pages/reset-password';
import {SignUp} from './pages/signup';
import {Profile} from './pages/profile';
import {Content} from './pages/content';
import {
  AuthContext,
  AuthContextProvider,
} from './providers/AuthContextProvider';
import {ForgotPassword} from './pages/forgot-password';
import {Spaces} from './pages/spaces';
import {Login} from './pages/login';
import CreateAccount from './pages/signup/CreateAccount';
import WebGLViewer from './pages/spaces/WebGLViewer';
import {Creators} from './pages/creators';
import CreatorsEnvironmentDetails
  from './pages/creators/CreatorsEnvironmentDetails';
import {CreatorsNewEnvironment} from './pages/creators/CreatorsNewEnvironment';
import {SpaceDetails} from './pages/spaces/SpacesDetails';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import AgoraRTC from 'agora-rtc-sdk-ng';
import {CreatorsSignUp} from './pages/creators/CreatorsSignUp';
import {CreateAccountForCreator} from './pages/signup/CreateAccountForCreator';
import {Contacts} from './pages/contacts';
import WebGLPreview from './pages/creators/WebGLViewer';
import {Integrations} from './pages/integrations';
import StreamingDashboard from './pages/streaming/StreamingDashboard';
import StreamingDashboardAudience
  from './pages/streaming/StreamingDashboardAudience';
import {SocketAdminDashboard} from './pages/host-tools/SocketAdminDashboard';
import {EventSpaces} from './pages/events';
import {EventBlendedRealities} from './pages/events/EventBlendedRealities';
import {MINUTE_IN_MS} from './constants/time';
import {ContentOverview} from './pages/contentOrganization';
import {SpacesPublic} from './pages/spacesPublic';
import {SpacesOrganization} from './pages/spacesOrganization';
import {SpacesGroup} from './pages/spacesGroup';
import {SpacesPrivate} from './pages/spacesPrivate';

const agoraLogLevel = process.env.REACT_APP_SYSTEMS_AGORA_LOG_LEVEL;

function PrivateRoute({children}) {
  const {isAuth} = useContext(AuthContext);
  const location = useLocation();
  return isAuth ? (
      children
  ) : (
      <Navigate to="/login" replace state={{from: location}}/>
  );
}

function App() {
  AgoraRTC.setLogLevel(agoraLogLevel);
  Sentry.init({
    dsn: 'https://d6a9ad5d61e04ca29ece0aa345e3f8a1@o1132931.ingest.sentry.io/6386458  ',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        ),
      }),
    ],
    tracesSampleRate: 1.0,
  });

  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        //data remains stale for 5 mins: https://react-query-v3.tanstack.com/guides/caching
        staleTime: 5 * MINUTE_IN_MS,
        retry: 1,
      },
    },
  });

  return (
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AuthContextProvider>
            <SentryRoutes>
              <Route
                  path="/reset-password/:resetPasswordCode"
                  element={<ResetPassword/>}
              />
              <Route
                  path="/spaces/:spaceId"
                  element={
                    <PrivateRoute>
                      <WebGLViewer/>
                    </PrivateRoute>
                  }
              />
              <Route path="/login" element={<Login/>}/>
              <Route path="/blendrealities" element={<EventBlendedRealities/>}/>

              <Route
                  path="/socket/admin/:room"
                  element={<SocketAdminDashboard/>}
              />
              <Route path="/signup" element={<SignUp/>}/>
              <Route path="/creators/signup" element={<CreatorsSignUp/>}/>
              <Route path="/create-account/:token" element={<CreateAccount/>}/>
              <Route
                  path="/creators/create-account/:token"
                  element={<CreateAccountForCreator/>}
              />

              <Route path="/forgot-password" element={<ForgotPassword/>}/>
              <Route
                  path="/profile"
                  element={
                    <PrivateRoute>
                      <Profile/>
                    </PrivateRoute>
                  }
              />
              <Route
                  path="/spaces"
                  element={
                    <PrivateRoute>
                      <Spaces/>
                    </PrivateRoute>
                  }
              />
              <Route
                  path="/spaces/public"
                  element={
                    <PrivateRoute>
                      <SpacesPublic/>
                    </PrivateRoute>
                  }
              />
              <Route
                  path="/spaces/organization/:organizationId"
                  element={
                    <PrivateRoute>
                      <SpacesOrganization/>
                    </PrivateRoute>
                  }
              />
              <Route
                  path="/spaces/group/:groupId"
                  element={
                    <PrivateRoute>
                      <SpacesGroup/>
                    </PrivateRoute>
                  }
              />
              <Route
                  path="/spaces/private"
                  element={
                    <PrivateRoute>
                      <SpacesPrivate/>
                    </PrivateRoute>
                  }
              />
              <Route
                  path="/streaming"
                  element={
                    <PrivateRoute>
                      <StreamingDashboard/>
                    </PrivateRoute>
                  }
              />
              <Route
                  path="/streaming/audience"
                  element={
                    <PrivateRoute>
                      <StreamingDashboardAudience/>
                    </PrivateRoute>
                  }
              />
              <Route
                  path="/spaces/details/:spaceUuid"
                  element={
                    <PrivateRoute>
                      <SpaceDetails/>
                    </PrivateRoute>
                  }
              />
              <Route
                  path="/events/:eventId"
                  element={
                    <PrivateRoute>
                      <EventSpaces/>
                    </PrivateRoute>
                  }
              />
              <Route
                  path="/organization/content/"
                  element={
                    <PrivateRoute>
                      <ContentOverview/>
                    </PrivateRoute>
                  }
              />
              <Route
                  path="/content"
                  element={
                    <PrivateRoute>
                      <Content/>
                    </PrivateRoute>
                  }
              />
              <Route
                  path="/contacts"
                  element={
                    <PrivateRoute>
                      <Contacts/>
                    </PrivateRoute>
                  }
              />
              <Route
                  path="/creators"
                  element={
                    <PrivateRoute>
                      <Creators/>
                    </PrivateRoute>
                  }
              />
              <Route
                  path="/creators/new-environment"
                  element={
                    <PrivateRoute>
                      <CreatorsNewEnvironment/>
                    </PrivateRoute>
                  }
              />
              <Route
                  path="/creators/environments/:environmentUuid"
                  element={
                    <PrivateRoute>
                      <CreatorsEnvironmentDetails/>
                    </PrivateRoute>
                  }
              />
              <Route
                  path="/creators/environments/preview/:environmentUuid"
                  element={
                    <PrivateRoute>
                      <WebGLPreview/>
                    </PrivateRoute>
                  }
              />
              <Route
                  path="/"
                  element={
                    <PrivateRoute>
                      <Profile/>
                    </PrivateRoute>
                  }
              />
              <Route
                  path="/integrations"
                  element={
                    <PrivateRoute>
                      <Integrations/>
                    </PrivateRoute>
                  }
              />
            </SentryRoutes>
          </AuthContextProvider>
        </BrowserRouter>
      </QueryClientProvider>
  );
}

export default App;
