import {
  LayoutDefaultPageClosed,
} from '../../components/layout/LayoutDefaultPageClosed';
import { PageDetailsProvider } from '../../providers/PageDetailsProvider';
import {
  SpacesNavigationMenu,
} from '../../components/spaces/SpacesNavigationMenu';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../providers/AuthContextProvider';
import {
  useOrganizationsForUsers,
} from '../../hooks/api/useOrganizationsForUsers';
import { SpacesContainerV2 } from '../../components/spaces/SpacesContainerV2';
import { SpacesCards } from '../../components/spaces/SpacesCards';
import { useSpacesPro } from '../../hooks/api/useSpacesPro';
import LoaderCircleBig from '../../components/loader/LoaderCircleBig';
import { useParams } from 'react-router-dom';

export const SpacesPublic = () => {
  const {
    user: { userUUID },
  } = useContext(AuthContext);

  const [userOrganizationMember, setUserOrganizationMember] = useState(false);
  const { data: organizationsForUser } = useOrganizationsForUsers({
    userUUID,
  });
  const spaceType = 'PUBLIC';


  const { data: spaces, isLoading: isLoadingSpacesPro } =
    useSpacesPro({
      spaceType: spaceType,
    });


  useEffect(() => {
    if (organizationsForUser) {
      setUserOrganizationMember(true);
    } else {
      setUserOrganizationMember(false);
    }
  }, [organizationsForUser]);


  return (
    <LayoutDefaultPageClosed>
      <PageDetailsProvider title="Public Spaces"
        description="Public Spaces overview" />
      <SpacesNavigationMenu showOrganization={false}
        showPublic={true}
        userOrganizationMember={userOrganizationMember}
        organizationsForUser={organizationsForUser} />
      {isLoadingSpacesPro ? (
        <LoaderCircleBig />
      ) :
        (
          <>
            <SpacesContainerV2>
              <SpacesCards data={spaces} isLoading={isLoadingSpacesPro} />
            </SpacesContainerV2>
          </>
        )
      }
    </LayoutDefaultPageClosed>
  );
};
