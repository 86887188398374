import Cookies from "js-cookie";
import isTokenValid from "./IsTokenValid";

export const getUser = () => {
  const user = Cookies.get("user");
  if (user) {
    return JSON.parse(user);
  }
  return null;
};

export const removeUserCookies = () => {
  Cookies.remove("token");
  Cookies.remove("user");
};

export const isTokenSet = () => !!Cookies.get("token");

export const getToken = () => Cookies.get("token") || null;

export const getTokenV2 = () => {
  const token = Cookies.get("token");
  if (token && isTokenValid(token)) {
    console.log("token is valid");
    return token;
  } else {
    console.log("token not valid");
    removeUserCookies();
    localStorage.clear();
    return null;
  }
};
