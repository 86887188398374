import { PageDetailsProvider } from "../../providers/PageDetailsProvider";
import styled from "styled-components";

export const EventBlendedRealities = () => {
  return (
    <>
      <Logo />
      <PageDetailsProvider
        title={"Blendrealities"}
        description={"Event overview"}
        meta={""}
      />
      <IFrameContainer>
        <iframe
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          width="100%"
          height="100%"
          scrolling="auto"
          src="https://dqrjvyeh3gx3p.cloudfront.net/index.html"
          // sandbox=''
        />
      </IFrameContainer>
    </>
  );
};

const IFrameContainer = styled.div`
  width: 100vw;
  height: 100vh;

  //disable scrolling
  overflow: hidden;
`;

const Logo = styled.div`
  left: 110px;
  top: 25px;
  position: absolute;
  width: 94px;
  height: 20px;
  background: url("/ravel-logo-text-green.png");
  background-size: cover;
`;
