import { useForm } from "react-hook-form";
import { useContext, useState } from "react";
import { AuthContext } from "../../providers/AuthContextProvider";
import { errorNotification } from "../notifications/Notifications";
import styles from "../../styles/DefaultForm.modules.css";
import { InputFieldWrapper } from "../Input/InputFieldWrapper";
import { InputFieldTitle } from "../Input/InputFieldTitle";
import { InputFieldError } from "../Input/InputFieldError";
import LoaderCircleSmall from "../loader/LoaderCircleSmall";
import { authenticateUser } from "../../api/services/AuthenticationServices";
import * as Sentry from "@sentry/react";

function FormLogIn() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);

  const { login } = useContext(AuthContext);

  async function onSubmit({ email, password }) {
    setLoading(true);
    try {
      const data = await authenticateUser({
        email,
        password,
      });
      login(data.accessToken, data);
      setLoading(false);
    } catch (error) {
      Sentry.captureException(error);
      errorNotification(error.message);
      setLoading(false);
    }
  }

  return (
    <>
      <div className={styles.formContainer}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputFieldWrapper>
            <InputFieldTitle>Email</InputFieldTitle>
            <input {...register("email", { required: true })} type="email" />
            {errors.email && (
              <InputFieldError>Emails is required</InputFieldError>
            )}
          </InputFieldWrapper>
          <InputFieldWrapper>
            <InputFieldTitle>Password</InputFieldTitle>
            <input
              {...register("password", { required: true })}
              type="password"
            />
            {errors.password && (
              <InputFieldError>Password is required</InputFieldError>
            )}
          </InputFieldWrapper>
          <button type="submit">
            {loading ? <LoaderCircleSmall /> : "Log in"}
          </button>
        </form>
      </div>
    </>
  );
}

export default FormLogIn;
