import { useForm } from "react-hook-form";
import { useState } from "react";
import styles from "../../styles/DefaultForm.modules.css";
import { InputFieldWrapper } from "../Input/InputFieldWrapper";
import { InputFieldTitle } from "../Input/InputFieldTitle";
import { InputFieldError } from "../Input/InputFieldError";
import LoaderCircleSmall from "../loader/LoaderCircleSmall";
import { requestResetPassword } from "../../api/services/AccountServices";
import { errorNotification } from "../notifications/Notifications";
import * as Sentry from "@sentry/react";

export const FormForgotPassword = ({ emailSend }) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);

  const onSubmit = async ({ email }) => {
    setLoading(true);
    try {
      await requestResetPassword({ email });
      setLoading(false);
      emailSend();
    } catch (error) {
      Sentry.setUser({ email });
      Sentry.captureException("Forgot password error");
      errorNotification(error.message);
      setLoading(false);
      reset();
    }
  };

  return (
    <div className={styles.formContainer}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputFieldWrapper>
          <InputFieldTitle>Email</InputFieldTitle>
          <input {...register("email", { required: true })} type="email" />
          {errors?.email && (
            <InputFieldError>Email is required</InputFieldError>
          )}
        </InputFieldWrapper>
        <button type="submit">
          {loading ? <LoaderCircleSmall /> : "Submit"}
        </button>
      </form>
    </div>
  );
};
