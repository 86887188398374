import styled, { css } from "styled-components";

const ButtonWrapper = styled.button`
  ${({ theme }) => css`
    pointer-events: all;

    font-family: var(--font-family-a);
    font-style: normal;
    font-weight: var(--font-weight-30);
    color: ${theme.colour.primary.purpleBlue500};
    background: ${theme.colour.secondary.light};
    border: 0px solid ${theme.colour.secondary.grey500};

    display: inline-flex;
    justify-content: start;

    &:hover {
      color: ${theme.colour.primary.purpleBlue700};
    }
  `}
`;

export const ButtonTextDefault = (props) => (
  <ButtonWrapper className={props.className}>
    <span className="title">{props.children}</span>
  </ButtonWrapper>
);
