import styled from "styled-components";
import { Tab } from "../../styles/Tab";

const Wrapper = styled.button`
  ${Tab}
  width: 95px;
  height: 46px;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  border-radius: 0px 10px 10px 0px;
`;

const TabRight = (props) => (
  <Wrapper className={props.className}>
    <span className="title">{props.children}</span>
  </Wrapper>
);

export default TabRight;
