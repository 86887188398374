import client from '../Client';
import { errorNotification } from "../../components/notifications/Notifications";

const apiUrl = process.env.REACT_APP_SYSTEMS_BASE_API_URL;

export const getEnvironmentsForCreatingSpace = async ({
  environmentType,
  organizationId,
}) =>
  client
    .get(
      `${apiUrl}/api/v1/spaces/env/?environmentAssignee=${environmentType}&uuid=${organizationId}`
    )
    .then(({ data }) => data)
    .catch((error) => {
      console.error(error);
      throw error;
    });

export const getCreatorDetails = async (userUuid) => {
  try {
    return await client.get(
        apiUrl + `/api/v1/environments/creators/${userUuid}`
    );
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const getEnvironmentsForCreator = async (userUuid, isPublished) => {
  try {
    return await client.get(
        apiUrl + `/api/v1/environments/${userUuid}?isPublished=${isPublished}`
    );
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const assignEnvironmentToOrganization = async ({
  environmentUuid,
  organizationUuid,
}) =>
  client
    .post(
      apiUrl +
        `/api/v1/spaces/env/${environmentUuid}/organizations/${organizationUuid}`
    )
    .catch((error) => {
      console.error(error);
      throw error;
    });

export const assignEnvironmentToUser = async (environmentUuid, userEmail) => {
  try {
    const response = await client.post(
      apiUrl + `/api/v1/spaces/env/${environmentUuid}/users`,
      {
        userEmail: userEmail,
      }
    );
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const getPublishedEnvironmentsForCreator = async (environmentUuid) => {
  try {
    const response = await client.get(
      apiUrl + `/api/v1/spaces/env/${environmentUuid}/creators`
    );
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const getEnvironmentWithUuid = async (environmentUuid) => {
  try {
    const response = await client.get(
      apiUrl + `/api/v1/environments/single/${environmentUuid}`
    );
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const publishEnvironmentForUse = async (environmentUuid) => {
  try {
    const response = await client.put(
      apiUrl + `/api/v1/environments/publish/${environmentUuid}`
    );
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const submitEnvironmentForReview = async (environmentUuid) => {
  try {
    const response = await client.put(
      apiUrl + `/api/v1/environments/submissions/${environmentUuid}`
    );
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const deleteNonPublishedEnvironment = async (environmentUuid) => {
  try {
    const response = await client.delete(
      apiUrl + `/api/v1/environments/${environmentUuid}`
    );
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const createNewActiveCreator = async (
  creatorUuid,
  creatorName,
  creatorBio,
  creatorEmail,
) =>
  client
    .post( `${apiUrl}/api/v1/environments/creators/`, {
       creatorUuid,
       creatorName,
       creatorBio,
       creatorEmail,
    })
    .catch((error) => {
      throw error;
    });

export const submitNewEnvironment = async ({
  userUUID,
  name,
  shortSummary,
  longSummary,
  isPublic,
}) =>
  client
    .post(apiUrl + `/api/v1/environments/${userUUID}`, {
      name,
      shortSummary,
      longSummary,
      public: isPublic,
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
