import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  errorNotification,
  successNotification,
} from "../notifications/Notifications";
import styles from "../../styles/DefaultForm.modules.css";
import { InputFieldWrapper } from "../Input/InputFieldWrapper";
import { InputFieldTitle } from "../Input/InputFieldTitle";
import { InputFieldError } from "../Input/InputFieldError";
import LoaderCircleSmall from "../loader/LoaderCircleSmall";
import { signUp } from "../../api/services/AccountServices";
import * as Sentry from "@sentry/react";

export const FormSignUpForCreator = ({ emailSend }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);

  async function onSubmit({ email }) {
    setLoading(true);
    try {
      await signUp({ email, signUpType: "CREATOR" });
      setLoading(false);
      successNotification("Check your email to confirm your account");
      emailSend();
    } catch (error) {
      Sentry.setUser({ email });
      Sentry.captureException(error);
      errorNotification(error.message);
      setLoading(false);
    }
  }

  return (
    <div className={styles.formContainer}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputFieldWrapper>
          <InputFieldTitle>Email</InputFieldTitle>
          <input {...register("email", { required: true })} type="email" />
          {errors?.email && (
            <InputFieldError>Email is required</InputFieldError>
          )}
        </InputFieldWrapper>
        <button type="submit">
          {loading ? <LoaderCircleSmall /> : "Submit"}
        </button>
      </form>
    </div>
  );
};
