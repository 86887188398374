import { LayoutDefaultPageClosed } from "../../components/layout/LayoutDefaultPageClosed";
import { SpacesSection } from "../../components/spaces/SpacesSection";
import { PageDetailsProvider } from "../../providers/PageDetailsProvider";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

export const Spaces = () => {
    const navigate = useNavigate();
    useEffect(() => {
    navigate("/spaces/public")
    }, []);

  return (
    <LayoutDefaultPageClosed>
      <PageDetailsProvider title="Spaces" description="Spaces overview" />
    </LayoutDefaultPageClosed>
  );
};
