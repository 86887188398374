import React, { useContext, useEffect, useState } from "react";
import { ModalTitle } from "./ModalTitle";
import { ModalText } from "./ModalText";
import ModalAvatar from "./ModalAvatar";
import { ButtonChip } from "../button/ButtonChip";
import { successNotification } from "../notifications/Notifications";
import { updateAvatarFullBody } from "../../api/services/AccountServices";
import { AuthContext } from "../../providers/AuthContextProvider";
import { AvatarIframe } from "../iframe/IframeAvatarStyle";

export const ModalChangeAvatarFullBody = ({ setUserUpdate }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    user: { userUUID },
  } = useContext(AuthContext);

  useEffect(() => {
    async function subscribe(event) {
      if (event.origin === "https://ravel-fullbody.readyplayer.me") {
        try {
          if (userUUID) {
            await updateAvatarFullBody({ userUUID, avatarUrl: event.data });
            successNotification("Avatar updated successfully");
            setIsModalOpen(false);
            setUserUpdate(true);
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
    window.addEventListener("message", subscribe);
    document.addEventListener("message", subscribe);
    return () => {
      window.addEventListener("message", subscribe);
      document.addEventListener("message", subscribe);
    };
  }, [setUserUpdate, userUUID]);

  return (
    <div>
      <div  onClick={() => setIsModalOpen(true)}>
        <ButtonChip>Change avatar</ButtonChip>
      </div>
      {isModalOpen && (
        <ModalAvatar
          id="modal"
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          modalSize={"lg"}
        >
          <ModalTitle>Configure your avatar</ModalTitle>
          <ModalText>
            Change the looks of your avatar by following the instructions in the
            screen below
          </ModalText>
          <div className="box-body">
            <AvatarIframe
              id="frame"
              className="frame"
              allow="camera *; microphone *"
              src="https://ravel-fullbody.readyplayer.me/avatar"
            />
          </div>
        </ModalAvatar>
      )}
    </div>
  );
};
