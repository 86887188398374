import { OnlineUserList } from "./OnlineUserList";
import React, { useCallback, useContext, useEffect, useState } from "react";
import socketio from "socket.io-client";
import { AuthContext } from "../../providers/AuthContextProvider";
import { LayoutDefaultPageClosed } from "../../components/layout/LayoutDefaultPageClosed";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { ChatComponent } from "../../feature/webSocket/ChatComponent";
import { getSpacePro } from "../../api/services/SpaceProServices";
import { errorNotification } from "../../components/notifications/Notifications";
import { getToken } from "../../helpers/CookieHelper";

export const SocketAdminDashboard = () => {
  const navigate = useNavigate();
  const token = getToken();
  const apiBaseUrl = process.env.REACT_APP_SYSTEMS_BASE_API_URL + "/";

  const [socket, setSocket] = useState(null);
  const { user } = useContext(AuthContext);
  const { room } = useParams();
  const [allowedPermissions, setAllowedPermissions] = useState([]);
  const [spacePro, setSpacePro] = useState({});
  const [allowedToAccess, setAllowedToAccess] = useState(false);


  useEffect(() => {
    if (allowedToAccess) {
      const newSocket = socketio.connect(`https://io.ravel.systems`
        // `https://io.ravel.systems`
        // `https://ruben.ravel.systems.eu.ngrok.io`
        ,{
          auth: {
            token: token,
          },
          query: {
            room: room,
            env: apiBaseUrl,
          }
        }
      );
      setSocket(newSocket);
      return () => {
        newSocket.close();
      };
    }
  }, [setSocket, allowedToAccess]);

  useEffect(() => {
    fetchSpacePro();
  }, []);

  useEffect(() => {
    if (allowedPermissions.includes("HOSTTOOLS")) {
      setAllowedToAccess(true);
    }
  }, [allowedPermissions]);

  const fetchSpacePro = useCallback(async () => {
    try {
      const response = await getSpacePro(room);
      if (response.data) {
        setSpacePro(response.data);
        setupPermissionsForUser(response.data);
      } else {
        errorNotification("Error loading Space");
        // navigate(-1);
      }
    } catch (error) {
      errorNotification("Error loading Space");
      // navigate(-1);
    }
  }, []);

  function setupPermissionsForUser(sessionDetails) {
    if (sessionDetails) {
      var permissionSet = [];
      sessionDetails.userSpaceRoles.forEach((userSpaceRole) => {
        userSpaceRole.appPermissions.forEach((appPermission) => {
          //check if permission is already in array
          if (!allowedPermissions.includes(appPermission.permissionName)) {
            permissionSet.push(appPermission);
          }
        });
      });
      setAllowedPermissions(permissionSet);
    }
  }

  return (
    <>
      <LayoutDefaultPageClosed>
        {socket && allowedToAccess ? (
          <>
            <SectionWrapper>
              <OnlineUserList
                socket={socket}
                spacePro={spacePro}
                allowedPermissions={allowedPermissions}
              />
            </SectionWrapper>
            <SectionWrapperChat>
              <ChatComponent
                socket={socket}
                user={user}
                showChatCard={true}
                room={room}
              />
            </SectionWrapperChat>
          </>
        ) : (
          <>not connected...</>
        )}
      </LayoutDefaultPageClosed>
    </>
  );
};

const SectionWrapper = styled.div`
  display: flex;
  //max-width: 600px;
  //min-width: 600px;
`;

const SectionWrapperChat = styled.div`
  display: flex;
  max-width: 200px;
  min-width: 200px;
  position: absolute;
  bottom: 90px;
  right: 150px;
`;
