import React, { useCallback, useEffect, useState } from "react";
import { SubMenuBar } from "../subMenu/SubMenuBar";
import { errorNotification } from "../notifications/Notifications";
import { getSpacesWithParams } from "../../api/services/SpaceProServices";
import { SubMenuContainer } from "../subMenu/SubMenuContainer";
import { SubMenuItem } from "../subMenu/SubMenuItem";
import { useParams } from "react-router-dom";
import { getEventDetails } from "../../api/services/EventServices";
import { PageDetailsProvider } from "../../providers/PageDetailsProvider";
import {SpacesContainer} from './SpacesContainer';

export function SpacesEventSection() {
  const [fetching, setFetching] = useState(true);
  const { eventId } = useParams();
  const [spacesPublic, setSpacesPublic] = useState([]);
  const [eventDetails, setEventDetails] = useState({});

  const fetchData = useCallback(async () => {
    try {
      const response = await getSpacesWithParams("EVENT", "", eventId);
      if (response.data) {
        console.log(response.data)
        setSpacesPublic(response.data);
        setFetching(false);
      } else {
        setSpacesPublic([]);
        setFetching(false);
      }
    } catch (error) {
      setFetching(false);
      errorNotification("Spaces could not be loaded");
    }
  }, []);

  const fetchEventDetails = useCallback(async () => {
    try {
      const response = await getEventDetails(eventId);
      if (response.data) {
        setEventDetails(response.data);
        console.log(response.data);
      } else {
        setEventDetails([]);
      }
    } catch (error) {
      errorNotification("Event could not be loaded");
    }
  }, []);

  useEffect(() => {
    fetchEventDetails().then(() => {
      fetchData();
    });
  }, []);


  return (
    <>
      <PageDetailsProvider title={eventDetails.eventName} />
      <>
        <>
          <SubMenuContainer>
            <SubMenuBar>
              <SubMenuItem></SubMenuItem>
            </SubMenuBar>
          </SubMenuContainer>
          <SpacesContainer
            showPublic={false}
            isLoading={fetching}
            data={spacesPublic}
          />
        </>
      </>
    </>
  );
}

