import { useCallback, useContext, useEffect, useState } from "react";
import {
  getSessionDetails,
  getSpacePro,
} from "../../api/services/SpaceProServices";
import {
  errorNotification,
  warningNotification,
} from "../../components/notifications/Notifications";
import { useNavigate, useParams } from "react-router-dom";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import { getWebGLBuildConfig } from "../../api/services/ConfigServices";
import WebGL from "../../feature/web-gl/WebGL";
import { NoAccessToSpace } from "./NoAccessToSpace";
import styled from "styled-components";
import { PageDetailsProvider } from "../../providers/PageDetailsProvider";
import ModalMobileOnly from "../../components/modal/ModalMobileOnly";
import { AuthContext } from "../../providers/AuthContextProvider";
import socketio from "socket.io-client";
import { MaxCCUReached } from "./MaxCCUReached";
import { validateGateAccess } from "../../api/services/GateServices";
import { getToken } from "../../helpers/CookieHelper";

function WebGLViewer() {
  const [title, setTitle] = useState("");
  const envId = process.env.REACT_APP_SYSTEMS_ENV_ID;
  const apiBaseUrl = process.env.REACT_APP_SYSTEMS_BASE_API_URL + "/";
  const photonMode = process.env.REACT_APP_SYSTEMS_PHOTON_MODE;
  const { spaceId } = useParams();
  const [spacePro, setSpacePo] = useState({});
  const [room, setRoom] = useState({});
  const [gateDetails, setGateDetails] = useState({});
  const { user } = useContext(AuthContext);
  const [apiDone, setApiDon] = useState(false);
  const [gateIsOpen, setGateIsOpen] = useState(true);
  const [maxAmountOfCCUReached, setMaxAmountOfCCUReached] = useState(false); //TODO: set default to false
  const navigate = useNavigate();
  const [buildUrl, setBuildUrl] = useState("");
  const [streamingAssetsUrl, setStreamingAssetsUrl] = useState("");
  const [socket, setSocket] = useState(null);
  const [allowedPermissions, setAllowedPermissions] = useState([]);

  const fetchSpacePro = useCallback(async () => {
    try {
      const response = await getSpacePro(spaceId);
      if (response.status === 403) {
        warningNotification("You do not have access to this space");
        navigate(-1);
      }
      if (response.data) {
        setSpacePo(response.data);
        setTitle(response.data.spaceName);
        setupPermissionsForUser(response.data);
        await validateTokenGatedAccess(response.data.spaceUuid);
        validateMaxCCU(response.data);
      } else {
        errorNotification("Error loading Space");
        navigate(-1);
      }
    } catch (error) {
      errorNotification("Error loading Space");
      navigate(-1);
    }
  }, []);

  const fetchSessionDetails = useCallback(async () => {
    try {
      const response = await getSessionDetails(user.userUUID, spaceId);
      if (response.status === 403) {
        warningNotification("You do not have access to this space");
        navigate(-1);
      }
      if (response.data) {
        setRoom(response.data);
      } else {
        errorNotification("Error loading Space");
        navigate(-1);
      }
    } catch (error) {
      errorNotification("Error loading Space");
      navigate(-1);
    }
  }, []);

  function setupPermissionsForUser(sessionDetails) {
    if (sessionDetails) {
      sessionDetails.userSpaceRoles.forEach((userSpaceRole) => {
        userSpaceRole.appPermissions.forEach((appPermission) => {
          //check if permission is already in array
          if (!allowedPermissions.includes(appPermission.permissionName)) {
            allowedPermissions.push(appPermission);
          }
        });
      });
    }
  }

  function createStreamingAsseturl(apiResponse) {
    setBuildUrl(apiResponse.data.build.buildBaseUrl);
    const url = apiResponse.data.build.buildBaseUrl.substring(
      0,
      apiResponse.data.build.buildBaseUrl.lastIndexOf("/")
    );
    const url2 = apiResponse.data.build.buildBaseUrl.substring(
      0,
      url.lastIndexOf("/")
    );
    setStreamingAssetsUrl(url2);
  }

  const fetchWebGLConfig = useCallback(async () => {
    try {
      if (window.location.href.includes("demo")) {
        await getWebGLBuildConfig("25d4bc0c-a91b-4a42-968e-5eab95036864").then(
          (response) => {
            if (response.data) {
              createStreamingAsseturl(response);
            }
          }
        );
      } else {
        await getWebGLBuildConfig(envId).then((response) => {
          if (response.data) {
            createStreamingAsseturl(response);
          }
        });
      }
    } catch (error) {
      errorNotification("Error loading space");
      navigate(-1);
    }
  }, []);

  function returnToPreviousPage() {
    {
      navigate(-1);
    }
  }

  async function validateTokenGatedAccess(spaceUuid) {
    await validateGateAccess(spaceUuid).then((gateResponse) => {
      if (gateResponse.status === 404) {
        setGateIsOpen(false);
        setGateDetails(gateResponse.data);
      }
      if (gateResponse.status === 200) {
        if (gateResponse.data) {
          if (!gateResponse.data.hasAccess) {
            setGateIsOpen(false);
            setGateDetails(gateResponse.data);
          }
        }
      }
    });
  }

  function validateMaxCCU(spacePro) {
    if (spacePro) {
      if (spacePro.maxOnlineUsers === 0) {
        return;
      }
      if (spacePro.maxOnlineUsers === null) {
        return;
      }
      if (spacePro.roomOnlineUserCount >= spacePro.maxOnlineUsers) {
        if (allowedPermissions.includes("OVERRIDE_MAX_CCU")) {
          setMaxAmountOfCCUReached(false);
        } else {
          setMaxAmountOfCCUReached(true);
        }
      }
    }
  }

  useEffect(() => {
    fetchSpacePro().then(() =>
      fetchSessionDetails()
        .then(() => fetchWebGLConfig())
        .finally(() => setApiDon(true))
    );
    return () => {};
  }, []);

  useEffect(() => {
    const newSocket = socketio.connect("https://io.ravel.systems", {
      auth: {
        token: getToken(),
      },
      query: {
        room: spaceId,
        env: apiBaseUrl,
      },
    });
    setSocket(newSocket);
    return () => {
      newSocket.close();
    };
  }, [setSocket]);

  return (
    <>
      <PageDetailsProvider
        title={title}
        description={spacePro.description}
        meta={"hellow"}
      />
        <>
            {!gateIsOpen && (
              <LoadingOverlay img={spacePro.environmentPro.imageUrl}>
                <NoAccessToSpace
                  gateDetails={gateDetails}
                  isModalOpen={gateIsOpen}
                  returnToSpaces={returnToPreviousPage}
                />
              </LoadingOverlay>
            )}
            {maxAmountOfCCUReached && (
              <LoadingOverlay img={spacePro.environmentPro.imageUrl}>
                <MaxCCUReached
                  isModalOpen={maxAmountOfCCUReached}
                  returnToSpaces={returnToPreviousPage}
                />
              </LoadingOverlay>
            )}

            {apiDone && gateIsOpen && !maxAmountOfCCUReached && (
              <>
                {socket ? (
                  <>
                    
                    <WebGL
                      spacePro={spacePro}
                      room={room}
                      buildUrl={buildUrl}
                      streamingAssetsUrl={streamingAssetsUrl}
                      agoraEnabled={true}
                      apiBaseUrl={apiBaseUrl}
                      photonMode={photonMode}
                      socketio={socket}
                    />
                  </>
                ) : (
                  <>NOT CONNECTED</>
                )}
              </>
            )}
        </>
    </>
  );
}

export default WebGLViewer;

const LoadingOverlay = styled.div`
  background: linear-gradient(
      111.43deg,
      rgba(122, 118, 118, 0.3),
      rgba(0, 0, 0, 0.3)
    ),
    url(${(props) => props.img});
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;
