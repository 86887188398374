import { LayoutDefaultPageClosed } from "../../components/layout/LayoutDefaultPageClosed";
import { SpacesSection } from "../../components/spaces/SpacesSection";
import { PageDetailsProvider } from "../../providers/PageDetailsProvider";
import {
  SpacesNavigationMenu
} from '../../components/spaces/SpacesNavigationMenu';
import {useContext, useEffect, useState} from 'react';
import {AuthContext} from '../../providers/AuthContextProvider';
import {
  useOrganizationsForUsers
} from '../../hooks/api/useOrganizationsForUsers';
import {useSpacesPro} from '../../hooks/api/useSpacesPro';
import LoaderCircleBig from '../../components/loader/LoaderCircleBig';
import {SpacesContainerV2} from '../../components/spaces/SpacesContainerV2';
import {SpacesCards} from '../../components/spaces/SpacesCards';
import {useOrganizationRole} from '../../hooks/api/useOrganizationRole';
import {ORGANIZATION_ROLE} from '../../constants/organizations';
import {useNavigate, useParams} from 'react-router-dom';
import {errorNotification} from '../../components/notifications/Notifications';


export const SpacesOrganization = () => {
  const {organizationId} = useParams();
  const navigate = useNavigate();
  const spaceType = 'ORGANIZATION';


  const {user: {userUUID},} = useContext(AuthContext);
  const [selectedOptionObject, setSelectedOptionObject] = useState([]);
  const [userOrganizationMember, setUserOrganizationMember] = useState(true);
  const {data: organizationsForUser} = useOrganizationsForUsers({userUUID,});
  const {data: spaces, isLoading: isLoadingSpacesPro } =
      useSpacesPro({
        organizationId: organizationId,
        spaceType: spaceType,
      });

  useEffect(() => {
    console.log(organizationId)
    console.log(userOrganizationMember)
  }, [organizationId])

  useEffect(() => {

    if (organizationsForUser && organizationsForUser.length > 0) {
      const [firstOrganizationData] = organizationsForUser;
      if (organizationsForUser.filter(organization => organization.organization.organizationId === organizationId).length > 0) {
        setSelectedOptionObject(organizationsForUser.find(organization => organization.organization.organizationId === organizationId));
        setUserOrganizationMember(true);
      } else {
        errorNotification('You are not part of this organization')
        // navigate('/spaces/organization/' + firstOrganizationData.organization.organizationId)
      }
    } else {
      //TODO if not part of organization, of link, show not found
      // setUserOrganizationMember(false);
      // setSelectedOptionObject([]);
      // setSelectedOption("");
    }
  }, [organizationsForUser]);

  return (
      <LayoutDefaultPageClosed>
        <PageDetailsProvider title="Public Spaces"
                             description="Public Spaces overview"/>
        <SpacesNavigationMenu showOrganization={true}
                              showPublic={false}
                              userOrganizationMember={userOrganizationMember}
                              organizationsForUser={organizationsForUser}
                              selectedObject={selectedOptionObject}
                              spaceType={spaceType}
        />
        {isLoadingSpacesPro ? (
                <LoaderCircleBig />
            ) :
            (
                <>
                  <SpacesContainerV2>
                    <SpacesCards data={spaces} isLoading={isLoadingSpacesPro} />
                  </SpacesContainerV2>
                </>
            )
        }
      </LayoutDefaultPageClosed>
  );
};
