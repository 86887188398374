import { useForm } from "react-hook-form";
import { useState } from "react";
import {
  errorNotification,
  successNotification,
} from "../notifications/Notifications";
import LoaderCircleSmall from "../loader/LoaderCircleSmall";
import { signUp } from "../../api/services/AccountServices";
import * as Sentry from "@sentry/react";
import { InputFieldWrapper } from "../Input/InputFieldWrapper";
import { InputFieldTitle } from "../Input/InputFieldTitle";
import { InputFieldError } from "../Input/InputFieldError";
import styles from "../../styles/DefaultForm.modules.css";

function FormSignUp({ emailSend }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);

  async function onSubmit(data) {
    setLoading(true);
    try {
      const response = await signUp(data.email, "GENERIC");
      if (response.status === 200) {
        setLoading(false);
        successNotification("Check your email to confirm your account");
        emailSend();
      }
      if (response.status === 404) {
        setLoading(false);
        errorNotification("Email is already in use");
        Sentry.setUser({ email: data.email });
        Sentry.captureException("Account already exists");
      }
    } catch (e) {
      Sentry.setUser({ email: data.email });
      Sentry.captureException(e);
      errorNotification(e);
      setLoading(false);
    }
  }

  return (
    <>
      <div className={styles.formContainer}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputFieldWrapper>
            <InputFieldTitle>Email</InputFieldTitle>
            <input {...register("email", { required: true })} type="email" />
            {errors.email && (
              <InputFieldError>Email is required</InputFieldError>
            )}
          </InputFieldWrapper>
          <button type="submit">
            {loading ? <LoaderCircleSmall /> : "Submit"}{" "}
          </button>
        </form>
      </div>
    </>
  );
}

export default FormSignUp;
