export const REACT_QUERY_CACHE_KEYS = {
  ORGANIZATION_USERS: "organizationUsers",
  SPACES_ADD: "spacesAdd",
  SPACES_PRO: "spacesPro",
  SPACES_PRO_PUBLIC: "spacesProPublic",
  ORGANIZATION_FOR_USERS: "orgranizationsForUsers",
  ORGANIZATION_ROLE: "organizationRole",
  USER_FILES: "userFiles",
  SELF: "self",
};
