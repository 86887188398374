import { LayoutDefaultPageClosed } from "../../components/layout/LayoutDefaultPageClosed";
import { PageDetailsProvider } from "../../providers/PageDetailsProvider";
import { SpacesEventSection } from "../../components/spaces/SpacesEventSection";
import styled from "styled-components";

export const EventSpaces = () => {
  const customEvent = () => {
    if (
      window.location.pathname ===
      "/events/a0c344b7-275a-4e24-9638-5fc10715bf6d"
    ) {
      return <LoadingOverlay img="/ade-bg-event.png" />;
    }
    if (
      window.location.pathname ===
      "/events/6da102ca-ea86-47c9-9b90-785951d262e2"
    ) {
      return <LoadingOverlay img="/unique-eventcover.jpg" />;
    }
  };

  return (
    <LayoutDefaultPageClosed>
      {customEvent()}
      <PageDetailsProvider
        title={"Event"}
        description={"Event overview"}
        meta={""}
      />
      <SpacesEventSection />
    </LayoutDefaultPageClosed>
  );
};

const LoadingOverlay = styled.div`
  background: linear-gradient(
      111.43deg,
      rgba(122, 118, 118, 0.3),
      rgba(0, 0, 0, 0.3)
    ),
    url(${(props) => props.img});
  position: absolute;
  z-index: -1;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;
