import BadgeSmallGreen from "../badge/BadgeSmallGreen";
import BadgeSmallLightBlue from "../badge/BadgeSmallLightBlue";
import BadgeSmallLightGreen from "../badge/BadgeSmallLightGreen";
import BadgeSmallRed from "../badge/BadgeSmallRed";
import BadgeSmallGrey from "../badge/BadgeSmallGrey";
import React from "react";

function EnvironmentStatusTag({ environment }) {
  return (
    <>
      {environment.published ? (
        <>
          <>
            <BadgeSmallGreen>Published</BadgeSmallGreen>
          </>
        </>
      ) : (
        <>
          {environment.submissionInProgress ? (
            <>
              <>
                <BadgeSmallLightBlue>In review</BadgeSmallLightBlue>
              </>
            </>
          ) : (
            <>
              {environment.qaReviewed ? (
                <>
                  {environment.qaApproved ? (
                    <>
                      <BadgeSmallLightGreen>Approved</BadgeSmallLightGreen>
                    </>
                  ) : (
                    <>
                      <BadgeSmallRed>Declined</BadgeSmallRed>
                    </>
                  )}
                </>
              ) : (
                <>
                  <BadgeSmallGrey>Draft</BadgeSmallGrey>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default EnvironmentStatusTag;
