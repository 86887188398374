import { LayoutDefaultPageClosed } from "../../components/layout/LayoutDefaultPageClosed";
import { SubMenuContainer } from "../../components/subMenu/SubMenuContainer";
import { SubMenuBar } from "../../components/subMenu/SubMenuBar";
import { SubMenuItem } from "../../components/subMenu/SubMenuItem";

import styled from "styled-components";
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  getCreatorDetails,
  getEnvironmentWithUuid,
  getPublishedEnvironmentsForCreator,
  publishEnvironmentForUse,
  submitEnvironmentForReview,
} from "../../api/services/EnvironmentServices";
import {
  errorNotification,
  successNotification,
} from "../../components/notifications/Notifications";
import { AuthContext } from "../../providers/AuthContextProvider";
import { Heading2 } from "../../components/Headings";
import { Paragraph } from "../../components/Paragraphs";
import { ItemHeader } from "../../components/item/ItemHeader";
import { CardContent } from "../../components/cards/CardContent";
import { ItemTitle } from "../../components/item/ItemTitle";
import { ItemContainer } from "../../components/item/ItemContainer";
import { ItemTextSubtile } from "../../components/item/ItemTextSubtile";
import { ItemText } from "../../components/item/ItemText";
import { ButtonChip } from "../../components/button/ButtonChip";
import { CreatorsEnvironmentActions } from "../../components/creators/CreatorsEnvironmentActions";
import EnvironmentStatusTag from "../../components/environment/EnvironmentStatusTag";
import EnvironmentSubmissionActions from "../../components/environment/EnvironmentSubmissionActions";
import OffSetDateTimeToLocalDateTime from "../../utils/OffSetDateTimeToLocalDateTime";
import ModalManageAssigendOrganizations from "../../components/modal/ModalManageAssigendOrganizations";
import ModalManageAssigendUsers from "../../components/modal/ModalManageAssigendUsers";
import BadgeSmallGreyNoWidth from "../../components/badge/BadgeSmallGreyNoWidth";

function CreatorsEnvironmentDetails() {
  const { environmentUuid } = useParams();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [fetching, setFetching] = useState(true);
  const [environment, setEnvironment] = useState({});
  const [assignedOrganizations, setAssignedOrganizations] = useState([]);
  const [assignedUsers, setAssignedUsers] = useState([]);

  const fetchCreatorDetails = useCallback(async () => {
    try {
      const response = await getCreatorDetails(user.userUUID);
      if (response.status === 200) {
        setFetching(false);
      }
      if (response.status === 404) {
        navigate("/spaces");
      } else {
      }
    } catch (e) {
      setFetching(false);
      errorNotification(e.message);
    }
  }, []);

  const fetchEnvironmentDetails = useCallback(async () => {
    try {
      const response = await getEnvironmentWithUuid(environmentUuid);
      if (response.status === 200) {
        setEnvironment(response.data);
        //if response.data.isPublished is true, then the environment is published and fetch api
        if (response.data.published) {
          await fetchPublishedEnvironmentDetails();
          setFetching(false);
        }
        setFetching(false);
      }
      if (response.status === 404) {
        errorNotification("Environment not found");
        setFetching(false);
      } else {
      }
    } catch (e) {
      setFetching(false);
      errorNotification(e.message);
    }
  }, []);

  async function fetchPublishedEnvironmentDetails() {
    const response = await getPublishedEnvironmentsForCreator(environmentUuid);
    if (response.status === 200) {
      setAssignedUsers(response.data.userList);
      setAssignedOrganizations(response.data.organizations);
    }
  }

  useEffect(() => {
    fetchCreatorDetails().then(() =>
      fetchEnvironmentDetails().then(() => {
        setFetching(false);
      })
    );
  }, [fetchEnvironmentDetails]);

  async function handleOnReviewSubmit(environmentUuid) {
    const response = await submitEnvironmentForReview(environmentUuid);
    if (response.status === 202) {
      successNotification("Submission for Review was successful");
    }
    // fetchEnvironments();
    if (response.status === 404) {
      errorNotification(response.data.message);
    } else {
    }
  }

  async function handleOnPublish(environmentUuid) {
    const response = await publishEnvironmentForUse(environmentUuid);
    if (response.status === 202) {
      successNotification("Environment has been published");
    }
    if (response.status === 404) {
      errorNotification(response.data.message);
    } else {
    }
  }

  function handleOnPreview() {
    navigate(`/creators/environments/preview/${environmentUuid}`);
  }

  function renderCard() {
    if (fetching) {
      return <div>Loading...</div>;
    }

    return (
      <>
        <GridItemContainer>
          <CardWrapper>
            <EnvImageWrapper>
              <GridItemContent>
                <GridItemLink>
                  <GridItemImageMask
                    src={
                      environment.metadataPreviewImage
                        ? environment.metadataPreviewImage.url1024
                        : "/1024x576.png"
                    }
                  ></GridItemImageMask>
                  <GridItemTextContainer></GridItemTextContainer>
                </GridItemLink>
              </GridItemContent>
            </EnvImageWrapper>
            <CardContent>
              <ItemContainer>
                <ItemHeader>
                  <GridHeader>
                    <ItemTitle>
                      <Heading2>Info</Heading2>
                    </ItemTitle>
                    <div></div>
                    <div onClick={() => handleOnPreview()}>
                      <ButtonChip hasBackgroundColor width={128} height={40}>
                        Preview
                      </ButtonChip>
                    </div>
                  </GridHeader>
                </ItemHeader>
                <div>
                  <GridBody>
                    <ItemTextSubtile>
                      <Paragraph>Name : </Paragraph>
                    </ItemTextSubtile>
                    <ItemText>
                      <Paragraph>{environment.name}</Paragraph>
                    </ItemText>
                  </GridBody>
                  <GridBody>
                    <ItemTextSubtile>
                      <Paragraph>Short summary : </Paragraph>
                    </ItemTextSubtile>
                    <ItemText>
                      <Paragraph>{environment.shortSummary}</Paragraph>
                    </ItemText>
                  </GridBody>
                  <GridBody>
                    <ItemTextSubtile>
                      <Paragraph>Long summary : </Paragraph>
                    </ItemTextSubtile>
                    <ItemText>
                      <Paragraph>{environment.longSummary}</Paragraph>
                    </ItemText>
                  </GridBody>
                  <GridBody>
                    <ItemTextSubtile>
                      <Paragraph>Created at : </Paragraph>
                    </ItemTextSubtile>
                    <ItemText>
                      <Paragraph>
                        {OffSetDateTimeToLocalDateTime(environment.created_at)}
                      </Paragraph>
                    </ItemText>
                  </GridBody>
                  {environment.published && (
                    <>
                      {!environment.public && (
                        <>
                          <GridBody>
                            <ItemTextSubtile>
                              <Paragraph>Assigned users : </Paragraph>
                            </ItemTextSubtile>
                            <ItemText>
                              <ModalManageAssigendUsers
                                environmentUuid={environmentUuid}
                                fetchEnvironmentDetails={
                                  fetchEnvironmentDetails
                                }
                              />

                              <Paragraph>
                                {assignedUsers.map((user, index) => (
                                  <BadgeSmallGreyNoWidth key={user.userUUID}>
                                    {user.length - 1 === index
                                      ? user.email
                                      : user.email}
                                  </BadgeSmallGreyNoWidth>
                                ))}
                              </Paragraph>
                            </ItemText>
                          </GridBody>
                          <GridBody>
                            <ItemTextSubtile>
                              <Paragraph>Assigned organizations : </Paragraph>
                            </ItemTextSubtile>
                            <ItemText>
                              <ModalManageAssigendOrganizations
                                environmentUuid={environmentUuid}
                                fetchEnvironmentDetails={
                                  fetchEnvironmentDetails
                                }
                              />
                              <Paragraph>
                                {assignedOrganizations.map(
                                  (organization, index) => (
                                    <BadgeSmallGreyNoWidth
                                      key={organization.organizationId}
                                    >
                                      {organization.length - 1 === index
                                        ? organization.organizationName
                                        : organization.organizationName}
                                    </BadgeSmallGreyNoWidth>
                                  )
                                )}
                              </Paragraph>
                            </ItemText>
                          </GridBody>
                        </>
                      )}
                    </>
                  )}
                </div>
              </ItemContainer>
              <ItemContainer>
                <ItemHeader>
                  <GridHeader>
                    <ItemTitle>
                      <Heading2>Status</Heading2>
                    </ItemTitle>
                  </GridHeader>
                </ItemHeader>
                <div>
                  <GridBody>
                    <ItemTextSubtile>
                      <Paragraph>Current status : </Paragraph>
                    </ItemTextSubtile>
                    <ItemText>
                      <Paragraph></Paragraph>
                      <EnvironmentStatusTag environment={environment} />
                    </ItemText>
                  </GridBody>
                </div>
              </ItemContainer>
              <ItemContainer>
                <ItemHeader>
                  <GridHeader>
                    <ItemTitle>
                      <Heading2>Actions</Heading2>
                    </ItemTitle>
                  </GridHeader>
                </ItemHeader>
                <div>
                  <ActionsWrapper>
                    <CreatorsEnvironmentActions environment={environment} />
                  </ActionsWrapper>
                </div>
              </ItemContainer>
            </CardContent>
          </CardWrapper>
        </GridItemContainer>
      </>
    );
  }

  return (
    <>
      <LayoutDefaultPageClosed>
        <SubMenuContainer>
          <SubMenuBar>
            <SubMenuItem>
              <Link to={"/creators"}>
                <ButtonChip hasBorder={false}>Go back</ButtonChip>
              </Link>
            </SubMenuItem>
            <SubMenuItem></SubMenuItem>
            <SubMenuItem></SubMenuItem>
          </SubMenuBar>
        </SubMenuContainer>
        <EnvironmentContainer>
          <EnvironmentOverview>{renderCard()}</EnvironmentOverview>
        </EnvironmentContainer>
      </LayoutDefaultPageClosed>
    </>
  );
}

export default CreatorsEnvironmentDetails;

const EnvironmentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const GridHeader = styled.div`
  display: grid;
  grid-template-columns: 20% 60% 20%;
`;

const GridBody = styled.div`
  display: grid;
  grid-template-columns: 35% 65%;
`;
const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

const EnvironmentOverview = styled.div``;

const GridItemContainer = styled.div`
  position: relative;
  width: 600px;
`;

const GridItemContent = styled.div`
  height: 300px;
  width: 100%;
  position: relative;
`;

const GridItemLink = styled.div`
  width: 100%;
  height: 100%;
`;

const GridItemImageMask = styled.img`
  height: 100%;
  width: 100%;
  bottom: 0;
  background: rgb(255, 255, 255);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
`;

const GridItemTextContainer = styled.div`
  color: #fff;
  font-size: 0.875rem;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  padding: 0 24px 20px 28px;
  text-shadow: 0 0.25rem 0.375rem rgb(0 0 0 / 30%);
`;

const EnvImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const CardWrapper = styled.div`
  min-height: 400px;
  background: #ffffff;
  box-shadow: 0px 18px 40px rgba(112, 144, 176, 0.12);
  border-radius: 30px;
  @media only screen and (max-width: 500px) {
  }
`;
