import HeaderClosed from "../HeaderClosed";
import styled from "styled-components";
import { LayoutFooterClosed } from "./LayoutFooterClosed";
import {NavigationBar} from '../navigation/NavigationBar';

const PageSpecific = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const LayoutDefaultPageClosed = ({ children }) => (
  <>
      <HeaderClosed />
      <PageSpecific>{children}</PageSpecific>
      <NavigationBar />
      <LayoutFooterClosed />
  </>
);
