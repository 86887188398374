import React from "react";
import Modal from "./Modal";
import { LogOutProvider } from "../../providers/LogOutProvider";
import { ButtonChip } from "../button/ButtonChip";
import { ModalTitle } from "./ModalTitle";
import { ModalText } from "./ModalText";

export const ModalLogOut = ({ isModalOpen, setIsModalOpen }) => {
  return (
    <Modal
      id="modal"
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
    >
      <div className="box-header">
        <ModalTitle>Log out</ModalTitle>
        <ModalText>Are you sure to log out?</ModalText>
      </div>
      <div className="box-footer">
        <div className="close">
          <ButtonChip
            width={110}
            height={32}
            onClick={() => setIsModalOpen(false)}
          >
            Cancel
          </ButtonChip>
        </div>
        <LogOutProvider>
          <ButtonChip hasBackgroundColor>Yes</ButtonChip>
        </LogOutProvider>
      </div>
    </Modal>
  );
};
