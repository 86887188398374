import client from "../Client";

const apiUrl = process.env.REACT_APP_SYSTEMS_BASE_API_URL;

export const validateGateAccess = async (spaceUuid) => {
  try {
    const response = await client.get(
      `${apiUrl}/api/v1/spaces/pro/${spaceUuid}/gate`
    );
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
