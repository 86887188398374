import React from "react";
import CreateRandomNumber from "../../utils/CreateRandomNumber";

function ModelViewer({ modalUrl }) {
  return (
    <>
      <script
        type="module"
        src="https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js"
      />
      <model-viewer
        id="transform"
        style={{
          height: "400px",
          width: "315px",
        }}
        arc-scale
        autoplay
        ar
        exposure="0.83"
        min-field-of-view="1deg"
        field-of-view="20deg"
        src={`${modalUrl}??${CreateRandomNumber}`}
        disable-zoom
        shadow-intensity="1.74"
        shadow-softness="0.88"
        camera-controls
        camera-orbit="20deg 80deg 5.5m"
        max-camera-orbit="20deg auto"
        min-camera-orbit="-20deg -auto"
        environment-image="neutral"
      >
        <div className="progress-bar hide" slot="progress-bar">
          <div className="update-bar"> </div>
        </div>
      </model-viewer>
    </>
  );
}

export default ModelViewer;
