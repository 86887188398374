import axios from "axios";
import { getToken, removeUserCookies } from "../helpers/CookieHelper";
import * as Sentry from "@sentry/react";

const client = () => {
  //empty
  const instance = axios.create({
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  instance.interceptors.request.use((req) => {
    const token = getToken();
    if (req.headers) {
      req.headers.Authorization = token ? `Bearer ${token}` : "";
    }
    return req;
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        Sentry.captureException(error);
        removeUserCookies();
      } else if (
        error.response?.status === 400 ||
        error.response?.status === 404 ||
        error.response?.status === 500 ||
        error.response?.status === 502 ||
        error.response?.status === 503 ||
        error.response?.status === 504
      ) {
        Sentry.captureException(error);
        return Promise.reject(error.response);
      }
      Sentry.captureException(error);
      return error.response.data;
    }
  );
  return instance;
};

export default client();
