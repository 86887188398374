import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ORGANIZATION_ROLE } from "../../constants/organizations";
import {
  errorNotification,
  successNotification,
} from "../notifications/Notifications";
import styles from "../../styles/DefaultForm.modules.css";
import { InputFieldWrapper } from "../Input/InputFieldWrapper";
import { InputFieldTitle } from "../Input/InputFieldTitle";
import { InputFieldError } from "../Input/InputFieldError";
import LoaderCircleSmall from "../loader/LoaderCircleSmall";
import { inviteUserToOrg } from "../../api/services/OrganizationUserServices";

export const FormAddUserToOrg = ({ selectedOption }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);

  async function onSubmit({ userEmail, organizationRole }) {
    setLoading(true);
    try {
      await inviteUserToOrg({
        organizationName: selectedOption,
        userEmail,
        organizationRole,
      });
      successNotification("User invited successfully");
      setLoading(false);
    } catch (error) {
      errorNotification(error.message);
      setLoading(false);
    }
  }

  return (
    <div className={styles.formContainer}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputFieldWrapper>
          <InputFieldTitle>Email</InputFieldTitle>
          <input
            {...register("userEmail", { required: true })}
            type="userEmail"
          />
          {errors?.userEmail && (
            <InputFieldError>Email is required</InputFieldError>
          )}
        </InputFieldWrapper>
        <InputFieldTitle>Role</InputFieldTitle>
        <select {...register("organizationRole")}>
          <option value={ORGANIZATION_ROLE.guest}>Guest</option>
          <option value={ORGANIZATION_ROLE.user}>User</option>
          <option value={ORGANIZATION_ROLE.admin}>Admin</option>
        </select>
        <button type="submit">
          {loading ? <LoaderCircleSmall /> : "Invite"}
        </button>
      </form>
    </div>
  );
};
