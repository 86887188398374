import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ORGANIZATION_ROLE } from "../../constants/organizations";
import styles from "../../styles/DefaultForm.modules.css";
import { InputFieldWrapper } from "../Input/InputFieldWrapper";
import { InputFieldTitle } from "../Input/InputFieldTitle";
import LoaderCircleSmall from "../loader/LoaderCircleSmall";
import {
  errorNotification,
  successNotification,
} from "../notifications/Notifications";
import { updateOrganizationRole } from "../../api/services/OrganizationUserServices";

export const FormChangeOrganizationRole = ({
  userUUID,
  organizationName,
  closeModal,
}) => {
  const { register, handleSubmit } = useForm();

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      await updateOrganizationRole({
        organizationName,
        userUUID,
        organizationRole: data.organizationRole,
      });
      successNotification("Updated organization role");
      closeModal();
      setLoading(false);
    } catch (error) {
      errorNotification(error.message);
      setLoading(false);
    }
  };

  return (
    <div className={styles.formContainer}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputFieldWrapper>
          <InputFieldTitle>Role</InputFieldTitle>
          <select {...register("organizationRole")}>
            <option value={ORGANIZATION_ROLE.guest}>Guest</option>
            <option value={ORGANIZATION_ROLE.user}>User</option>
            <option value={ORGANIZATION_ROLE.admin}>Admin</option>
          </select>
        </InputFieldWrapper>

        <button type="submit">
          {loading ? <LoaderCircleSmall /> : "Change role"}
        </button>
      </form>
    </div>
  );
};
