import React, {useContext, useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import styled from 'styled-components';
import {ButtonChip} from './button/ButtonChip';
import Modal from './modal/Modal';
import LoaderCirclePrimary from './loader/LoaderCircleBig';
import {ModalTitle} from './modal/ModalTitle';
import {ModalText} from './modal/ModalText';
import {AuthContext} from '../providers/AuthContextProvider';
import {
    errorNotification,
    successNotification,
    warningNotification,
} from './notifications/Notifications';
import {getToken} from '../helpers/CookieHelper';
import {ProfileImageUploader} from "./ProfileImageUploader";

const apiUrl = process.env.REACT_APP_SYSTEMS_BASE_API_URL;

export const DragAndDrop = ({
                                setIsModalOpen,
                                setUserUpdate,
                                exceed,
                                title,
                                isModalOpen,
                                acceptedFileTypes,
                            }) => {
    const {
        user: {userUUID},
    } = useContext(AuthContext);
    const [showUpload, changeShowUpload] = useState(false);
    const [showDefaultOptions, changeShowDefaultOptions] = useState(true);

    const onClick = () => {

    };

    function toggleShowUpload() {
        changeShowUpload(!showUpload);
        changeShowDefaultOptions(!showDefaultOptions);
    }

    useEffect(() => {
        if (showDefaultOptions) {
            changeShowUpload(false);
        }
    }, [showDefaultOptions]);

    return (
        <>
            <Modal
                id="modal"
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            >
                <div className="box-header">
                    <ModalTitle>{title}</ModalTitle>
                    {showUpload && (
                        <ModalText>
                            {`Select drop or select a file to upload, file size cannot exceed ${exceed}`}
                        </ModalText>
                    )}
                </div>
                <div className="box-body">
                    <div style={{marginTop: '32px'}} onClick={onClick}>
                        {showDefaultOptions && (
                            <>
                                <ButtonChip onClick={toggleShowUpload}
                                            hasBackgroundColor={true}> Upload profile
                                    image </ButtonChip>

                            </>
                        )}
                        {showUpload && (
                            <>
                                <ProfileImageUploader/>
                            </>

                        )}
                    </div>
                </div>
                <div className="box-footer">
                    {showUpload && (
                        <>
                            <ButtonChip onClick={() => changeShowDefaultOptions(true)}
                                        hasBackgroundColor={false}> Go back </ButtonChip>
                        </>
                    )}
                    <div onClick={() => setIsModalOpen(false)} className="close">
                        <ButtonChip hasBackgroundColor>Done</ButtonChip>
                    </div>
                </div>
            </Modal>
        </>
    );
};
