import React, { useState } from "react";
import Modal from "./Modal";
import { ButtonChip } from "../button/ButtonChip";
import { ModalTitle } from "./ModalTitle";
import { ModalText } from "./ModalText";
import { ButtonText } from "../button/ButtonText";
import { deleteUserFromOrg } from "../../api/services/OrganizationUserServices";
import {
  errorNotification,
  successNotification,
} from "../notifications/Notifications";

export const ModalRemoveUserFromOrganization = ({
  userUUID,
  organizationName,
  refreshData,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const removeUser = async () => {
    try {
      await deleteUserFromOrg({
        organizationName,
        userUUID,
      });
      setIsModalOpen(false);
      successNotification("User removed from organization");
      refreshData(null);
    } catch (error) {
      errorNotification(error.message);
    }
  };

  return (
    <>
      <div onClick={() => setIsModalOpen(true)}>
        <ButtonText>Remove user</ButtonText>
      </div>
      {isModalOpen && (
        <Modal
          id="modal"
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        >
          <ModalTitle>Remove user</ModalTitle>
          <ModalText>
            Are you sure to remove user from the organization?
          </ModalText>
          <ButtonChip
            width={110}
            height={32}
            margin="16px 0px"
            onClick={() => setIsModalOpen(false)}
          >
            Cancel
          </ButtonChip>
          <ButtonChip hasBackgroundColor onClick={() => removeUser()}>
            Remove user
          </ButtonChip>
        </Modal>
      )}
    </>
  );
};
