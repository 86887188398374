import React, { Fragment, useEffect, useRef, useState } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import {
  errorNotification,
  infoNotification,
} from "../../components/notifications/Notifications";
import { getUser } from "../../helpers/CookieHelper";
import styled from "styled-components";
import { ModalLeaveSpace } from "../../components/modal/ModalLeaveSpace";
import Call from "../web-rtc/Call";
import LoaderCirclePrimary from "../../components/loader/LoaderCircleBig";
import { getSpaceProWithUuid, getSpaceProWithUuid2 } from "../../api/services/SpaceProServices";
import { useNavigate } from "react-router-dom";
import { ButtonRound } from "../../components/button/ButtonRound";
import { ModalUploadFileWebGL } from "../../components/modal/ModalUploadFileWebGL";
import { ReactComponent as Plus } from "../../assets/icons/plus.svg";
import { ReactComponent as SoundOn } from "../../assets/icons/sound-on.svg";
import { ReactComponent as SoundOff } from "../../assets/icons/sound-off.svg";
import { ReactComponent as Chat } from "../../assets/icons/chat.svg";
import { ReactComponent as ChatSlash } from "../../assets/icons/chat-slash.svg";

import WebSocketWebGL from "../webSocket/WebSocketWebGL";
import { ChatComponent } from "../webSocket/ChatComponent";
import { Badge } from "antd";
import { ButtonRoundYellow } from "../../components/button/ButtonRoundYellow";
import { useSpaceProWithUuid } from "../../hooks/api/useSpaceProWithUuid";
import client from "../../api/Client";

const apiUrl = process.env.REACT_APP_SYSTEMS_BASE_API_URL;


export default function WebGL(props: {
  spacePro: any;
  room: any;
  buildUrl: string;
  streamingAssetsUrl: string;
  agoraEnabled: boolean;
  apiBaseUrl: string;
  photonMode: number;
  socketio: any;
}) {
  const {
    spacePro,
    room,
    buildUrl,
    streamingAssetsUrl,
    agoraEnabled,
    apiBaseUrl,
    photonMode,
    socketio,
  } = props;

  const {
    unityProvider,
    loadingProgression,
    isLoaded,
    unload,
    addEventListener,
    removeEventListener,
    sendMessage,
    UNSAFE__unityInstance,
    UNSAFE__detachAndUnloadImmediate: detachAndUnloadImmediate,
  } = useUnityContext({
    loaderUrl: buildUrl + ".loader.js",
    dataUrl: buildUrl + ".data",
    frameworkUrl: buildUrl + ".framework.js",
    codeUrl: buildUrl + ".wasm",
    streamingAssetsUrl: streamingAssetsUrl + "/StreamingAssets",
    webglContextAttributes: {
      preserveDrawingBuffer: true,
    },
  });

  const {
    moderationRoomState,
    moderationUserState,
    socketEmitRaiseHand,
    socketEmitMuteState,
    socketUpdateCurrentState,
    updateUserState,
  } = WebSocketWebGL({ room, socketio });

  const [showUI, setShowUI] = useState(true);
  const [showChatModal, setShowChatModal] = useState(false);
  const [pixelCorrection, setPixelCorrection] = useState("100vh"); // 100dvh
  const [showSettings, setShowSettings] = useState(false);

  const permissions = navigator.mediaDevices.getUserMedia({ audio: true, video: false });

  const navigate = useNavigate();
  const currentUser = getUser();
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [hasMediaAccess, setHasMediaAccess] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState("Downloading assets...");
  const delay = (ms: number | undefined) =>
    new Promise((res) => setTimeout(res, ms));
  const [isUnityMounted, setIsUnityMounted] = useState<boolean>(true);
  const [isUnityRavelLoaded, setIsUnityRavelLoaded] = useState<boolean>(false); //TODO set to false as default
  const [progression, setProgression] = useState<number>(0);
  const [isUploadFileModalOpen, setIsUploadFileModalOpen] =
    useState<boolean>(false);
  const [fileState, setFileState] = useState<boolean>(false);
  const [isVoiceMounted, setIsVoiceMounted] = useState(false); //TODO set to false as default
  const [handRaised, setHandRaised] = useState<boolean>(false);
  const [soundMuted, setSoundMuted] = useState<boolean>(true);
  const [devicePixelRatio, setDevicePixelRatio] = useState(
    window.devicePixelRatio
  );
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(function () {
    if (!hasMediaAccess) {
      permissions.then((stream) => {
        stream.getTracks().forEach(x => {
          x.stop();
        });
        setShowSettings(true);
      }).catch((err) => {
        console.log(`${err.name} : ${err.message}`)
      });
    }
  }, []);

  useEffect(
    function () {
      const updateDevicePixelRatio = function () {
        setDevicePixelRatio(window.devicePixelRatio);
      };
      const mediaMatcher = window.matchMedia(
        `screen and (resolution: ${devicePixelRatio}dppx)`
      );
      mediaMatcher.addEventListener("change", updateDevicePixelRatio);
      return function () {
        mediaMatcher.removeEventListener("change", updateDevicePixelRatio);
      };
    },
    [devicePixelRatio]
  );

  useEffect(() => {
    if (isLoaded) {
      addEventListener("ServerPing", handleServerPing); //receives from unity when it received a ping and sends a ping back
      addEventListener("OnSpaceLoaded", handleSpaceLoaded); //receives from unity when the space is loaded
      addEventListener("OnEnterPortal", handleOnPortalEnter); //receives from unity when the player enters a portal
      addEventListener("OnLoadingActionChanged", handleOnLoadingActionChanged); //Event when loading starts and text changes
      addEventListener("OnLoadingFinished", handleOnLoadingFinished); //Loading screen can be turned off when this event triggers
      addEventListener("OnDisconnect", handleOnDisconnect); //receives from unity when the player is disconnected from the server
      addEventListener("UploadFile", handleOnUploadFile); //receives from unity if the player uploads a file
      addEventListener("OnUIVisibleChanged", handleOnUIVisibleChanged); //receives from unity if ui should be visable
      addEventListener("OnSoundMuteState", handleOnSoundMuteState); //receives from unity to set soundMuted state

      return () => {
        removeEventListener("ServerPing", handleServerPing); //receives from unity when it received a ping and sends a ping back
        removeEventListener("OnSpaceLoaded", handleSpaceLoaded); //receives from unity when the space is loaded
        removeEventListener("OnEnterPortal", handleOnPortalEnter); //receives from unity when the player enters a portal
        removeEventListener(
          "OnLoadingActionChanged",
          handleOnLoadingActionChanged
        ); //Event when loading starts and text changes
        removeEventListener("OnLoadingFinished", handleOnLoadingFinished); //Loading screen can be turned off when this event triggers
        removeEventListener("OnDisconnect", handleOnDisconnect);
        removeEventListener("UploadFile", handleOnUploadFile); //receives from unity when the player is disconnected from the server
        removeEventListener("OnUIVisibleChanged", handleOnUIVisibleChanged); //receives from unity if ui should be visable
        removeEventListener("OnUIVisibleChanged", handleOnSoundMuteState); //receives from unity to set soundMuted state
      };
    }
  }, [
    isLoaded,
    handleOnUIVisibleChanged,
    addEventListener,
    removeEventListener,
    handleServerPing,
    handleSpaceLoaded,
    handleOnPortalEnter,
    handleOnLoadingActionChanged,
    handleOnLoadingFinished,
    handleOnDisconnect,
    handleOnUploadFile,
  ]);

  useEffect(() => {
    socketUpdateCurrentState(loadingInfo);
  }, [loadingInfo]);

  useEffect(() => {
    if (moderationUserState.kicked === true) {
      infoNotification("You have been kicked from the room");
      returnToSpaces();
    }
  }, [moderationUserState]);

  /**
   * Below "detachAndUnloadImmediate" is a function that is used to unload the unity instance when users leave the page with the go-back button from the browser.
   * See more details here: https://github.com/jeffreylanters/react-unity-webgl/issues/22
   */
  useEffect(() => {
    return () => {
      detachAndUnloadImmediate();
    };
  }, [detachAndUnloadImmediate]);

  /**
   * Sets the unity instance after initialization so the Agora WebGlSDK scripts (see index.html) can use it to initialize the Agora SDK during streaming.
   */
  useEffect(
    // @ts-ignore
    () => (window.unityInstance = UNSAFE__unityInstance),
    [UNSAFE__unityInstance]
  );

  useEffect(() => {
    if (isLoaded) {
      setIsDownloaded(true);
      setLoadingInfo("Loading Space...");
      sendPing();
    }
  }, [isLoaded]);

  useEffect(() => {
    sendMessage("ServerHandle", "OnUploadCompleted");
  }, [fileState]);

  useEffect(() => {
    // size:&nbsp;{canvasRef.current?.width}x{canvasRef.current?.height}&nbsp;ua:&nbsp;{window.navigator.userAgent}
    console.log(window.navigator.userAgent);
    if (window.navigator.userAgent.startsWith("Mozilla/5.0 (Linux; Android 10; K)")) {
      // Use new CSS tag on all Android10+ devices for better scaling using menu bars.
      setPixelCorrection("100dvh");
    }
  }, []);

  function handleOnUploadFile(event: any) {
    setIsUploadFileModalOpen(true);
  }

  function openPanel(e: any) {
    sendMessage("ServerHandle", "OpenFilePanel");
    eventHandler(e);
  }

  function eventHandler(e: any) {
    e.stopPropagation();
    e.preventDefault();
  }

  async function handleSpaceLoaded() { }

  async function handleOnPortalEnter(destinationUuid: any) {
    await fetchAndLeave(destinationUuid);
  }

  //TODO this gives an error, needs to be fixed!
  const fetchAndLeave = async (spaceProUuid: any) => {
    // console.log(spaceProUuid);
    try {
      // const response = await getSpaceProWithUuid(spaceProUuid);
      // // @ts-ignore
      // const data = getSpaceProWithUuid({spaceProUuid});
      // @ts-ignore
      const response = await client.get(
        `${apiUrl}/api/v1/spaces/pro/spaces/url/${spaceProUuid}`)
      if (response.status === 200) {
        // @ts-ignore
        unload()
          .then(() => {
            setIsUnityMounted(false);
            setIsVoiceMounted(false);
          })
          .then(() => {
            // @ts-ignore
            // navigate(`/spaces/${response?.data?.sessionSpaceId}`);
            window.location.replace(`/spaces/${response?.data?.sessionSpaceId}`);
          });
      } else {
        unload().then(() => {
          errorNotification("InputFieldError loading space");
          navigate(-1);
        });
      }
    } catch (error) {
      errorNotification("InputFieldError loading space");
      // navigate(-1);
    }
  };

  function handleOnUIVisibleChanged(bool: boolean) {
    setShowUI(bool);
  }

  function handleOnShowChatModal(e: any) {
    if (showChatModal === true) {
      setShowChatModal(false);
    } else {
      setShowChatModal(true);
    }
    eventHandler(e);
  }

  function handleOnLoadingActionChanged(action: string, progression: number) {
    setLoadingInfo(`Finalizing, ${action}`);
    setProgression(progression);
  }

  async function handleOnLoadingFinished() {
    // socketUpdateCurrentState("Space loaded and connecting to Agora");
    await delay(500);
    setIsUnityRavelLoaded(true);
    await delay(500);
    setIsVoiceMounted(true);
  }

  async function handleOnDisconnect() {
    errorNotification(
      "Seems like your network is playing hard to get. Please try again later."
    );
    setLoadingInfo("Disconnected from server");
    await delay(500);
    returnToSpaces();
  }

  function handleOnSoundMuteState(bool: undefined) {
    if (bool === 1) {
      setSoundMuted(true);
    }
    if (bool === 0) {
      setSoundMuted(false);
    }
  }

  function toggleSoundMuteState(e: any) {
    if (soundMuted) {
      sendMessage("ServerHandle", "ToggleSoundMuteState", 0);
    }
    if (!soundMuted) {
      sendMessage("ServerHandle", "ToggleSoundMuteState", 1);
    }
    eventHandler(e);
  }

  async function sendPing() {
    // socketUpdateCurrentState("Setting up Unity WebGL");
    setLoadingInfo("Send message to Mars");

    await delay(500); //TODO replace this ugly delay
    sendMessage("ServerHandle", "UnityPing");
  }

  async function handleServerPing() {
    // socketUpdateCurrentState("Configuring the space...");
    setLoadingInfo("Message from Mars received, configuring systems...");
    await sendApiUrl();
    await sendAppMode();
    await sendUser();
    await sendSpacePro();
    await sendRoom();
    await delay(500);
    // setLoadingInfo("More additional configurations...");
  }

  function sendApiUrl() {
    sendMessage("ServerHandle", "SetAPIUrl", apiBaseUrl);
  }

  function sendAppMode() {
    if (photonMode === null) {
      errorNotification(`Error loading Space with network mode ${photonMode}`);
      navigate(-1);
    }
    // @ts-ignore
    sendMessage("ServerHandle", "SetAppMode", parseInt(photonMode));
  }

  function sendUser() {
    sendMessage("ServerHandle", "ServerLogin", JSON.stringify(currentUser));
  }

  function sendSpacePro() {
    sendMessage("ServerHandle", "SetSpace", JSON.stringify(spacePro));
  }

  function sendRoom() {
    sendMessage("ServerHandle", "SetRoom", JSON.stringify(room));
  }

  function returnToSpaces() {
    setLoadingInfo("Leaving Space");

    unload()
      .then(() => {
        setIsUnityMounted(false);
        setIsVoiceMounted(false);
        setLoadingInfo("Agora disconnected and Unity unloaded");
      })
      .then(() => {
        navigate(-1);
      });
  }

  //TODO implement sendMouthOpen, WebRTC receives mic audio level and sends it to unity. This will make mouth move
  function sendMouthOpen(amount: number) {
    sendMessage("ServerHandle", "SetAvatarMouthOpen", amount);
  }

  function handleHandRaise(e: any) {
    setHandRaised(!handRaised);
    socketEmitRaiseHand(!handRaised);
    eventHandler(e);
  }

  // @ts-ignore
  return (
    <>
      <Room>
        <ModalUploadFileWebGL
          isModalOpen={isUploadFileModalOpen}
          setIsModalOpen={setIsUploadFileModalOpen}
          setFileState={setFileState}
        />
        <RoomContent>
          <ControlBar style={{ display: showUI ? "" : "none" }}>
            <HeaderWrapper>
              <ModalLeaveSpace leaveSpace={returnToSpaces} />
            </HeaderWrapper>
          </ControlBar>
          {agoraEnabled && (
            <BottomBar>
              <BottomBarContainer>
                <div style={{
                  zIndex: 1000,
                  display: showUI ? "" : "none"
                }}>
                  <ButtonRoundYellow
                    onClick={(e: any) => toggleSoundMuteState(e)}
                    className={undefined}
                    disabled={false}
                  >
                    {soundMuted ? (
                      <>
                        <SoundOff />
                      </>
                    ) : (
                      <>
                        <SoundOn />
                      </>
                    )}
                  </ButtonRoundYellow>
                </div>
                {isVoiceMounted === true && (
                  <Call
                    room={room}
                    agoraToken={room.agoraToken}
                    photonRoomId={room.photonRoomId}
                    sessionUserId={room.sessionUserId}
                    moderationUserState={moderationUserState}
                    moderationRoomState={moderationRoomState}
                    socket={socketio}
                    updateUserState={updateUserState}
                    showUI={showUI}
                    showSettingsModelAsync={showSettings}
                  />
                )}

                <div style={{ display: showUI ? "flex" : "none" }}>
                  <ButtonRound
                    onClick={(e: any) => openPanel(e)}
                    className={undefined}
                    disabled={false}
                  >
                    <Plus />
                  </ButtonRound>

                  <ButtonRound
                    onClick={(e: any) => handleHandRaise(e)}
                    className={undefined}
                    disabled={false}
                  >
                    <>{handRaised ? <Badge dot={true}>👋</Badge> : <>✋</>}</>
                  </ButtonRound>
                  <ButtonRound
                    onClick={(e: any) => handleOnShowChatModal(e)}
                    className={undefined}
                    disabled={false}
                  >
                    <>{showChatModal ? <Chat /> : <ChatSlash />}</>
                  </ButtonRound>


                  <ChatWrapper>
                    <ChatComponent
                      socket={socketio}
                      user={currentUser}
                      showChatCard={showChatModal}
                      room={room.photonRoomId}
                    />
                  </ChatWrapper>

                </div>
              </BottomBarContainer>
            </BottomBar>
          )}
        </RoomContent>
      </Room>
      <Fragment>
        <div className="wrapper">
          {isUnityMounted === true && (
            <Fragment>
              <div style={{ height: pixelCorrection, width: '100vw', margin: '0px', padding: '0px', border: '0px', outline: 'none', cursor: 'none', overflow: 'hidden' }}>
                {isUnityRavelLoaded === false && (
                  <LoadingOverlay img={spacePro.environmentPro.imageUrl}>
                    <LoaderCirclePrimary />
                    <p
                      style={{
                        marginTop: "10px",
                        marginLeft: "10px",
                        color: "#c3c8d3",
                      }}
                    >
                      {" "}
                      {loadingInfo}
                    </p>
                    <ProgressBar>
                      <ProgressBarFill
                        style={{
                          width:
                            loadingProgression * 50 + progression * 50 + "%",
                        }}
                      />
                    </ProgressBar>
                  </LoadingOverlay>
                )}
                <Unity
                  ref={canvasRef}
                  tabIndex={1}
                  unityProvider={unityProvider}
                  devicePixelRatio={devicePixelRatio}
                  matchWebGLToCanvasSize={true}
                  style={{
                    visibility: isLoaded ? "visible" : "hidden",
                    width: "100%",
                    height: "100%",
                  }}
                />
                {/* </UnityContainer> */}
              </div>
            </Fragment>
          )}
        </div>
      </Fragment>
    </>
  );
}

const ChatWrapper = styled.div`
  display: flex;
  max-width: 200px;
  min-width: 200px;
  position: absolute;
  bottom: 100px;
  left: 160px;
`;

const BottomBar = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  left: 20px;
  bottom: 20px;
  width: 100vw;
  position: fixed;
`;

const BottomBarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  height: 100px;
  border-radius: 103.5px;
`;

// @ts-ignore
const LoadingOverlay = styled.div<ImageProps>`
  background: linear-gradient(
      111.43deg,
      rgba(122, 118, 118, 0.3),
      rgba(0, 0, 0, 0.3)
    ),
    url(${(props) => props.img});
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const ProgressBar = styled.div`
  position: relative;
  display: inline-block;
  width: 300px;
  height: 10px;
  background: linear-gradient(
    270deg,
    #e0e5f2 34.37%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 60px;
  overflow: hidden;
`;

const ProgressBarFill = styled.div`
  height: 10px;
  background: linear-gradient(
    270deg,
    #4a4aff 5.56%,
    rgba(74, 74, 255, 0.5) 100%
  );
  transition: width 0.5s ease;
`;

const ControlBar = styled.div`
  display: grid;
  z-index: 2;
  pointer-events: none !important;
`;

const Room = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none !important;
`;

const RoomContent = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  flex-direction: column;
  align-items: center;
  pointer-events: none !important;
`;

const UnityContainer = styled.div`
  height: 100vh;
  width: 100vw;
  outline: none;
  cursor: none;
  overflow: hidden;
`;

const HeaderWrapper = styled.header`
  display: flex;
  padding: 20px 25px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: 800px) {
    padding: 25px 10px;
    justify-content: center;
  }
`;
