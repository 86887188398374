import React, { useContext, useState } from "react";
import styled from "styled-components";
import { AuthContext } from "../../providers/AuthContextProvider";
import { SubMenuContainer } from "../subMenu/SubMenuContainer";
import { SubMenuBar } from "../subMenu/SubMenuBar";
import { SubMenuItem } from "../subMenu/SubMenuItem";
import { ButtonChip } from "../button/ButtonChip";
import { useSpaceEnivroments } from "../../hooks/api/useSpaceEnivroments";
import { FormSpacesAdd } from "../form/FormSpacesAdd";
import { CardContentWrapper } from "../cards/CardContentWrapper";
import styles from "../../styles/DefaultForm.modules.css";
import {ModalTitle} from '../modal/ModalTitle';
import {ModalText} from '../modal/ModalText';
import Modal from '../modal/Modal';

export const SpacesAdd = ({
  setCreateNewSpace,
  selectedType,
  userOrganizationMember,
  selectedOrganization,
}) => {
  const {
    user: { userUUID },
  } = useContext(AuthContext);
  const [spaceType, setSpaceType] = useState("PUBLIC");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: environments } = useSpaceEnivroments({
    environmentType: spaceType,
    organizationId:
      spaceType === "USER"
        ? userUUID
        : selectedOrganization.organization.organizationId,
  });

  return (
    <>

      <div>
        <div onClick={() => setIsModalOpen(true)}>
          <ButtonChip>Add Space</ButtonChip>
        </div>
        {isModalOpen && (
            <Modal
                id="modal"
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                modalSize={'xlg'}
            >
              <ModalTitle>Add space</ModalTitle>
              <ModalText>
                Provide a name, description and the default role for the space. Then select the environment for the new Space.
              </ModalText>
              <div className="box-body">
              <div className={styles.formContainer}>
                <FormSpacesAdd
                    environments={environments}
                    userOrganizationMember={userOrganizationMember}
                    setSpaceType={setSpaceType}
                    selectedType={selectedType}
                    selectedOrganization={selectedOrganization}
                />
              </div>
              </div>

              <div className="box-footer">
                <div onClick={() => setIsModalOpen(false)} className="close">
                  <ButtonChip width={110} height={32}>
                    Cancel
                  </ButtonChip>
                </div>
                <div onClick={() => setIsModalOpen(false)} className="close">
                  <ButtonChip hasBackgroundColor>Done</ButtonChip>
                </div>
              </div>
            </Modal>
        )}
      </div>


      {/*<SubMenuContainer>*/}
      {/*  <SubMenuBar>*/}
      {/*    <SubMenuItem onClick={() => setCreateNewSpace(false)}>*/}
      {/*      <ButtonChip width={140} height={40}>*/}
      {/*        Go back*/}
      {/*      </ButtonChip>*/}
      {/*    </SubMenuItem>*/}
      {/*  </SubMenuBar>*/}
      {/*</SubMenuContainer>*/}
      {/*<DefaultPageClosedContentWrapper>*/}
      {/*  <DefaultPageClosedContentContainer>*/}
      {/*    <CardContentWrapper>*/}
      {/*      <div className={styles.formContainer}>*/}
      {/*        <FormSpacesAdd*/}
      {/*          environments={environments}*/}
      {/*          userOrganizationMember={userOrganizationMember}*/}
      {/*          setSpaceType={setSpaceType}*/}
      {/*          selectedType={selectedType}*/}
      {/*          selectedOrganization={selectedOrganization}*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*    </CardContentWrapper>*/}
      {/*  </DefaultPageClosedContentContainer>*/}
      {/*</DefaultPageClosedContentWrapper>*/}
    </>
  );
};
