import { CardWrapper } from "../cards/CardWrapper";
import styled, { css } from "styled-components";

// TODO: refactor this -> breakdown
export const SpacesEnvironmentList = ({ environments, submit }) => {
  return (
    <>
      {environments && (
        <EnvironmentsContainer>
          <EnvironmentsList>
            <EnvGrid>
              {environments.map((link) => (
                <GridItemContainer
                  onClick={() => submit(link.environmentUuid)}
                  key={link.environmentUuid}
                >
                  <CardWrapper>
                    <EnvImageWrapper>
                      <GridItemContent>
                        <GridItemImageMask
                          src={link.imageUrl}
                          alt={link.name}
                        ></GridItemImageMask>
                        <GridItemTextContainer>
                          <GridItemOnline>{link.name}</GridItemOnline>
                        </GridItemTextContainer>
                      </GridItemContent>
                    </EnvImageWrapper>
                  </CardWrapper>
                </GridItemContainer>
              ))}
            </EnvGrid>
          </EnvironmentsList>
        </EnvironmentsContainer>
      )}
    </>
  );
};

const EnvGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: stretch;
  row-gap: 20px;
  column-gap: 10px;
  //width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const EnvironmentsContainer = styled.div`
  ${({ theme }) => css`
    height: 250px;
    display: flex;
    justify-content: center;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 100%;
    // background-color: ${theme.colour.secondary.grey300};
    border-radius: 8px;
    padding: 24px 0px;
  `};
  &::-webkit-scrollbar {
    display: none;
  }
`;

const GridItemOnline = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-top: 8px;
`;

const EnvironmentsList = styled.div`
  height: 100%;
`;

const EnvImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 150px;
  align-items: flex-start;
`;

const GridItemContainer = styled.div`
  height: 100%;
  position: relative;
  width: 290px;
`;

const GridItemContent = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

const GridItemImageMask = styled.img`
  height: 100%;
  width: 100%;
  bottom: 0;
  border-radius: 30px;
  transition: 0.5s all;
  transform: scale(1);

  &:hover {
    transform: scale(1.05);
    transition: 0.5s;
    box-shadow: 0 16px 48px 0 rgb(0 0 0 / 24%);
  }
`;

const GridItemTextContainer = styled.div`
  color: #fff;
  font-size: 0.875rem;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  padding: 0 24px 20px 28px;
  text-shadow: 0 0.25rem 0.375rem rgb(0 0 0 / 30%);
`;
