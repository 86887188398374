import React, { useContext, useEffect, useState } from "react";
import { SubMenuBar } from "../subMenu/SubMenuBar";
import { infoNotification } from "../notifications/Notifications";
import { ORGANIZATION_ROLE } from "../../constants/organizations";
import { AuthContext } from "../../providers/AuthContextProvider";
import { SubMenuContainer } from "../subMenu/SubMenuContainer";
import { SubMenuItem } from "../subMenu/SubMenuItem";
import { SpacesContainer } from "./SpacesContainer";
import { SpacesAdd } from "./SpacesAdd";
import TabLeft from "../tabs/TabLeft";
import TabMiddle from "../tabs/TabMiddle";
import TabRight from "../tabs/TabRight";
import TabLeftSecondary from "../tabs/TabLeftSecondary";
import TabMiddleSecondary from "../tabs/TabMiddleSecondary";
import TabRightSecondary from "../tabs/TabRightSecondary";
import { ButtonChip } from "../button/ButtonChip";
import { useOrganizationsForUsers } from "../../hooks/api/useOrganizationsForUsers";
import { useSpacesPro } from "../../hooks/api/useSpacesPro";
import { useOrganizationRole } from "../../hooks/api/useOrganizationRole";
// import { useSpacesProType } from "../../hooks/api/useSpacesProType";

export const SpacesSection = () => {
  const {
    user: { userUUID },
  } = useContext(AuthContext);

  const [isAdmin, setIsAdmin] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOptionObject, setSelectedOptionObject] = useState([]);
  const [createNewSpace, setCreateNewSpace] = useState(false);
  const [userOrganizationMember, setUserOrganizationMember] = useState(false);

  const [showPublic, setShowPublic] = useState(true);
  const [showOrganization, setShowOrganization] = useState(false);
  const [spaceType, setSpaceType] = useState("PUBLIC");

  const { data: organizationsForUser } = useOrganizationsForUsers({
    userUUID,
  });

  const { data: spaceProForOrganization, isLoading: isLoadingSpacesPro } =
    useSpacesPro({
      orgaizationType: spaceType,
      organizationId: selectedOptionObject?.organization?.organizationId,
    });

  const { data: userRole } = useOrganizationRole({
    organization:
      selectedOption || organizationsForUser
        ? organizationsForUser[0].organization?.organizationName
        : null,
    userUUID,
  });

  //for private when ready
  // const {
  //   data: spaceProPrivateForOrganization,
  //   isLoading: isLoadingSpacesProPrivate,
  // } = useSpacesProType({
  //   orgaizationType: "PUBLIC",
  // });

  function handleOnChangeSelected(value) {
    setSelectedOption(value);
    const currentSelectionObject = organizationsForUser.find(
      (organization) => organization.organization.organizationName === value
    );
    setSelectedOptionObject(currentSelectionObject);
    if (
      currentSelectionObject.organizationRole === ORGANIZATION_ROLE.admin ||
      currentSelectionObject.organizationRole === ORGANIZATION_ROLE.owner
    ) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }

  useEffect(() => {
    if (
      userRole === ORGANIZATION_ROLE.admin ||
      userRole === ORGANIZATION_ROLE.owner
    ) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [userRole]);

  useEffect(() => {
    if (organizationsForUser) {
      const [firstOrganizationData] = organizationsForUser || [];
      setSelectedOption(firstOrganizationData?.organization?.organizationName);
      setSelectedOptionObject(firstOrganizationData);
      setUserOrganizationMember(true);
    } else {
      setUserOrganizationMember(false);
      setSelectedOptionObject([]);
      setSelectedOption("");
    }
  }, [organizationsForUser]);

  const handleOnClickPublic = () => {
    setShowPublic(true);
    setShowOrganization(false);
    setSpaceType("PUBLIC");
  };

  const handleOnClickPrivate = () => {
    // setSpaceType('PUBLIC');
    // setShowPublic(false);
    // setShowOrganization(false);
    infoNotification("Private spaces are not yet available");
  };

  const handleOnClickOrganization = () => {
    setShowPublic(false);
    setShowOrganization(true);
    setSpaceType("ORGANIZATION");
  };

  return (
    <>
      {createNewSpace ? (
        <SpacesAdd
          setCreateNewSpace={setCreateNewSpace}
          selectedType={spaceType}
          userOrganizationMember={userOrganizationMember}
          selectedOrganization={selectedOptionObject}
        />
      ) : (
        <>
          <SubMenuContainer>
            <SubMenuBar>
              <SubMenuItem>
                <div onClick={handleOnClickPublic}>
                  {showPublic ? (
                    <TabLeft>Public</TabLeft>
                  ) : (
                    <TabLeftSecondary>Public</TabLeftSecondary>
                  )}
                </div>
                <div onClick={handleOnClickOrganization}>
                  {showOrganization && userOrganizationMember ? (
                    <TabMiddle>Organization</TabMiddle>
                  ) : (
                    userOrganizationMember && (
                      <TabMiddleSecondary>Organization</TabMiddleSecondary>
                    )
                  )}
                </div>
                <div onClick={handleOnClickPrivate}>
                  {!showPublic && !showOrganization ? (
                    <TabRight>Private</TabRight>
                  ) : (
                    <TabRightSecondary>Private</TabRightSecondary>
                  )}
                </div>
              </SubMenuItem>
              {showOrganization &&
                userOrganizationMember &&
                organizationsForUser.length > 1 && (
                  <SubMenuItem>
                    <select
                      value={selectedOption}
                      onChange={(e) => handleOnChangeSelected(e.target.value)}
                    >
                      {organizationsForUser.map((link) => (
                        <option
                          key={link.organization.organizationName}
                          value={link.organization.organizationName}
                        >
                          {link.organization.organizationName}
                        </option>
                      ))}
                    </select>
                  </SubMenuItem>
                )}
              {showOrganization && isAdmin && (
                <SubMenuItem>
                  {/* <div onClick={() => navigate("/spaces/new-space")}> */}
                  <div onClick={() => setCreateNewSpace(true)}>
                    <ButtonChip width={140} height={40}>
                      Add Space
                    </ButtonChip>
                  </div>
                </SubMenuItem>
              )}
            </SubMenuBar>
          </SubMenuContainer>
          <SpacesContainer
              showPublic={showPublic}
            isLoading={isLoadingSpacesPro}
            data={spaceProForOrganization}
          />
        </>
      )}
    </>
  );
};
