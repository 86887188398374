import {useForm} from 'react-hook-form';
import {errorNotification, successNotification} from '../notifications/Notifications';
import styles from '../../styles/DefaultForm.modules.css';
import {InputFieldWrapper} from '../Input/InputFieldWrapper';
import {InputFieldTitle} from '../Input/InputFieldTitle';
import {InputFieldError} from '../Input/InputFieldError';
import LoaderCircleSmall from '../loader/LoaderCircleSmall';
import {useParams} from 'react-router-dom';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {setNewPassword} from '../../api/services/AccountServices';
import {useEffect, useState} from 'react';
import * as Sentry from '@sentry/react';
import {CardSubText} from "../cards/CardSubText";

export const FormResetPassword = ({newPassWordSet}) => {
  const [hasUppercase, setHasUppercase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);

  const {resetPasswordCode} = useParams();
  const [loading, setLoading] = useState(false);
  const formSchema = Yup.object().shape({
    password: Yup.string()
        .required('Password is mandatory')
        .min(8, 'Password must contain a minimum of 8 characters')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/\d/, 'Password must contain at least one number')
        .matches(/[\^$*.\[\]{}()?\-"!@#%&/,><':;|_~`]/, 'Password must contain at least one special character')
      ,
    passwordValidate: Yup.string().
        required('Password is mandatory').
        oneOf([Yup.ref('password')], 'Passwords does not match'),
  });
  const formOptions = {resolver: yupResolver(formSchema)};
  const {register, handleSubmit, formState, watch} = useForm(formOptions);
  const {errors} = formState;

  const password = watch('password');

  useEffect(() => {
    setHasUppercase(/[A-Z]/.test(password));
    setHasNumber(/\d/.test(password));
    setHasSpecialChar(/[\^$*.\[\]{}()?\-"!@#%&/,><':;|_~`]/.test(password));
  }, [password]);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await setNewPassword({
        resetPasswordCode: resetPasswordCode,
        password: data.password,
        passwordValidate: data.passwordValidate,
      });
        if(response.status === 200){
          setLoading(false);
          newPassWordSet();
          successNotification('Password changed successfully')
        } else {
        errorNotification(response.data.message);
        setLoading(false);
      }

    } catch (error) {
      setLoading(false);
    }
  };

  return (<div className={styles.formContainer}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputFieldWrapper>
            <InputFieldTitle>New password</InputFieldTitle>
            <input
                name="password"
                type="password"
                {...register('password')}
                className={`form-control ${errors.password
                    ? 'is-invalid'
                    : ''}`}
            />
            {errors?.password &&
                (<InputFieldError>{errors.password?.message}</InputFieldError>)}
          </InputFieldWrapper>
          <InputFieldWrapper>
            <InputFieldTitle>Confirm new password</InputFieldTitle>
            <input
                name="passwordValidate"
                type="password"
                {...register('passwordValidate')}
                className={`form-control ${errors.passwordValidate
                    ? 'is-invalid'
                    : ''}`}
            />
            {errors?.passwordValidate && (<InputFieldError>
                  {errors?.passwordValidate?.message}
                </InputFieldError>)}
            <div>
              <CardSubText>Has uppercase: {hasUppercase ? 'Yes' : 'No'}</CardSubText>
              <CardSubText>Has number: {hasNumber ? 'Yes' : 'No'}</CardSubText>
              <CardSubText>Has special character: {hasSpecialChar ? 'Yes' : 'No'}</CardSubText>

            </div>
          </InputFieldWrapper>

          <button type="submit">
            {loading ? <LoaderCircleSmall/> : 'Submit'}
          </button>
        </form>
      </div>);
};
