import { useForm } from "react-hook-form";
import {useEffect, useState} from 'react';
import { errorNotification } from "../notifications/Notifications";
import styles from "../../styles/DefaultForm.modules.css";
import { InputFieldWrapper } from "../Input/InputFieldWrapper";
import { InputFieldTitle } from "../Input/InputFieldTitle";
import { InputFieldError } from "../Input/InputFieldError";
import LoaderCircleSmall from "../loader/LoaderCircleSmall";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { createAccount } from "../../api/services/AccountServices";
import * as Sentry from "@sentry/react";
import {CardSubText} from '../cards/CardSubText';

export const FormCreateAccount = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [hasUppercase, setHasUppercase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [passwordVisable, setPasswordVisable] = useState(false);

  const formSchema = Yup.object().shape({
    password: Yup.string()
    .required('Password is mandatory')
    .min(8, 'Password must contain a minimum of 8 characters')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/\d/, 'Password must contain at least one number')
    .matches(/[\^$*.\[\]{}()?\-"!@#%&/,><':;|_~`]/, 'Password must contain at least one special character')
    ,
    passwordValidate: Yup.string().
        required('Password is mandatory').
        oneOf([Yup.ref('password')], 'Passwords does not match'),
  });
  const formOptions = { resolver: yupResolver(formSchema) };
  const {register, handleSubmit, formState, watch} = useForm(formOptions);
  const { errors } = formState;

  const password = watch('password');

  useEffect(() => {
    setHasUppercase(/[A-Z]/.test(password));
    setHasNumber(/\d/.test(password));
    setHasSpecialChar(/[\^$*.\[\]{}()?\-"!@#%&/,><':;|_~`]/.test(password));
  }, [password]);



  const onSubmit = async ({ firstName, lastName, password }) => {
    setLoading(true);
    try {
      await createAccount({
        token,
        firstName,
        lastName,
        password,
      });
      navigate("/login");
      setLoading(false);
    } catch (error) {
      Sentry.setUser({ firstName, inviteCode: token });
      Sentry.captureException(error);
      errorNotification(error.data.message);
      setLoading(false);
    }
  };
  return (
      <div className={styles.formContainer}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* First name */}
          <InputFieldWrapper>
            <InputFieldTitle>First name</InputFieldTitle>
            <input {...register("firstName", { required: true })} type="text" />
            {errors?.firstName && (
                <InputFieldError>First name is required</InputFieldError>
            )}
          </InputFieldWrapper>

          {/* Last name */}
          <InputFieldWrapper>
            <InputFieldTitle>Last name</InputFieldTitle>
            <input {...register("lastName", { required: true })} type="text" />
            {errors?.lastName && (
                <InputFieldError>Last name is required</InputFieldError>
            )}
          </InputFieldWrapper>

          {/* Password */}
          <InputFieldWrapper>
            <InputFieldTitle>Your password</InputFieldTitle>
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
              <input
                  name="password"
                  type={passwordVisable ? "text" : "password"}
                  {...register("password")}
                  className={`form-control ${errors.password ? "is-invalid" : ""}`}
                  style={{ paddingRight: '40px' }}
              />
              <button
                  type="button"
                  onClick={() => setPasswordVisable(prevState => !prevState)}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer'
                  }}
              >
                {passwordVisable ? 'Hide' : 'Show'}
              </button>
            </div>
            {errors?.password && (
                <InputFieldError>{errors.password?.message}</InputFieldError>
            )}
          </InputFieldWrapper>

          {/* Confirm password */}
          <InputFieldWrapper>
            <InputFieldTitle>Confirm password</InputFieldTitle>
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
              <input
                  name="passwordValidate"
                  type={passwordVisable ? "text" : "password"}
                  {...register("passwordValidate")}
                  className={`form-control ${errors.passwordValidate ? "is-invalid" : ""}`}
                  style={{ paddingRight: '40px' }}
              />
              <button
                  type="button"
                  onClick={() => setPasswordVisable(prevState => !prevState)}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer'
                  }}
              >
                {passwordVisable ? 'Hide' : 'Show'}
              </button>
            </div>
            {errors?.passwordValidate && (
                <InputFieldError>{errors.passwordValidate?.message}</InputFieldError>
            )}
          </InputFieldWrapper>

          {/* Password criteria */}
          <div>
            <CardSubText>Has uppercase: {hasUppercase ? 'Yes' : 'No'}</CardSubText>
            <CardSubText>Has number: {hasNumber ? 'Yes' : 'No'}</CardSubText>
            <CardSubText>Has special character: {hasSpecialChar ? 'Yes' : 'No'}</CardSubText>
          </div>

          {/* Submit button */}
          <button type="submit">
            {loading ? <LoaderCircleSmall /> : "Submit"}
          </button>

        </form>
      </div>
  );
};
