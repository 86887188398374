import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import classNames from "classnames";
import { ModalLogOut } from "../modal/ModalLogOut";
import PairDevice from "../modal/ModalPairDevice";
import { ButtonText } from "../button/ButtonText";

export const NavigationSettingsMenu = ({ setOpen, open }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
      <NavigationMenuContainer className={classNames({ "is-open": open })}>
        <NavigationList>
          <NavigationItem>
            <PairDevice />
          </NavigationItem>
          {/*<FlaggedFeatureVideoStreaming>*/}
          {/*  <NavigationItem>*/}
          {/*    <Link to="/streaming">*/}
          {/*      <ButtonText>Streaming</ButtonText>*/}
          {/*    </Link>*/}
          {/*  </NavigationItem>*/}
          {/*</FlaggedFeatureVideoStreaming>*/}
          <NavigationItem>
            <Link to="/creators" rel="noreferrer">
              <ButtonText>Creators</ButtonText>
            </Link>
          </NavigationItem>
          <NavigationItem>
            <a
                href="https://docs.ravel.world"
                target={"_blank"}
                rel="noreferrer"
                onClick={() => setOpen(false)}
            >
              <ButtonText>Support</ButtonText>
            </a>
          </NavigationItem>
          <NavigationItem>
            <Link to="/integrations" rel="noreferrer">
              <ButtonText>Integrations</ButtonText>
            </Link>
          </NavigationItem>
          <NavigationItem>
            <a
                className="NavBarLink"
                href="/organization/content"
                rel="noreferrer"
                onClick={() => setOpen(false)}
            >
              <ButtonText>Organisation Content</ButtonText>
            </a>
          </NavigationItem>
          <NavigationItem>
            <ButtonText onClick={() => setIsModalOpen(true)}> Log out</ButtonText>

            {isModalOpen && (
                <ModalLogOut
                    setIsModalOpen={setIsModalOpen}
                    isModalOpen={isModalOpen}
                />
            )}
          </NavigationItem>
        </NavigationList>
      </NavigationMenuContainer>
  );
};

const NavigationMenuContainer = styled.div`
  display: none;
  position: absolute;
  z-index: 2;
  max-height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
  transition: 0.3s;
  opacity: 1;
  right: calc(100% - 75%);
  bottom: calc(48%);
  min-width: 180px;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 4px 90px 5px;
  border-radius: 18px;
  background-color: white;

  &.is-open {
    display: block;
    opacity: 1;
    pointer-events: all;
    transition: 0.3s;
  }
`;

const NavigationList = styled.div`
  text-align: start;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

const NavigationItem = styled.ul`
  padding: 0.3rem;
  transition: 0.3s all;
  transform: scale(0.95);

  &:hover {
    transform: scale(1);
    transition: 0.3s all;
  }
`;
