import { LayoutDefaultPageClosed } from "../../components/layout/LayoutDefaultPageClosed";
import { ProfileSection } from "../../components/profile/ProfileSection";
import { PageDetailsProvider } from "../../providers/PageDetailsProvider";

export const Profile = () => {
  return (
    <LayoutDefaultPageClosed>
      <PageDetailsProvider title="Profile" description="Spaces overview" />

      <ProfileSection />
    </LayoutDefaultPageClosed>
  );
};
