import client from "../Client";

const apiUrl = process.env.REACT_APP_SYSTEMS_BASE_API_URL;

export const verifyCode = async ({ code }) =>
  client
    .post(`${apiUrl}/v1/auth/passwordless/verify`, {
      code,
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });

export const authenticateUser = async ({ email, password }) =>
  client
    .post(`${apiUrl}/v1/auth/login`, {
      email,
      password,
    })
    .then(({ data }) => data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
