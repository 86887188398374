import React from "react";
import { LayoutDefaultPageClosed } from "../../components/layout/LayoutDefaultPageClosed";
import { ContentFiles } from "../../components/content/ContentFiles";
import { PageDetailsProvider } from "../../providers/PageDetailsProvider";

export const Content = () => {
  return (
    <LayoutDefaultPageClosed>
      <PageDetailsProvider
        title={"Content"}
        description={"Content overview"}
        meta={""}
      />

      <ContentFiles />
    </LayoutDefaultPageClosed>
  );
};
