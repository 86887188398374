import { useForm } from "react-hook-form";
import { useState } from "react";
import { errorNotification } from "../notifications/Notifications";
import styles from "../../styles/DefaultForm.modules.css";
import { InputFieldWrapper } from "../Input/InputFieldWrapper";
import { InputFieldTitle } from "../Input/InputFieldTitle";
import { InputFieldError } from "../Input/InputFieldError";
import LoaderCircleSmall from "../loader/LoaderCircleSmall";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { createAccountForCreator } from "../../api/services/AccountServices";
import * as Sentry from "@sentry/react";

function FormCreateAccountForCreator() {
  const { token } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const formSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is mandatory")
      .min(6, "Password must contain a minimum of 6 characters"),
    passwordValidate: Yup.string()
      .required("Password is mandatory")
      .oneOf([Yup.ref("password")], "Passwords does not match"),
  });
  const formOptions = { resolver: yupResolver(formSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const onSubmit = async ({
    firstName,
    lastName,
    creatorName,
    creatorBio,
    password,
  }) => {
    setLoading(true);
    try {
      await createAccountForCreator({
        token,
        firstName,
        lastName,
        creatorName,
        creatorBio,
        password,
      });

      navigate("/login");
      setLoading(false);
    } catch (error) {
      Sentry.setUser({ firstName, inviteCode: token });
      Sentry.captureException(error);
      errorNotification("Cannot create your account");
      setLoading(false);
    }
  };

  return (
    <>
      <div className={styles.formContainer}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputFieldWrapper>
            <InputFieldTitle>First name</InputFieldTitle>
            <input {...register("firstName", { required: true })} type="text" />
            {errors?.firstName && (
              <InputFieldError>First name is required</InputFieldError>
            )}
          </InputFieldWrapper>
          <InputFieldWrapper>
            <InputFieldTitle>Last name</InputFieldTitle>
            <input {...register("lastName", { required: true })} type="text" />
            {errors?.lastName && (
              <InputFieldError>Last name is required</InputFieldError>
            )}
          </InputFieldWrapper>
          <InputFieldWrapper>
            <InputFieldTitle>Creator name</InputFieldTitle>
            <input
              {...register("creatorName", { required: true })}
              type="text"
            />
            {errors?.creatorName && (
              <InputFieldError>Creator name is required</InputFieldError>
            )}
          </InputFieldWrapper>
          <InputFieldWrapper>
            <InputFieldTitle>Creator bio</InputFieldTitle>
            <input
              {...register("creatorBio", { required: true })}
              type="text"
            />
            {errors?.creatorBio && (
              <InputFieldError>Creator bio is required</InputFieldError>
            )}
          </InputFieldWrapper>
          <InputFieldWrapper>
            <InputFieldTitle>New password</InputFieldTitle>
            <input
              name="password"
              type="password"
              {...register("password")}
              className={`form-control ${errors?.password ? "is-invalid" : ""}`}
            />
            {errors.password && (
              <InputFieldError>{errors.password?.message}</InputFieldError>
            )}
          </InputFieldWrapper>
          <InputFieldWrapper>
            <InputFieldTitle>Confirm new password</InputFieldTitle>
            <input
              name="passwordValidate"
              type="password"
              {...register("passwordValidate")}
              className={`form-control ${
                errors?.passwordValidate ? "is-invalid" : ""
              }`}
            />
            {errors?.passwordValidate && (
              <InputFieldError>
                {errors.passwordValidate.message}
              </InputFieldError>
            )}
          </InputFieldWrapper>
          <button type="submit">
            {loading ? <LoaderCircleSmall /> : "Submit"}
          </button>
        </form>
      </div>
    </>
  );
}

export default FormCreateAccountForCreator;
