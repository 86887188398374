import React, { Component } from "react";
import { ButtonText } from "../button/ButtonText";
import Modal from "./Modal";
import { ButtonChip } from "../button/ButtonChip";
import { FormPairDevice } from "../form/FormPairDevice";
import { ModalTitle } from "./ModalTitle";
import { ModalText } from "./ModalText";

//TODO refactor to new component
class PairDevice extends Component {
  state = {
    isModalOpen: false,
  };

  toggleState = (e) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  };

  render() {
    return (
      <div onClick={this.toggleState}>
        <ButtonText> Pair VR device </ButtonText>
        {this.state.isModalOpen && (
          <Modal
            id="modal"
            isOpen={this.state.isModalOpen}
            onClose={this.toggleState}
          >
            <div className="box-header">
              <ModalTitle>Pair VR device</ModalTitle>
              <ModalText>Enter the code displayed on your VR device </ModalText>
            </div>
            <div className="box-body">
              <FormPairDevice />
            </div>
            <div className="box-footer">
              <div onClick={this.toggleState} className="close">
                <ButtonChip hasBackgroundColor>Done</ButtonChip>
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

export default PairDevice;
