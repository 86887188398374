import client from "../Client";
import axios from "axios";

const apiUrl = process.env.REACT_APP_SYSTEMS_BASE_API_URL;

const source = axios.CancelToken.source();

export const createAccount = async ({ token, firstName, lastName, password }) =>
  client
    .post(
      `${apiUrl}/api/v1/users/invites/signup?token=${token}`,
      {
        firstName: firstName,
        lastName: lastName,
        password: password,
        passwordValidate: password,
      },
      {
        cancelToken: source.token,
      }
    )
    .catch((error) => {
      console.error(error);
      throw error;
    });

export const createAccountForCreator = async ({
  token,
  firstName,
  lastName,
  creatorName,
  creatorBio,
  password,
}) =>
  client
    .post(
      `${apiUrl}/api/v1/users/create-account/creators?token=${token}`,
      {
        firstName,
        lastName,
        creatorName,
        creatorBio,
        password,
        passwordValidate: password,
      },
      {
        cancelToken: source.token,
      }
    )
    .catch((error) => {
      console.error(error);
      throw error;
    });

export const signUp = async (email, signUpType) =>
  client
    .post(`${apiUrl}/api/v1/users/signup?inviteType=${signUpType}`, {
      email,
    })
    .catch((error) => {
      console.error(error);
      throw error.repsonse;
    });

export const requestResetPassword = async ({ email }) =>
  client
    .post(
      `${apiUrl}/api/v2/users/reset-password-request`,
      {
        email,
      },
      { cancelToken: source.token }
    )
    .catch((error) => {
      console.error(error);
      throw error;
    });

export const setNewPassword = async ({ resetPasswordCode, password, passwordValidate }) =>
  client
    .post(`${apiUrl}/api/v1/users/resetpassword?token=${resetPasswordCode}`, {
      password,
      passwordValidate,
    })
    .catch((error) => {
      console.error(error);
      return  error;
    });

export const updateAvatar = async ({ userUUID, avatarUrl }) =>
  await client
    .put(`${apiUrl}/api/v2/users/avatar/${userUUID}`, {
      avatarUrl,
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });

export const updateAvatarFullBody = async ({ userUUID, avatarUrl }) =>
  await client
    .put(`${apiUrl}/api/v2/users/avatar/fullBody/${userUUID}`, {
      avatarUrl,
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });

export const getSelf = async () =>
  client
    .get(`${apiUrl}/api/v1/users/self`)
    .then(({ data }) => data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
