import styled from "styled-components";
import { ButtonTextStyles } from "../../styles/ButtonTextStyles";

const ButtonWrapper = styled.button`
  ${ButtonTextStyles}
  min-width: 110px;
  min-height: 32px;
  font-size: 14px;
  line-height: 24px;

  text-align: center;
  letter-spacing: -0.02em;
`;

export const ButtonText = ({ className, children, onClick }) => (
  <ButtonWrapper onClick={onClick} className={className}>
    <span className="title">{children}</span>
  </ButtonWrapper>
);
