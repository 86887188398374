import React, { useState } from "react";
import Modal from "./Modal";
import { ModalTitle } from "./ModalTitle";
import { ModalText } from "./ModalText";
import { ButtonText } from "../button/ButtonText";
import { ButtonChip } from "../button/ButtonChip";
import { FormChangeOrganizationRole } from "../form/FormChangeOrganizationRole";

export const ModalChangeOrganizationRole = ({
  userUUID,
  organizationName,
  refreshData,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    refreshData(null);
    setIsModalOpen(false);
  };

  return (
    <>
      <div onClick={() => setIsModalOpen(true)}>
        <ButtonText>Change role</ButtonText>
      </div>
      {isModalOpen && (
        <Modal
          id="modal"
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        >
          <ModalTitle>Change role</ModalTitle>
          <ModalText>Select the role for the user</ModalText>
          <FormChangeOrganizationRole
            userUUID={userUUID}
            organizationName={organizationName}
            closeModal={closeModal}
          />
          <div onClick={() => setIsModalOpen(false)}>
            <ButtonChip width={110} height={32}>
              Cancel
            </ButtonChip>
          </div>
        </Modal>
      )}
    </>
  );
};
