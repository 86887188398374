import { useQuery } from "react-query";
import { REACT_QUERY_CACHE_KEYS } from "./reactQueryCacheKeys";
import { getUserFiles } from "../../api/services/UserFilesServices";

export function useUserFile({ userFiles, enabled }) {
  const { data, isLoading, error } = useQuery(
    [REACT_QUERY_CACHE_KEYS.USER_FILES, userFiles],
    () => getUserFiles(),
    { enabled }
  );

  return { data, isLoading, error };
}
