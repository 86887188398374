import { useQuery } from "react-query";
import { REACT_QUERY_CACHE_KEYS } from "./reactQueryCacheKeys";
import { getOrganizationsForUser } from "../../api/services/OrganizationUserServices";

export function useOrganizationsForUsers({ userUUID }) {
  const { data, isLoading } = useQuery(
    [REACT_QUERY_CACHE_KEYS.ORGANIZATION_FOR_USERS, userUUID],
    () => getOrganizationsForUser({ userUUID })
  );
  return { data, isLoading };
}
