import { LayoutDefaultPageClosed } from "../../components/layout/LayoutDefaultPageClosed";
import { SpacesSection } from "../../components/spaces/SpacesSection";
import { PageDetailsProvider } from "../../providers/PageDetailsProvider";
import {
  SpacesNavigationMenu
} from '../../components/spaces/SpacesNavigationMenu';

export const SpacesPrivate = () => {
  return (
    <LayoutDefaultPageClosed>
      <PageDetailsProvider title="Private Spaces" description="Private Spaces overview" />
      {/*<SpacesNavigationMenu showPrivate={true} showOrganization={false} showPublic={false} userOrganizationMember={true} isOrganizationAdmin={false} />*/}

      {/*<SpacesSection />*/}
    </LayoutDefaultPageClosed>
  );
};
