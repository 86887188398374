import client from "../Client";

const apiUrl = process.env.REACT_APP_SYSTEMS_BASE_API_URL;

export const getOrganizationFiles = async (orgUuid) => {
  try {
    const response = await client.get(
      `${apiUrl}/api/v1/organizationfiles/organization/${orgUuid}`
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const deleteOrganizationFiles = async (id) => {
  try {
    const response = await client.delete(
      apiUrl + "/api/v1/organizationfiles/" + id
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const requestDownloadUrlOrg = async (id) => {
  try {
    const response = await client.get(
      apiUrl + "/api/v1/organizationfiles/request/" + id
    );

    return response;
  } catch (e) {
    console.error(e);
  }
};
