import LoaderCircleBig from "../loader/LoaderCircleBig";
import {SpacesCards} from "./SpacesCards";
import styled from "styled-components";
import CustomSingleSpaceCardEvent from './CustomSingleSpaceCardEvent';

export const SpacesContainer = ({ showPublic,data, isLoading }) => {
  return (
    <Container>
      <SpaceOverwiew>
        <SpaceList>
          <GridContainer>
            <SpaceGrid>
              {isLoading ? (
                <LoaderCircleBig />
              ) : (
                  <>
                    {showPublic && <CustomSingleSpaceCardEvent fetching={isLoading} />}
                    <SpacesCards data={data} isLoading={isLoading} />
                  </>

              )}
            </SpaceGrid>
          </GridContainer>
        </SpaceList>
      </SpaceOverwiew>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const SpaceOverwiew = styled.div`
  width: 80vw;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  @media (min-width: 1400px) {
    width: 60vw;
  }
`;

const SpaceList = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

const GridContainer = styled.div`
  height: 100%;
`;

const SpaceGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: stretch;
  row-gap: 40px;
  column-gap: 40px;
  width: 100%;
`;
