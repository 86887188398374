import React, { useState } from "react";
import { ButtonChip } from "../button/ButtonChip";
import Modal from "./Modal";
import { ModalTitle } from "./ModalTitle";
import { ModalText } from "./ModalText";
import { ButtonTextDefault } from "../button/ButtonTextDefault";
import { assignEnvironmentToUser } from "../../api/services/EnvironmentServices";
import {
  errorNotification,
  successNotification,
} from "../notifications/Notifications";
import { InputFieldWrapper } from "../Input/InputFieldWrapper";
import { InputFieldTitle } from "../Input/InputFieldTitle";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import styles from "../../styles/DefaultForm.modules.css";
import { InputFieldError } from "../Input/InputFieldError";
import LoaderCircleSmall from "../loader/LoaderCircleSmall";
import { ModalBody } from "./ModalBody";

function ModalManageAssigendUsers({
  environmentUuid,
  fetchEnvironmentDetails,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  async function onSubmit(data) {
    setLoading(true);
    try {
      const response = await assignEnvironmentToUser(
        environmentUuid,
        data.userEmail
      );
      if (response.status === 202) {
        setLoading(false);
        successNotification("Environment created successfully");
        fetchEnvironmentDetails();
      }
      if (response.status === 400) {
        errorNotification(response.message);
        setLoading(false);
      }
      if (response.status === 403) {
        errorNotification(response.message);
        setLoading(false);
      }
      if (response.status === 409) {
        errorNotification(response.message);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
    }
  }

  return (
    <div>
      <div onClick={() => setIsModalOpen(true)}>
        <ButtonTextDefault>Manage</ButtonTextDefault>
      </div>
      {isModalOpen && (
        <Modal
          id="modal"
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        >
          <ModalTitle>Manage users</ModalTitle>
          <ModalText>
            Enter the email address to assign to environment to an user. After
            adding the user they can use the environment to create new Spaces
          </ModalText>
          <ModalBody>
            <Container>
              <div className={styles.formContainer}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <InputFieldWrapper>
                    <InputFieldTitle>Name</InputFieldTitle>
                    <input
                      {...register("userEmail", { required: true })}
                      type="text"
                    />
                    {errors.userEmail && (
                      <InputFieldError>Email is required</InputFieldError>
                    )}
                  </InputFieldWrapper>

                  <button type="submit">
                    {loading ? <LoaderCircleSmall /> : "Assign"}{" "}
                  </button>
                </form>
              </div>
            </Container>
          </ModalBody>
          <div className="box-footer">
            <div onClick={() => setIsModalOpen(false)} className="close">
              <ButtonChip width={110} height={32}>
                Done
              </ButtonChip>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default ModalManageAssigendUsers;

const Container = styled.div`
  width: auto;
  height: auto;
  display: flex;
  align-content: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
