import client from "../Client";

const apiUrl = process.env.REACT_APP_SYSTEMS_BASE_API_URL;

export const getSpacesForOrganization = async ({
  organizationId,
  spaceType,
}) => {
  if (!organizationId)  return client
  .get(
      apiUrl +
      `/api/v1/spaces/pro/spaces?spaceType=${spaceType}`
  )
  .then(({ data }) => data)
  .catch((error) => {
    console.error(error);
    throw error;
  });
  return client
    .get(
      apiUrl +
        `/api/v1/spaces/pro/spaces?spaceType=${spaceType}&organizationUuid=${organizationId}`
    )
    .then(({ data }) => data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const getSpacesWithParams = async (
  spaceType,
  organizationUUID,
  eventId
) => {
  try {
    const response = await client.get(
      apiUrl + `/api/v1/spaces/pro/spaces?spaceType=${spaceType}`,
      {
        params: {
          ...(organizationUUID ? { organizationUuid: organizationUUID } : {}),
          ...(eventId ? { eventId: eventId } : {}),
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getSpaces = async ({ spaceType }) =>
  client
    .get(apiUrl + `/api/v1/spaces/pro/spaces?spaceType=${spaceType}`)
    .then(({ data }) => data)
    .catch((error) => {
      console.error(error);
      throw error;
    });

export const getPublicEnvironments = async () => {
  try {
    const response = await client.get(apiUrl + "/api/v1/spaces/env/pro/all");
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const getSpacePro = async (spaceProId) => {
  try {
    const response = await client.get(
      apiUrl + "/api/v1/spaces/pro/sessions/" + spaceProId
    );
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const deleteSpacePro = async ({ spaceUuid }) =>
  client.delete(`${apiUrl}/api/v1/spaces/pro/${spaceUuid}`).catch((error) => {
    console.error(error);
    throw error;
  });

export const getSpaceProWithUuid = async ({ spaceUuid }) =>
  client
    .get(`${apiUrl}/api/v1/spaces/pro/spaces/url/${spaceUuid}`)
    .then(({ data }) => data)
    .catch((error) => {
      console.error(error);
      throw error;
    });

export const getSpaceProWithUuid2 = async ({ spaceUuid }) => {
  try {
    const response = await client.get(
        `${apiUrl}/api/v1/spaces/pro/spaces/url/${spaceUuid}`)
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const getSessionDetails = async (userUuid, spaceProId) => {
  try {
    const response = await client.get(
      apiUrl + "/api/v1/spaces/pro/sessions/" + userUuid + "/" + spaceProId
    );
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const getAllSpacesAsSysAdmin = async () => {
  try {
    const response = await client.get(`${apiUrl}/api/v1/admin/spaces/pro/all`);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const createOrganizationSpace = async (
  spaceName,
  description,
  codeProtected,
  spaceType,
  environmentUuid,
  defaultSpaceRole,
  userUuid,
  organizationName
) =>
  client
    .post(`${apiUrl}/api/v1/spaces/pro/organizations/`, {
      spaceName,
      description,
      codeProtected,
      spaceType,
      environmentUuid,
      defaultSpaceRole,
      userUuid,
      organizationName,
    })
    .then((data) => data)
    .catch((error) => {
      console.error(error);
      throw error;
    });

export const getTokenForStreaming = async (
  createForChannel,
  createForUserId,
  agoraRole
) => {
  try {
    const response = await client.post(
      `${apiUrl}/api/v1/admin/agora/create?agoraRole=${agoraRole}`,
      {
        createForChannel: createForChannel,
        createForUserId: createForUserId,
      }
    );
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
