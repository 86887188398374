import React, { useState } from "react";
import { Table } from "../Table";
import Card from "../cards/Card";
import styled from "styled-components";
import { requestDownloadUrl } from "../../api/services/UserFilesServices";
import { ButtonIcon } from "../button/ButtonIcon";
import { errorNotification } from "../notifications/Notifications";
import { ModalDeleteFile } from "../modal/ModalDeleteFile";
import LoaderCirclePrimary from "../loader/LoaderCircleBig";

const onClickGetFile = async (id) => {
  try {
    const { data } = await requestDownloadUrl(id);
    const file_path = await data.preSignedUrl;
    const a = document.createElement("A");

    a.href = file_path;
    a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } catch (error) {
    console.error(error);
    errorNotification("Error downloading file");
  }
};

export const ContentFilesList = ({ setFileState, data, isLoading }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const columns = React.useMemo(
    () => [
      {
        Header: "file name",
        accessor: "fileName",
      },
      {
        Header: "file size",
        accessor: "fileSize",
        Cell: ({ cell: { value } }) => {
          const mb = Math.floor(value / 1000000);
          return (
            <>{mb > 0.0 ? `${mb} mb${mb > 1.0 ? " " : " "} ` : "1 mb <"}</>
          );
        },
      },
      {
        Header: "",
        accessor: "id",
        Cell: ({ cell: { value } }) => {
          return (
            <>
              <ButtonIcon
                hasBackground
                icon="trash"
                width={32}
                height={32}
                margin="0 8px"
                onClick={() => {
                  setItemToDelete(value);
                  setIsModalOpen(true);
                }}
              />

              <ButtonIcon
                hasBackground
                width={32}
                height={32}
                icon="download"
                onClick={() => onClickGetFile(value)}
              />
            </>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      {isLoading ? (
        <LoaderCirclePrimary />
      ) : !data?.length ? (
        <p>
          You can upload files by clicking on the upload button in the top right
          corner.
        </p>
      ) : (
        <>
          <Card>
            <ListWrapper>
              {isLoading && <LoaderCirclePrimary />}
              <Table columns={columns} data={data} />
            </ListWrapper>
          </Card>
        </>
      )}
      {isModalOpen && (
        <ModalDeleteFile
          setIsModalOpen={setIsModalOpen}
          setFileState={setFileState}
          id={itemToDelete}
          fetchData={data}
        />
      )}
    </>
  );
};

const ListWrapper = styled.div`
  display: flex;
  justify-content: center;
  min-width: 300px;
  max-height: 500px;
  overflow-y: scroll;
  @media only screen and (max-width: 500px) {
    max-height: 300px;
  }

  .menu-icon {
    margin-left: 8px;
    height: 24px;
    display: inline-block;
  }
`;
