import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

export const PageDetailsProvider = ({ title, description, meta = [] }) => {
  return (
    <HelmetProvider>
      <Helmet
        title={"Ravel " + title}
        htmlAttributes={{ lang: "en" }}
        meta={[
          {
            name: `description`,
            content: description,
          },
        ]}
      />
    </HelmetProvider>
  );
};
