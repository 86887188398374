import styled from "styled-components";
import { TabSecondary } from "../../styles/TabSecundary";

const Wrapper = styled.button`
  ${TabSecondary}
  width: 95px;
  height: 46px;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
`;

const TabMiddleSecondary = (props) => (
  <Wrapper className={props.className}>
    <span className="title">{props.children}</span>
  </Wrapper>
);

export default TabMiddleSecondary;
