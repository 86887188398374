import { useContext } from "react";
import { AuthContext } from "./AuthContextProvider";

export const LogOutProvider = ({ children }) => {
  const { logout } = useContext(AuthContext);

  return (
    <div onClick={logout}>
      <div>{children}</div>
    </div>
  );
};
