import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { LayoutDefaultPageClosed } from "../../components/layout/LayoutDefaultPageClosed";
import { SubMenuContainer } from "../../components/subMenu/SubMenuContainer";
import { SubMenuBar } from "../../components/subMenu/SubMenuBar";
import { SubMenuItem } from "../../components/subMenu/SubMenuItem";
import { SpacesDetail } from "../../components/spaces/SpacesDetail";
import { ButtonText } from "../../components/button/ButtonText";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  errorNotification,
  infoNotification,
  successNotification,
} from "../../components/notifications/Notifications";
import { ButtonChip } from "../../components/button/ButtonChip";
import { deleteSpacePro } from "../../api/services/SpaceProServices";
import { useSpaceProWithUuid } from "../../hooks/api/useSpaceProWithUuid";
import { ModalDeleteSpace } from "../../components/modal/ModalDeleteSpace";
//TODO: add organization owner to space?
//TODO: add online users to space?
//TODO: add user roles for specific space?
//TODO: add roles to details and change default roles?
export const SpaceDetails = () => {
  const { spaceUuid } = useParams();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: environment, isLoading: isLoadingEnvironment } =
    useSpaceProWithUuid({ spaceUuid });

  useEffect(() => {
    if (!environment && !isLoadingEnvironment) {
      navigate("/spaces/public");
    }
  });

  const deleteSpace = async () => {
    try {
      await deleteSpacePro({ spaceUuid });
      successNotification("Space deleted successfully");
      navigate(-1);
    } catch (e) {
      errorNotification(e.message);
    }
  };

  function handleOnPreview() {
    infoNotification("Coming soon");
  }

  function handleOnGoBack() {
    navigate(-1);
  }

  function handleOnEnter() {
    navigate(`/spaces/${environment.photonRoomId}`);
  }

  //TODO : implement validation user permission for specific space on actions to prevent unauthorized actions by users. Need backend impl of more details about space and specific user so these details are included in the API request
  const renderActions = () => (
    <>
      <div onClick={() => setIsModalOpen(true)}>
        <ButtonChip>Delete</ButtonChip>
      </div>
      {isModalOpen && (
        <ModalDeleteSpace
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          onHandleClick={deleteSpace}
        />
      )}
      {/*<div onClick={() => handleOnPreview()}>*/}
      {/*  <ButtonChip>Update roles</ButtonChip>*/}
      {/*</div>*/}
      {/*<div onClick={() => handleOnPreview()}>*/}
      {/*  <ButtonChip>Create code</ButtonChip>*/}
      {/*</div>*/}
    </>
  );

  return (
    <LayoutDefaultPageClosed>
      <SubMenuContainer>
        <SubMenuBar>
          <SubMenuItem>
              <ButtonText onClick={handleOnGoBack} >Go back</ButtonText>
          </SubMenuItem>
        </SubMenuBar>
      </SubMenuContainer>
      <EnvironmentContainer>
        <SpacesDetail
          environment={environment}
          renderActions={renderActions}
          handleOnEnter={handleOnEnter}
        />
      </EnvironmentContainer>
    </LayoutDefaultPageClosed>
  );
};

const EnvironmentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
