import React, { useState, useEffect, useRef } from "react";
import { ModalRemoveUserFromOrganization } from "../modal/ModalRemoveUserOrganization";
import styled from "styled-components";
import { ModalChangeOrganizationRole } from "../modal/ModalChangeOrganizationRole";
import classNames from "classnames";
import { ButtonIcon } from "../button/ButtonIcon";

export const ContactsAction = ({ selectedOption, userUUID, refreshData }) => {
  const [open, setOpen] = useState(false);
  const modalRef = useRef();

  function handleMenuState() {
    if (open) {
      setOpen(false);
      return;
    }
    setOpen(true);
  }

  useEffect(() => {
    function handler(event) {
      if (!modalRef.current?.contains(event.target)) {
        setOpen(false);
      }
    }

    window.addEventListener("click", handler);
    return () => window.removeEventListener("click", handler);
  }, []);

  return (
    <div id="modal" ref={modalRef}>
      <div onClick={() => handleMenuState()}>
        <ButtonIcon icon="options" />
      </div>
      <MenuContainer className={classNames({ "is-open": open })}>
        <MenuList>
          <MenuItem>
            <ModalRemoveUserFromOrganization
              userUUID={userUUID}
              organizationName={selectedOption}
              refreshData={refreshData}
            />
          </MenuItem>
          <MenuItem>
            <ModalChangeOrganizationRole
              userUUID={userUUID}
              organizationName={selectedOption}
              refreshData={refreshData}
            />
          </MenuItem>
        </MenuList>
      </MenuContainer>
    </div>
  );
};

const MenuContainer = styled.div`
  display: none;
  position: absolute;
  z-index: 2;
  max-height: 75vh;
  overflow-y: auto;
  transition: 0.3s;
  opacity: 1;
  right: calc(15%);
  top: 100%;
  min-width: 150px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 4px 16px 5px;
  border-radius: 18px;
  background-color: white;

  &.is-open {
    display: block;
    opacity: 1;
    pointer-events: all;
    transition: 0.3s;
  }
`;

const MenuList = styled.ul`
  text-align: start;
  padding: 8px;
  list-style-type: none;
  margin: auto;
`;

const MenuItem = styled.li`
  padding: 4px 8px;
  transition: 0.3s all;
  transform: scale(0.95);
  margin: 0px;
  &:hover {
    transform: scale(1);
    transition: 0.3s all;
  }
`;
