import React, { useContext, useState } from "react";
import styled from "styled-components";
import { SubMenuBar } from "../subMenu/SubMenuBar";
import { SubMenuItem } from "../subMenu/SubMenuItem";
import { InputFieldWrapper } from "../Input/InputFieldWrapper";
import { InputFieldTitle } from "../Input/InputFieldTitle";
import { InputFieldError } from "../Input/InputFieldError";
import { createOrganizationSpace } from "../../api/services/SpaceProServices";
import {
  errorNotification,
  successNotification,
} from "../notifications/Notifications";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../providers/AuthContextProvider";
import { useNavigate } from "react-router-dom";
import TabLeft from "../tabs/TabLeft";
import TabLeftSecondary from "../tabs/TabLeftSecondary";
import TabMiddle from "../tabs/TabMiddle";
import TabMiddleSecondary from "../tabs/TabMiddleSecondary";
import TabRight from "../tabs/TabRight";
import TabRightSecondary from "../tabs/TabRightSecondary";
import { Heading2 } from "../Headings";
import { Paragraph } from "../Paragraphs";
import { SpacesEnvironmentList } from "../spaces/SpacesEnvironmentList";

export const FormSpacesAdd = ({
  environments,
  setSpaceType,
  userOrganizationMember,
  selectedOrganization,
  selectedType,
}) => {
  const [showPublic, setShowPublic] = useState(true);
  const [showOrganization, setShowOrganization] = useState(false);

  const {
    user: { userUUID },
  } = useContext(AuthContext);
  const navigate = useNavigate();

  const {
    register,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  function handleForceSubmit(selectedElement) {
    setValue("environmentUuid", selectedElement);
    handleSubmit(onSubmit)();
  }

  const onSubmit = async ({
    spaceName,
    description,
    environmentUuid,
    defaultSpaceRole,
  }) => {
    try {
      const response = await createOrganizationSpace(
        spaceName,
        description,
        false,
        selectedType,
        environmentUuid,
        defaultSpaceRole,
        userUUID,
        selectedOrganization.organization.organizationName
      );
      const spaceId =
        response.headers.location.split("/")[
          response.headers.location.split("/").length - 1
        ];
      successNotification("Space created successfully");
      navigate(`/spaces/${spaceId}`);
    } catch (error) {
      console.error(error);
      errorNotification(error.message);
      reset();
    }
  };

  const handleOnClickPublic = () => {
    setShowPublic(true);
    setShowOrganization(false);
    setSpaceType("PUBLIC");
  };

  const handleOnClickPrivate = () => {
    setShowPublic(false);
    setShowOrganization(false);
    setSpaceType("USER");
  };

  const handleOnClickOrganization = () => {
    setShowPublic(false);
    setShowOrganization(true);
    setSpaceType("ORGANIZATION");
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormContainer>
        <TextContainer>
          <InputFieldWrapper>
            <InputFieldTitle>Space name</InputFieldTitle>
            <input {...register("spaceName", { required: true })} type="text" />
            {errors.spaceName && (
              <InputFieldError>Name is required</InputFieldError>
            )}
          </InputFieldWrapper>

          <InputFieldWrapper>
            <InputFieldTitle>Description</InputFieldTitle>
            <textarea
              {...register("description", { required: true })}
              type="text"
            />
            {errors.description && (
              <InputFieldError>Description is required</InputFieldError>
            )}
          </InputFieldWrapper>

          <InputFieldWrapper>
            <InputFieldTitle>Default role for all users</InputFieldTitle>
            <select {...register("defaultSpaceRole")}>
              <option value="GUEST">Guest</option>
              <option value="USER">User</option>
              <option value="PRESENTER">Presenter</option>
              <option value="MODERATOR">Moderator</option>
            </select>
          </InputFieldWrapper>
        </TextContainer>
        <InputFieldWrapper>
          <InputFieldTitle
            {...register("environmentUuid", { required: true })}
            type="text"
          />
          {errors.environmentUuid && (
            <InputFieldError>Environment is required</InputFieldError>
          )}
        </InputFieldWrapper>

        <InformationWrapper>
          <Paragraph>Select an environment for your space, switch between public, organization and private environments</Paragraph>
        </InformationWrapper>

        <SubMenuBar>
          <SubMenuItem>
            <div onClick={handleOnClickPublic}>
              {showPublic ? (
                <TabLeft>Public</TabLeft>
              ) : (
                <TabLeftSecondary>Public</TabLeftSecondary>
              )}
            </div>
            <div onClick={handleOnClickOrganization}>
              {showOrganization && userOrganizationMember && (
                <TabMiddle>Organization</TabMiddle>
              )}
              {!showOrganization && userOrganizationMember && (
                <TabMiddleSecondary>Organization</TabMiddleSecondary>
              )}
            </div>
            <div onClick={handleOnClickPrivate}>
              {!showPublic && !showOrganization ? (
                <TabRight>Private</TabRight>
              ) : (
                <TabRightSecondary>Private</TabRightSecondary>
              )}
            </div>
          </SubMenuItem>
        </SubMenuBar>

        <ListWrapper>
          <SpacesEnvironmentList
            environments={environments}
            submit={handleForceSubmit}
          />
        </ListWrapper>
      </FormContainer>
    </form>
  );
};

const InformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
`;

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: nowrap;
  flex-direction: column;
  align-items: center;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  align-content: stretch;
  flex-wrap: wrap;
  width: 620px;
`;
